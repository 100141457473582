/* eslint-disable no-unused-vars */

import React, { useRef, useEffect, useState, useMemo } from 'react';
import Transition from '../../utils/Transition';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setUserDetails } from '../../slices/callsSlice';
import { fetchFeedbackUserDetails } from '../../services/operations/calls';
import toast from 'react-hot-toast';
import useUpdateFeedbackCallDetails from '../../hooks/useUpdateFeedbackCallDetails';

function FeedbackDetailsModal({ email, modalOpen, setModalOpen, getLastSeenText, callType }) {
  const modalContent = useRef(null);
  const dispatch = useDispatch();

  const [notes, setNotes] = useState({ call1: '', call2: '' });
  const [callStatus, setCallStatus] = useState({ call1: '', call2: '' });
  const [isEditing, setIsEditing] = useState({ call1: false, call2: false });

  const [selectedCall, setSelectedCall] = useState(null);
  useEffect(() => {
    setSelectedCall(callType === 'post 2w' ? 'call2' : 'call1');
  }, [setSelectedCall, callType]);

  const userData = useSelector((state) => state.calls.userDetails);

  useEffect(() => {
    if (modalOpen && email) {
      dispatch(setEmail(email));
      dispatch(fetchFeedbackUserDetails(email));
    }
  }, [modalOpen, email, dispatch]);

  useEffect(() => {
    if (userData) {
      setNotes({
        call1: userData?.call_details_1?.notes,
        call2: userData?.call_details_2?.notes,
      });

      setCallStatus({
        call1: userData?.call_details_1?.status,
        call2: userData?.call_details_2?.status,
      });
    }
  }, [userData]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to Clipboard');
  };

  const userInfoLeft = useMemo(
    () => [
      { label: 'Name', value: userData?.name || 'N/A' },
      { label: 'Email', value: userData?.email || 'N/A', copyable: true },
      { label: 'Phone Number', value: userData?.phone_number || 'N/A', copyable: true },
      {
        label: 'Purchase Date',
        value: moment(userData?.created_at).format('MMM DD, YYYY, h:mm A') || 'N/A',
      },
      {
        label: 'Location',
        value: userData?.location
          ? userData?.location === 'international'
            ? 'International'
            : userData?.location
          : 'N/A',
      },
    ],
    [userData],
  );

  const userInfoRight = useMemo(
    () => [
      { label: 'Plan Name', value: userData?.plan_name || 'N/A' },
      { label: 'No. of Active days', value: userData?.active_days_count || 'N/A' },
      { label: 'Last Seen', value: getLastSeenText(userData?.last_seen) || 'N/A' },
      {
        label: 'Profile Visibility',
        value: userData?.isPublic ? 'Public' : 'Private',
        visibility: true,
      },
      { label: 'Profile Link', value: `${userData?.user_id}` || 'N/A', link: true },
    ],
    [userData, getLastSeenText],
  );

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen) return; // Don't close if TranscriptModal is open
      if (modalContent.current.contains(target)) return;

      // Close outer modal logic
      setModalOpen(false);
      setIsEditing({ call1: false, call2: false });
      dispatch(setEmail(null));
      dispatch(setUserDetails({}));
      setIsEditing(false);
      setSelectedCall(callType === 'post 2w' ? 'call2' : 'call1');
    };

    document.addEventListener('mousedown', clickHandler);
    return () => document.removeEventListener('mousedown', clickHandler);
  }, [dispatch, modalOpen, setModalOpen, setSelectedCall, callType]);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;

      // Close outer modal logic
      setModalOpen(false);
      setIsEditing({ call1: false, call2: false });
      dispatch(setEmail(null));
      dispatch(setUserDetails({}));
      setSelectedCall(callType === 'post 2w' ? 'call2' : 'call1');
    };

    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dispatch, modalOpen, setModalOpen, setSelectedCall, callType]);

  // Close modal and clear user details
  const handleClose = (e) => {
    e.stopPropagation();
    setModalOpen(false);
    setIsEditing({ call1: false, call2: false });
    dispatch(setEmail(null));
    dispatch(setUserDetails({}));
    setSelectedCall(callType === 'post 2w' ? 'call2' : 'call1');
  };

  const toggleEdit = (key) => {
    setIsEditing((prev) => ({ ...prev, [key]: !prev[key] }));
    if (isEditing[key]) {
      updateFeedbackCallDetails({
        email,
        callType: Number(key.replace(/\D/g, '')),
        notes: notes[key],
        status: callStatus[key],
      });
    }
  };

  const updateFeedbackCallDetails = useUpdateFeedbackCallDetails();

  const handleStatusChange = (key, value) => {
    setCallStatus((prev) => ({ ...prev, [key]: value }));
    updateFeedbackCallDetails({
      email,
      callType: Number(key.replace(/\D/g, '')),
      notes: notes[key],
      status: value,
    });
  };

  return (
    <>
      <Transition
        className='fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity'
        show={modalOpen}
        enter='transition ease-out duration-200'
        enterStart='opacity-0'
        enterEnd='opacity-100'
        leave='transition ease-out duration-100'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        aria-hidden='true'
      />
      <Transition
        className='fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6'
        show={modalOpen}
        enter='transition ease-in-out duration-200'
        enterStart='opacity-0 translate-y-4'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-in-out duration-200'
        leaveStart='opacity-100 translate-y-0'
        leaveEnd='opacity-0 translate-y-4'
      >
        <div
          ref={modalContent}
          className='bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar min-w-[50%] max-w-[50%] max-h-[80%] flex flex-col  p-4 sm:p-6 '
        >
          {/* Modal Header */}
          <div className='flex justify-between items-center border-b dark:border-zinc-700'>
            <div></div>
            <h2 className='font-bold text-xl text-zinc-800 dark:text-zinc-100'>
              Feedback Call Details
            </h2>
            <button
              className='text-sm font-medium text-red-500 hover:text-red-700'
              onClick={handleClose}
            >
              Close
            </button>
          </div>

          {/* Two-column layout */}
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-6 px-4'>
            {/* Left Column */}
            <div className='space-y-4'>
              {userInfoLeft.map(({ label, value, copyable }, index) => (
                <div key={index} className='flex flex-row gap-2 items-center mt-1 p-1'>
                  <label className='font-semibold'>{label}:</label>
                  <span className='font-base'>{value}</span>

                  {/* Copy Button for Email & Phone */}
                  {copyable && value !== 'N/A' && (
                    <button
                      onClick={() => copyToClipboard(value)}
                      className='ml-2 text-red-500 hover:text-red-700 transition'
                      title='Copy to Clipboard'
                    >
                      {/* Copy Icon SVG */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M8 7v10c0 .6.4 1 1 1h7M16 3h-4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 4H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2z'
                        />
                      </svg>
                    </button>
                  )}

                  {/* WhatsApp Icon for Phone Number */}
                  {userInfoLeft.find((item) => item.label === 'Location')?.value ===
                    'International' &&
                    label === 'Phone Number' &&
                    value !== 'N/A' && (
                      <a
                        href={`https://wa.me/${value}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className=''
                        title='Chat on WhatsApp'
                      >
                        {/* WhatsApp Icon SVG */}
                        <svg
                          viewBox='0 0 256 256'
                          xmlns='http://www.w3.org/2000/svg'
                          className='w-5 h-5 text-green-700 dark:text-green-400 hover:text-green-900'
                          fill='currentColor'
                          stroke='currentColor'
                        >
                          <path
                            strokeWidth={5}
                            d='M128.00049,28A100.02594,100.02594,0,0,0,41.11475,177.53908l-9.0044,31.51661a11.99971,11.99971,0,0,0,14.835,14.834l31.5166-9.00391A100.00677,100.00677,0,1,0,128.00049,28Zm0,192a91.87082,91.87082,0,0,1-46.95264-12.86719,3.99494,3.99494,0,0,0-3.14355-.4082l-33.15723,9.47363a3.99979,3.99979,0,0,1-4.94434-4.94531l9.47266-33.15625a4.00111,4.00111,0,0,0-.4082-3.14355A92.01077,92.01077,0,1,1,128.00049,220Zm50.51123-73.457-20.45947-11.69141a12.01054,12.01054,0,0,0-12.12745.12891l-13.80664,8.28418a44.04183,44.04183,0,0,1-19.38232-19.38281l8.28369-13.80664a12.0108,12.0108,0,0,0,.12891-12.127l-11.69092-20.46A10.91584,10.91584,0,0,0,100,72a32.00811,32.00811,0,0,0-32,31.88086A84.001,84.001,0,0,0,151.999,188h.12012A32.00842,32.00842,0,0,0,184,156,10.913,10.913,0,0,0,178.51172,146.543ZM152.10791,180h-.1084A75.99972,75.99972,0,0,1,76,103.8926,23.997,23.997,0,0,1,100,80a2.89975,2.89975,0,0,1,2.51172,1.457L114.20264,101.918a4.00418,4.00418,0,0,1-.043,4.042l-9.38916,15.64844a3.9987,3.9987,0,0,0-.21826,3.69824,52.04112,52.04112,0,0,0,26.1416,26.1416,3.99707,3.99707,0,0,0,3.69873-.21875L150.04,141.84084a4.006,4.006,0,0,1,4.043-.04394l20.46045,11.69238A2.89712,2.89712,0,0,1,176,156,23.99725,23.99725,0,0,1,152.10791,180Z'
                          ></path>
                        </svg>
                      </a>
                    )}
                </div>
              ))}
            </div>
            {/* Right Column */}
            <div className='space-y-4'>
              {userInfoRight.map(({ label, value, link, visibility }, index) => (
                <div key={index} className='flex flex-row items-center gap-2 mt-1 p-1'>
                  <label className='font-semibold'>{label}:</label>
                  {link ? (
                    <a
                      href={`https://takeuforward.org/plus/profile/${value}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={`text-red-500 flex items-center gap-1 italic underline transition-colors duration-200 ${
                        userData?.isPublic ? 'hover:text-green-500' : 'hover:text-blue-500'
                      }`}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='w-5 h-5'
                      >
                        <path d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71' />
                        <path d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71' />
                      </svg>
                      {value}
                    </a>
                  ) : visibility ? (
                    <span className={value === 'Public' ? 'text-green-500' : 'text-blue-500'}>
                      {value}
                    </span>
                  ) : (
                    <span className='font-base'>{value}</span>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Notes Section */}
          {['call1', 'call2'].map((key, index) => (
            <div key={key} className='text-sm sm:text-base md:text-lg p-2'>
              <button
                className={`w-full text-center font-semibold p-2 rounded-t-lg border border-gray-200 dark:border-zinc-700 transition duration-300 ${
                  selectedCall === key
                    ? 'bg-gray-200 dark:bg-zinc-700 text-black dark:text-white'
                    : 'bg-gray-100 dark:bg-zinc-800 text-gray-600 dark:text-gray-400'
                }`}
                onClick={() => {
                  setSelectedCall(key);
                }}
              >
                {index === 0
                  ? 'Post Purchase Call (after 24 hours)'
                  : 'Post Purchase Call (after 2 Weeks)'}
              </button>

              {selectedCall === key && (
                <div className='p-3 bg-rounded shadow-md border border-gray-200 dark:border-zinc-700 transition-all duration-300 ease-in-out opacity-100 scale-100'>
                  {/* Last Update Time */}
                  <div className='flex flex-row gap-x-2 items-center'>
                    <div className='flex items-center text-sm py-2'>
                      <label className='font-semibold mr-2'>Last Update Time:</label>
                      <span>
                        {moment(
                          userData?.[`call_details_${key === 'call1' ? 1 : 2}`]?.timestamp,
                        ).format('MMM DD, YYYY, h:mm A') || 'N/A'}
                      </span>
                    </div>
                  </div>

                  {/* Call Status */}
                  <div className='flex flex-row gap-x-2 text-xs sm:text-sm items-center'>
                    <label className='font-semibold mb-1'>Call Status:</label>
                    <select
                      className='text-xs sm:text-sm px-2 py-1 bg-gray-50 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 focus:outline-none focus:ring focus:ring-red-500 appearance-none'
                      value={callStatus[key]}
                      onChange={(e) => handleStatusChange(key, e.target.value)}
                    >
                      <option value='not called'>Not Called</option>
                      <option value='wrong num'>Wrong Number</option>
                      <option value='unreachable'>Unreachable</option>
                      <option value='unanswered'>Unanswered</option>
                      <option value='feedback happy'>Feedback Given - Happy</option>
                      <option value='feedback sad'>Feedback Given - Sad</option>
                    </select>
                  </div>

                  {/* Notes Field */}
                  <div className='flex flex-col'>
                    <div className='flex items-center justify-between mb-2'>
                      <span className='font-semibold text-base'>Notes:</span>
                      <button
                        className={`px-3 py-1 rounded text-sm font-medium ${
                          isEditing[key]
                            ? 'bg-green-500 text-white hover:bg-green-600'
                            : 'bg-red-500 text-white hover:bg-red-600'
                        }`}
                        onClick={() => toggleEdit(key)}
                      >
                        {isEditing[key] ? 'Save' : 'Edit'}
                      </button>
                    </div>
                    {isEditing[key] ? (
                      <ReactQuill
                        theme='snow'
                        placeholder='Start writing...'
                        value={notes[key]}
                        className='rounded h-100'
                        onChange={(value) => setNotes((prev) => ({ ...prev, [key]: value }))}
                      />
                    ) : (
                      <div
                        className='p-3 bg-gray-50 dark:bg-zinc-800 text-sm rounded shadow-md border border-gray-200 dark:border-zinc-700'
                        dangerouslySetInnerHTML={{ __html: notes[key] || 'No notes available.' }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Transition>
    </>
  );
}

export default FeedbackDetailsModal;
