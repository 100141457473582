import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
    fetchDetails: { category: 'all', startDate: '', endDate: '', page: 1, isEnrolled: 'all', orderBy: 'ASC' },
    userType: '',
    allUserCalls: { users: [], count: 0 },
    userDetails: {},
    callDetails: { transcript: '', notes: '', duration: '', time: '' },
    email: '',
    status: '',
    code_used: '',
    loading: false,
    error: null,
    isUserViaEmail: false,
    call_time: '',
    userAdded: false,

    feedbackFetch: { category: 'all', startDate: '', endDate: '', page: 1, callType: '' },

    fetchedData: {},
    fetchedDataBar: {}
};

const callsSlice = createSlice({
    name: 'calls',
    initialState,
    reducers: {
        setFetchDetails(state, action) {
            state.fetchDetails = {
                ...state.fetchDetails,
                ...action.payload
            }
        },
        setUserCalls(state, action) {
            state.allUserCalls = {
                users: action.payload.users,
                count: action.payload.count,
            };
        },
        setUserDetails(state, action) {
            state.userDetails = action.payload;
        },
        setCallDetailsRedux(state, action) {
            state.callDetails = action.payload;
        },
        updateCallStatusRedux(state, action) {
            state.status = action.payload;
        },
        updateBuyingStatusRedux(state, action) {
            state.status = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setCodeUsedRedux(state, action) {
            state.code_used = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        
            if (action.payload) {
                toast.loading("Processing request...", { id: "loading-toast" });
            } else {
                toast.success("Request processed", { id: "loading-toast"});
            }
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setUserType(state, action) {
            state.userType = action.payload;
        },
        setUserViaEmail(state, action) {
            state.allUserCalls = {
                users: action.payload.userDetails,
                count: 1,
            };
        },
        setIsUserViaEmail(state, action) {
            state.isUserViaEmail = action.payload;
        },
        setCallTimeRedux(state, action) {
            state.call_time = action.payload;
        },
        setUserAdded(state, action) {
            state.userAdded = action.payload;
        },
        setFetchedDataPI: (state, action) => {
            state.fetchedData = action.payload;
        },
        setFetchedDataBar: (state, action) => {
            state.fetchedDataBar = action.payload;
        },
        setFeedBackFetch(state, action) {
            state.feedbackFetch = {
                ...state.feedbackFetch,
                ...action.payload
            }
        },
    }
});

export const {
    setFetchDetails,
    setUserCalls,
    setUserDetails,
    updateCallStatusRedux,
    setCodeUsedRedux,
    setLoading,
    setError,
    setUserType,
    setEmail,
    setCallDetailsRedux,
    setUserViaEmail,
    setIsUserViaEmail,
    setCallTimeRedux,
    setUserAdded,
    updateBuyingStatusRedux,
    setFetchedDataPI,
    setFetchedDataBar,
    setFeedBackFetch
} = callsSlice.actions;

export default callsSlice.reducer;
