import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { BadgeX, StepBackIcon } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { getSingleAptitudeProblem, updateAptitudeProblem } from "../../../services/operations/new-syllabus";

const AptitudeEdit = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const { CurrentProblem } = useSelector((state) => state.new_syllabus);
    const dispatch = useDispatch();
    const { slug } = useParams();
    const { isProd } = useSelector((state) => state.auth);

    const {
        control,
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            problem_name: CurrentProblem?.problem_name || "",
            slug: CurrentProblem?.slug || "",
            text: CurrentProblem?.problem_data?.text || "",
            options: CurrentProblem?.problem_data?.options || ["", "", "", ""],
            correct_option: CurrentProblem?.problem_data?.correct_option || 0,
            explanation: CurrentProblem?.problem_data?.explanation || "",
            image_link: CurrentProblem?.problem_data?.image_link || "",
            image_link_problem: CurrentProblem?.problem_data?.image_link_problem || "",
        },
    });

    useEffect(() => {
        if (slug) {
            dispatch(getSingleAptitudeProblem(slug));
        }
    }, [dispatch, slug]);

    useEffect(() => {
        if (Object.entries(CurrentProblem).length > 0) {
            reset({
                problem_name: CurrentProblem?.problem_name || "",
                slug: CurrentProblem?.slug || "",
                text: CurrentProblem?.problem_data?.text || "",
                options: CurrentProblem?.problem_data?.options || ["", "", "", ""],
                correct_option: CurrentProblem?.problem_data?.correct_option || 0,
                explanation: CurrentProblem?.problem_data?.explanation || "",
                image_link: CurrentProblem?.problem_data?.image_link || "",
                image_link_problem: CurrentProblem?.problem_data?.image_link_problem || "",
            })
        }
    }, [CurrentProblem, reset]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "options",
    });

    const onSubmit = (formData) => {
        // console.log("Submitted Data:", formData);
        const data = {
            id: CurrentProblem.id,
            problem_name: formData.problem_name,
            slug: formData.slug,
            problem_data: {
                text: formData.text,
                options: formData.options,
                correct_option: formData.correct_option,
                explanation: formData.explanation,
                image_link: formData.image_link,
                image_link_problem: formData.image_link_problem,
            }
        }
        console.log("Data:", data);
        dispatch(updateAptitudeProblem(data, isProd));
    };

    return (
        <div className="flex dark:bg-dark_50 h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mt-4 flex flex-col gap-10">
                            {/* Back Button */}
                            <div className="flex items-center gap-4">
                                <button
                                    onClick={() => navigate(-1)}
                                    className="flex items-center gap-2 px-4 py-2 rounded-md shadow-md"
                                >
                                    <StepBackIcon />
                                    <span>Back</span>
                                </button>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex gap-5 mb-8">
                                    <div className="w-1/2">
                                        <div className="flex items-center gap-x-4">
                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                                Problem Name <span className="text-red-500">*</span>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            {...register("problem_name", { required: "Problem name is required" })}
                                            className="form-input w-full text-gray-300"
                                            placeholder="Problem name"
                                        />
                                        {errors.problem_name && (
                                            <span className="text-red-500">{errors.problem_name.message}</span>
                                        )}
                                    </div>

                                    <div className="w-1/2">
                                        <div className="flex items-center gap-x-4">
                                            <span className="text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                                Slug <span className="text-red-500">*</span>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            {...register("slug", { required: "Slug is required" })}
                                            className="form-input w-full text-gray-300"
                                            placeholder="slug"
                                        />
                                        {errors.slug && (
                                            <span className="text-red-500">{errors.slug.message}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-4">
                                    <div className="flex mb-4">
                                        <span className="w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Question Text <span className="text-red-500">*</span>
                                        </span>
                                        <textarea
                                            {...register("text")}
                                            className="form-input w-full text-gray-300"
                                            rows="3"
                                            placeholder="Enter question text"
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        {fields.map((field, index) => (
                                            <div key={field.id} className="flex items-center gap-4">
                                                <span className="w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                                    Option {index + 1} <span className="text-red-500">*</span>
                                                </span>
                                                <input
                                                    {...register(`options.${index}`, {
                                                        required: "Option text is required",
                                                    })}
                                                    className="form-input w-full text-gray-300"
                                                    placeholder={`Option ${index + 1}`}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => remove(index)}
                                                    className="text-red-500"
                                                >
                                                    <BadgeX />
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                    <button
                                        type="button"
                                        onClick={() => append("")}
                                        className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                                    >
                                        Add Option
                                    </button>

                                    <div className="flex items-center gap-4 mt-4">
                                        <span className="w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Correct Option <span className="text-red-500">*</span>
                                        </span>
                                        <Controller
                                            control={control}
                                            name="correct_option"
                                            render={({ field }) => (
                                                <select {...field} className="form-select w-full text-gray-300">
                                                    <option value="">Select correct option</option>
                                                    {fields.map((_, index) => (
                                                        <option key={index} value={index}>
                                                            Option {index + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                    </div>

                                    <div className="flex items-center gap-4 mt-4">
                                        <span className="w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Explanation <span className="text-red-500">*</span>
                                        </span>
                                        <textarea
                                            {...register("explanation")}
                                            className="form-input w-full text-gray-300"
                                            rows="3"
                                            placeholder="Enter explanation"
                                        />
                                    </div>

                                    <div className="flex items-center gap-4 mt-4">
                                        <span className="w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Image Link
                                        </span>
                                        <input
                                            {...register("image_link")}
                                            className="form-input w-full text-gray-300"
                                            placeholder="Enter image link"
                                        />
                                    </div>
                                    <div className="flex items-center gap-4 mt-4">
                                        <span className="w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium">
                                            Image Link Problem
                                        </span>
                                        <input
                                            {...register("image_link_problem")}
                                            className="form-input w-full text-gray-300"
                                            placeholder="Enter image link"
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="mt-4 px-4 py-2 rounded-md bg-green-500 text-white"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AptitudeEdit;