import { setRunSubmitStats, setRunSubmitPerformanceStats } from '../../slices/judgeStatsSlice';
import { judgeStatsEndpoints } from '../api';
import { apiConnector } from '../apiConnector';
import toast from 'react-hot-toast';

const { GET_JUDGE_STATS, GET_JUDGE_PERFORMANCE_STATS } = judgeStatsEndpoints;

export function getJudgeStats(startDate, endDate) {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        'GET',
        `${GET_JUDGE_STATS}?start_date=${startDate}&end_date=${endDate}`,
      );

      if (response?.data) {
        const stats = response.data.map(({ date, total_runs, total_submits }) => ({
          date: new Date(date).toISOString().split('T')[0],
          runCount: total_runs,
          submitCount: total_submits,
        }));

        dispatch(setRunSubmitStats(stats));
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error fetching judge stats:', error);
      toast.error('Failed to fetch judge stats');
    }
  };
}

export function getJudgePerformanceStats(startDate, endDate) {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        `${GET_JUDGE_PERFORMANCE_STATS}?start_date=${startDate}&end_date=${endDate}`
      );

      if (response?.data) {
        console.log(response.data);

        const parsePerformance = (performance) => {
          try {
            return JSON.parse(performance);
          } catch (error) {
            return {}; // Return empty object if parsing fails
          }
        };

        // Object to store aggregated results
        const aggregatedStats = {};

        response.data.forEach(({ date, submit_performance, run_performance }) => {
          const formattedDate = new Date(date).toISOString().split("T")[0];

          const runPerf = parsePerformance(run_performance);
          const submitPerf = parsePerformance(submit_performance);

          if (!aggregatedStats[formattedDate]) {
            aggregatedStats[formattedDate] = {
              date: formattedDate,
              runPerformance: {},
              submitPerformance: {},
            };
          }

          // Aggregate Run Performance
          Object.entries(runPerf).forEach(([key, value]) => {
            aggregatedStats[formattedDate].runPerformance[key] =
              (aggregatedStats[formattedDate].runPerformance[key] || 0) + value;
          });

          // Aggregate Submit Performance
          Object.entries(submitPerf).forEach(([key, value]) => {
            aggregatedStats[formattedDate].submitPerformance[key] =
              (aggregatedStats[formattedDate].submitPerformance[key] || 0) + value;
          });
        });

        // Convert aggregatedStats object to array
        const stats = Object.values(aggregatedStats);

        dispatch(setRunSubmitPerformanceStats(stats));
      } else {
        console.warn("No data found in the response");
      }
    } catch (error) {
      console.error("Error fetching judge stats:", error);
      toast.error("Failed to fetch judge stats");
    }
  };
}

