import { apiConnector } from '../apiConnector';
import toast from 'react-hot-toast';
import { DSA, THEORY, QUIZ, APTITUDE, TOPICS, SUBJECT_ORDER, DSA_PROD, THEORY_PROD, QUIZ_PROD, TOPICS_PROD, SUBJECT_ORDER_PROD } from '../api';
import {
    setAllAptitudeProblems,
    setAllCategories,
    setAllDSAProblems,
    setAllQuizProblems,
    setAllSubCategories,
    setAllSubjectProblems,
    setAllSubjects,
    setAllTheoryProblems,
    setCurrentProblem,
} from '../../slices/new-syllabusSlice';
import { setIsProd } from '../../slices/authSlice';

const {
    GET_ALL_DSA_PROBLEMS,
    GET_SINGLE_DSA_PROBLEMS,
    ADD_DSA_PROBLEM,
    UPDATE_DSA_PROBLEM,
    DELETE_DSA_PROBLEM,
    GET_COPY_TO_EDITOR
} = DSA;

const {
    GET_ALL_APTITUDE_PROBLEMS,
    GET_SINGLE_APTITUDE_PROBLEMS,
    ADD_APTITUDE_PROBLEM,
    UPDATE_APTITUDE_PROBLEM,
    DELETE_APTITUDE_PROBLEM,
    DELETE_APTITUDE_PROBLEM_PROD,
    UPDATE_APTITUDE_PROBLEM_PROD,
    ADD_APTITUDE_PROBLEM_PROD,


} = APTITUDE;

const {
    ADD_DSA_PROBLEM_PROD,
    UPDATE_DSA_PROBLEM_PROD,
    DELETE_DSA_PROBLEM_PROD,
} = DSA_PROD;


const {
    GET_ALL_THEORY_PROBLEMS,
    GET_SINGLE_THEORY_PROBLEMS,
    ADD_THEORY_PROBLEM,
    UPDATE_THEORY_PROBLEM,
    DELETE_THEORY_PROBLEM,
} = THEORY;

const {
    ADD_THEORY_PROBLEM_PROD,
    UPDATE_THEORY_PROBLEM_PROD,
    DELETE_THEORY_PROBLEM_PROD,
} = THEORY_PROD;

const {
    GET_ALL_QUIZ_PROBLEMS,
    GET_SINGLE_QUIZ_PROBLEMS,
    ADD_QUIZ_PROBLEM,
    UPDATE_QUIZ_PROBLEM,
    DELETE_QUIZ_PROBLEM,
} = QUIZ;

const {
    ADD_QUIZ_PROBLEM_PROD,
    UPDATE_QUIZ_PROBLEM_PROD,
    DELETE_QUIZ_PROBLEM_PROD,
} = QUIZ_PROD;

const {
    GET_ALL_CATEGORIES_V2,
    ADD_CATEGORY_V2,
    UPDATE_CATEGORY_V2,
    DELETE_CATEGORY_V2,

    GET_ALL_SUB_CATEGORIES_V2,
    ADD_SUB_CATEGORY_V2,
    UPDATE_SUB_CATEGORY_V2,
    DELETE_SUB_CATEGORY_V2,

    GET_ALL_TOPICS_V2,
    ADD_TOPIC_V2,
    UPDATE_TOPIC_V2,
    DELETE_TOPIC_V2,
    REMOVE_PROBLEM_FROM_ORDER
} = TOPICS;


const {
    ADD_CATEGORY_V2_PROD,
    UPDATE_CATEGORY_V2_PROD,
    DELETE_CATEGORY_V2_PROD,

    ADD_SUB_CATEGORY_V2_PROD,
    UPDATE_SUB_CATEGORY_V2_PROD,
    DELETE_SUB_CATEGORY_V2_PROD,

    ADD_TOPIC_V2_PROD,
    UPDATE_TOPIC_V2_PROD,
    DELETE_TOPIC_V2_PROD,
    REMOVE_PROBLEM_FROM_ORDER_PROD
} = TOPICS_PROD;


const { GET_ALL_SUBJECT_PROBLEMS } = SUBJECT_ORDER;

export function getAllDSAProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_DSA_PROBLEMS);
            dispatch(setAllDSAProblems(response.data.allProblems));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllAptitudeProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_APTITUDE_PROBLEMS);
            
            if(response.data.success){
                dispatch(setAllAptitudeProblems(response.data.data));
            }
            else{
                toast.error("Failed to fetch aptitude problems")
            }

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}


export function getSingleAptitudeProblem(slug) {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProblem({}));
            const response = await apiConnector('GET', GET_SINGLE_APTITUDE_PROBLEMS + slug);
            if(response.data.success){
                dispatch(setCurrentProblem(response.data.data));
            }
            else{
                toast.error("Failed to fetch problem data");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addAptitudeProblem(problemData, setModalOpen, reset, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', ADD_APTITUDE_PROBLEM, problemData);
                const response2 = await apiConnector('POST', ADD_APTITUDE_PROBLEM_PROD, problemData);
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('POST', ADD_APTITUDE_PROBLEM, problemData);
                console.log(response);
                if(response.data.success){
                    toast.success(response.data.message);
                }
                else{
                    toast.error("Failed to save problem data")
                }
            }
            dispatch(setIsProd(false))
            setModalOpen(false);
            reset({
                name: '',
                slug: '',
            });
            dispatch(getAllAptitudeProblems());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateAptitudeProblem(problem_data, isProd) {
    return async (dispatch) => {
        try {
            console.log("problem_data", problem_data);
            if (isProd){
                const response = await apiConnector('PATCH', UPDATE_APTITUDE_PROBLEM, {problem_data});
                const response2 = await apiConnector('PATCH', UPDATE_APTITUDE_PROBLEM_PROD, {problem_data});
                if(response.data.success && response2.data.success){
                    toast.success(response.data.message);
                    toast.success(response2.data.message + " in PROD");
                }
                else{
                    toast.error("Failed to save problem data")
                }
            }
            else{
                const response = await apiConnector('PATCH', UPDATE_APTITUDE_PROBLEM, {problem_data});
                if(response.data.success){
                    toast.success(response.data.message);
                }
                else{
                    toast.error("Failed to save problem data")
                }
            }
            dispatch(setIsProd(false))
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteAptitudeProblem(slug, isProd) {
  return async (dispatch) => {
    try {
      if (isProd) {
        const response1 = await apiConnector('DELETE', DELETE_APTITUDE_PROBLEM_PROD + slug);
        const response2 = await apiConnector('DELETE', DELETE_APTITUDE_PROBLEM + slug);
        if (response1.data.success && response2.data.success) {
          toast.success('Aptitude Problem deleted successfully');
        } else {
          toast.error('Failed to delete aptitude problem');
        }
      } else {
        const response = await apiConnector('DELETE', DELETE_APTITUDE_PROBLEM + slug);
        toast.success(response.data.message);
      }
      dispatch(getAllAptitudeProblems());
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || 'An error occurred');
    }
  };
}

export function getSingleDSAProblem(problemId) {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProblem({}));
            const response = await apiConnector('GET', GET_SINGLE_DSA_PROBLEMS + problemId);
            const problem = response.data.problem_data;
            dispatch(setCurrentProblem(problem));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addDSAProblem(problemData, setModalOpen, reset, isProd) {
    return async (dispatch) => {
        try {
            if (isProd) {
                const response = await apiConnector('POST', ADD_DSA_PROBLEM, problemData);
                const response2 = await apiConnector('POST', ADD_DSA_PROBLEM_PROD, problemData);
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");  
            }
            else {
                const response = await apiConnector('POST', ADD_DSA_PROBLEM, problemData);
                toast.success(response.data.message);
               
            }
            setModalOpen(false);
            reset({
                name: '',
                difficulty: 'easy',
                slug: '',
                hasIDE: false,
            });
            dispatch(getAllDSAProblems());
            dispatch(setIsProd(false))

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateDSAProblem(problem_data, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', UPDATE_DSA_PROBLEM, {problem_data});
                const response2 = await apiConnector('POST', UPDATE_DSA_PROBLEM_PROD, {problem_data});

                if(response.data.success && response2.data.success){
                    toast.success(response?.data?.message);
                    toast.success(response2?.data?.message+" in PROD");
                }
                else{
                    toast.error("Failed to save problem data ")
                }
            }
            else{
                const response = await apiConnector('POST', UPDATE_DSA_PROBLEM, {problem_data});
                if(response.data.success){
                    toast.success(response?.data?.message);
                }
                else{
                    toast.error("Failed to save problem data ")
                }
            }
            dispatch(setIsProd(false))

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getCopyToEditorCode(problem_slug, setCopyToEditorCode) {
  return async (dispatch) => {
    try {
        const fetchPromise = apiConnector('GET', GET_COPY_TO_EDITOR + problem_slug);
        await toast.promise(
          fetchPromise,
          {
            loading: 'Generating Copy To Editor Code...',
            success: 'Code Generated Successfully',
            error: 'Failed to fetch data.',
          }
        );
    
        const response = await fetchPromise;
        if (response.data.success) {
          setCopyToEditorCode(response.data.data[0]);
        }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || 'An error occurred');
    }
  };
}

export function deleteDSAProblem(problem_slug, isProd) {
    return async (dispatch) => {
        try {
            if(isProd){
                const response = await apiConnector('DELETE', DELETE_DSA_PROBLEM ,{slug: problem_slug});
                const response2 = await apiConnector('DELETE', DELETE_DSA_PROBLEM_PROD ,{slug: problem_slug});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('DELETE', DELETE_DSA_PROBLEM ,{slug: problem_slug});
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))
            dispatch(getAllDSAProblems());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllTheoryProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_THEORY_PROBLEMS);
            dispatch(setAllTheoryProblems(response.data.allProblems || []));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getSingleTheoryProblem(problemId) {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProblem({}));

            const response = await apiConnector('GET', GET_SINGLE_THEORY_PROBLEMS + problemId);
            const problem = response.data.problem_data;
            dispatch(setCurrentProblem(problem));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addTheoryProblem(problemData, setModalOpen, reset, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', ADD_THEORY_PROBLEM, problemData);
                const response2 = await apiConnector('POST', ADD_THEORY_PROBLEM_PROD, problemData);
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");

            }
            else{
                const response = await apiConnector('POST', ADD_THEORY_PROBLEM, problemData);
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))
            setModalOpen(false);
            reset({
                name: '',
                slug: '',
            });
            dispatch(getAllTheoryProblems());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateTheoryProblem(problem_data, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST',UPDATE_THEORY_PROBLEM,{problem_data});
                const response2 = await apiConnector('POST',UPDATE_THEORY_PROBLEM_PROD,{problem_data});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");

            }
            else{
                const response = await apiConnector('POST',UPDATE_THEORY_PROBLEM,{problem_data});
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteTheoryProblem(problem_slug, isProd) {
    return async (dispatch) => {
        try {
            if(isProd){
                const response = await apiConnector('DELETE', DELETE_THEORY_PROBLEM ,{slug:problem_slug});
                const response2 = await apiConnector('DELETE', DELETE_THEORY_PROBLEM_PROD ,{slug:problem_slug});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");

            }
            else{
                const response = await apiConnector('DELETE', DELETE_THEORY_PROBLEM ,{slug:problem_slug});
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))



            dispatch(getAllTheoryProblems());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllQuizProblems() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_QUIZ_PROBLEMS);
            dispatch(setAllQuizProblems(response.data.allProblems));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getSingleQuizProblem(problemId) {
    return async (dispatch) => {
        try {
            dispatch(setCurrentProblem({}));

            const response = await apiConnector('GET', GET_SINGLE_QUIZ_PROBLEMS + problemId);
            const problem = response.data.problem_data;
            dispatch(setCurrentProblem(problem));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addQuizProblem(problemData, setModalOpen, reset, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', ADD_QUIZ_PROBLEM, problemData);
                const response2 = await apiConnector('POST', ADD_QUIZ_PROBLEM_PROD, problemData);
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('POST', ADD_QUIZ_PROBLEM, problemData);
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))

            setModalOpen(false);
            reset({
                name: '',
                slug: '',
            });
            dispatch(getAllQuizProblems());
            
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateQuizProblem(problem_data, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', UPDATE_QUIZ_PROBLEM ,{problem_data});
                const response2 = await apiConnector('POST', UPDATE_QUIZ_PROBLEM_PROD ,{problem_data});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                
            const response = await apiConnector(
                'POST',
                UPDATE_QUIZ_PROBLEM ,
                {problem_data},
            );
            dispatch(setIsProd(false))
            
            toast.success(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteQuizProblem(problem_slug, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('DELETE', DELETE_QUIZ_PROBLEM ,{slug: problem_slug});
                const response2 = await apiConnector('DELETE', DELETE_QUIZ_PROBLEM_PROD ,{slug: problem_slug});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('DELETE', DELETE_QUIZ_PROBLEM ,{slug: problem_slug});
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))

            dispatch(getAllQuizProblems());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllSubCategories_v2() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_SUB_CATEGORIES_V2);
            dispatch(setAllSubCategories(response.data.allSubCategories));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addSubCategory(name, slug, category, rank, type, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', ADD_SUB_CATEGORY_V2, {
                    name,
                    slug,
                    category,
                    rank,
                    type,
                });
                const response2 = await apiConnector('POST', ADD_SUB_CATEGORY_V2_PROD, {
                    name,
                    slug,
                    category,
                    rank,
                    type,
                });
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('POST', ADD_SUB_CATEGORY_V2, {
                    name,
                    slug,
                    category,
                    rank,
                    type,
                });
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))
            setModalOpen(false);
            dispatch(getAllSubCategories_v2());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateSubCategory(subCategoryId, name, slug, category, rank, type, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', UPDATE_SUB_CATEGORY_V2 + subCategoryId, {
                    name,
                    slug,
                    rank,
                    category,
                    type,
                });
                const response2 = await apiConnector('POST', UPDATE_SUB_CATEGORY_V2_PROD + subCategoryId, {
                    name,
                    slug,
                    rank,
                    category,
                    type,
                });
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('POST', UPDATE_SUB_CATEGORY_V2 + subCategoryId, {
                    name,
                    slug,
                    rank,
                    category,
                    type,
                });
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))
            setModalOpen(false);
            dispatch(getAllSubCategories_v2());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteSubCategory(subCategory_slug, isProd) {
    return async (dispatch) => {
        try {
        if (isProd){
            const response = await apiConnector('DELETE', DELETE_SUB_CATEGORY_V2 ,{slug: subCategory_slug});
            const response2 = await apiConnector('DELETE', DELETE_SUB_CATEGORY_V2_PROD ,{slug: subCategory_slug});
            toast.success(response.data.message);
            toast.success(response2.data.message + " in PROD");
        }
        else{
            const response = await apiConnector('DELETE', DELETE_SUB_CATEGORY_V2 ,{slug: subCategory_slug});
            toast.success(response.data.message);
         }
         dispatch(setIsProd(false))
            dispatch(getAllSubCategories_v2());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllCategories_v2() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_CATEGORIES_V2);
            dispatch(setAllCategories(response.data.allCategories));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addCategory(name, slug, rank, subject, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', ADD_CATEGORY_V2, {
                    name,
                    slug,
                    rank,
                    subject,
                });
                const response2 = await apiConnector('POST', ADD_CATEGORY_V2_PROD, {
                    name,
                    slug,
                    rank,
                    subject,
                });
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('POST', ADD_CATEGORY_V2, {
                    name,
                    slug,
                    rank,
                    subject,
                });
                toast.success(response.data.message);
                
            }
            dispatch(setIsProd(false))
            setModalOpen(false);
            dispatch(getAllCategories_v2());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateCategory(categoryId, name, slug, rank, subject, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', UPDATE_CATEGORY_V2 + categoryId, {
                    name,
                    slug,
                    rank,
                    subject,
                });
                const response2 = await apiConnector('POST', UPDATE_CATEGORY_V2_PROD + categoryId, {
                    name,
                    slug,
                    rank,
                    subject,
                });
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
           else{
            const response = await apiConnector('POST', UPDATE_CATEGORY_V2 + categoryId, {
                name,
                slug,
                rank,
                subject,
            });
            toast.success(response.data.message);
           }
            setModalOpen(false);
            dispatch(setIsProd(false))

            dispatch(getAllCategories_v2());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllTopics() {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_TOPICS_V2);
            dispatch(setAllSubjects(response.data.allTopics));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function addTopic(topicData, setModalOpen, reset, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', ADD_TOPIC_V2, topicData);
                const response2 = await apiConnector('POST', ADD_TOPIC_V2_PROD, topicData);
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('POST', ADD_TOPIC_V2, topicData);
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))

            setModalOpen(false);
            reset({
                name: '',
                slug: '',
            });
            dispatch(getAllTopics());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function updateTopic(topicData, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('POST', UPDATE_TOPIC_V2 + topicData.id, topicData);
                const response2 = await apiConnector('POST', UPDATE_TOPIC_V2_PROD + topicData.id, topicData);
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('POST', UPDATE_TOPIC_V2 + topicData.id, topicData);
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))

            setModalOpen(false);
            dispatch(getAllTopics());

        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function getAllSubjectProblems(subject) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('GET', GET_ALL_SUBJECT_PROBLEMS + subject);
            dispatch(setAllSubjectProblems(response.data.allProblems));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function setSubjectOrder(subject, category, subCategory, problems, type, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                if (type === 'CATEGORY') {
                    const response = await apiConnector(
                        'POST',
                        SUBJECT_ORDER.SET_CATEGORY_PROBLEM_ORDER + subject,
                        {
                            category,
                        },
                    );
                    const response2 = await apiConnector(
                        'POST',
                        SUBJECT_ORDER_PROD.SET_CATEGORY_PROBLEM_ORDER_PROD + subject,
                        {
                            category,
                        },
                    );
                    toast.success(response.data.message);
                    toast.success(response2.data.message + " in PROD");
                }
                if (type === 'SUBCATEGORY') {
                    const response = await apiConnector('POST', 
                        SUBJECT_ORDER.SET_SUB_CATEGORY_PROBLEM_ORDER + subject, {
                        category,
                        subCategory,
                    });
                    const response2 = await apiConnector('POST', 
                        SUBJECT_ORDER_PROD.SET_SUB_CATEGORY_PROBLEM_ORDER_PROD + subject, {
                        category,
                        subCategory,
                    });
                    toast.success(response.data.message);
                    toast.success(response2.data.message + " in PROD");
                }
                if (type === 'PROBLEM') {
                    const response = await apiConnector('POST', 
                        SUBJECT_ORDER.SET_PROBLEM_ORDER + subject, {
                        category,
                        subCategory,
                        problems,
                    });
                    const response2 = await apiConnector('POST', 
                        SUBJECT_ORDER_PROD.SET_PROBLEM_ORDER_PROD + subject, {
                        category,
                        subCategory,
                        problems,
                    });
                    toast.success(response.data.message);
                    toast.success(response2.data.message + " in PROD");
                }
            }
            else{
                if (type === 'CATEGORY') {
                    const response = await apiConnector(
                        'POST',
                        SUBJECT_ORDER.SET_CATEGORY_PROBLEM_ORDER + subject,
                        {
                            category,
                        },
                    );
                    toast.success(response.data.message);
                }
                if (type === 'SUBCATEGORY') {
                    const response = await apiConnector(
                        'POST',
                        SUBJECT_ORDER.SET_SUB_CATEGORY_PROBLEM_ORDER + subject,
                        {
                            category,
                            subCategory,
                        },
                    );
                    toast.success(response.data.message);
                }
                if (type === 'PROBLEM') {
                    const response = await apiConnector('POST', 
                        SUBJECT_ORDER.SET_PROBLEM_ORDER + subject, {
                        category,
                        subCategory,
                        problems,
                    });
                    toast.success(response.data.message);
                }
            }
            dispatch(setIsProd(false))
            dispatch(getAllSubjectProblems(subject));
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteCategory(category_slug, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('DELETE', DELETE_CATEGORY_V2 ,{slug: category_slug});
                const response2 = await apiConnector('DELETE', DELETE_CATEGORY_V2_PROD ,{slug: category_slug});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('DELETE', DELETE_CATEGORY_V2 ,{slug: category_slug});
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))
            dispatch(getAllCategories_v2());
        } catch (error) {
            console.log(error); 
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };
}

export function deleteTopic(subject_slug, isProd){
    return  async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('DELETE', DELETE_TOPIC_V2 ,{slug: subject_slug});
                const response2 = await apiConnector('DELETE', DELETE_TOPIC_V2_PROD ,{slug: subject_slug});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('DELETE', DELETE_TOPIC_V2 ,{slug: subject_slug});
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))
            dispatch(getAllTopics());
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    }
}

export function removeProblemFromOrder(subject, problem, setRemoveModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if (isProd){
                const response = await apiConnector('DELETE', REMOVE_PROBLEM_FROM_ORDER, {problem:{...problem}});
                const response2 = await apiConnector('DELETE', REMOVE_PROBLEM_FROM_ORDER_PROD, {problem:{...problem}});
                toast.success(response.data.message);
                toast.success(response2.data.message + " in PROD");
            }
            else{
                const response = await apiConnector('DELETE', REMOVE_PROBLEM_FROM_ORDER, {problem:{...problem}});
                toast.success(response.data.message);
            }
            dispatch(setIsProd(false))
            dispatch(getAllSubjectProblems(subject))
            setRemoveModalOpen(false);
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    }
}