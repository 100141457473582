/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useDebugValue } from 'react';
import CallsTableItem from './CallTableItem';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ArrowDownWideNarrow, ArrowDownNarrowWide } from 'lucide-react';
import { setFetchDetails } from '../../../slices/callsSlice';

const tableHeadingsMap = {
  dropped: [
    { key: 'no', label: 'No.' },
    { key: 'email', label: 'Email' },
    { key: 'created_at', label: 'Created At', sortable: true },
    { key: 'category', label: 'Call Status' },
    { key: 'call_time', label: 'Call Time', sortable: false },
    { key: 'details', label: 'Details' },
    { key: 'codeUsed', label: 'Code Used' },
  ],
  failed: [
    { key: 'no', label: 'No.' },
    { key: 'email', label: 'Email' },
    { key: 'payment_time', label: 'Payment Time', sortable: true },
    { key: 'category', label: 'Call Status' },
    { key: 'call_time', label: 'Call Time', sortable: false },
    { key: 'details', label: 'Details' },
    { key: 'codeUsed', label: 'Code Used' },
  ],
  whatsapp: [
    { key: 'no', label: 'No.' },
    { key: 'name', label: 'Name' },
    { key: 'email', label: 'Email' },
    { key: 'phoneNo', label: 'Phone Number' },
    { key: 'buying_status', label: 'Buying Status' },
    { key: 'status_update_time', label: 'Status Update Time', sortable: false },
    { key: 'details', label: 'Details' },
    { key: 'codeUsed', label: 'Code Used' },
  ],
};

const CallsTable = ({ currentPage, itemsPerPage }) => {
  const userType = useSelector((state) => state.calls.userType);
  const users = useSelector((state) => state.calls.allUserCalls.users);
  const [sortState, setSortState] = useState({ key: null, direction: 'ASC' });
  const dispatch = useDispatch();

  const handleSort = (key) => {
    setSortState((prevState) => {
      const newDirection = prevState.key === key && prevState.direction === 'ASC' ? 'DESC' : 'ASC';

      dispatch(setFetchDetails({ orderBy: newDirection }));

      return { key, direction: newDirection };
    });
  };

  const tableHeadings = tableHeadingsMap[userType];

  return (
    <div className='font-primary  relative'>
      <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
        <div className='overflow-x-auto no-scrollbar'>
          <table className='table-auto w-full divide-y divide-gray-200 overflow-y-auto no-scrollbar'>
            <thead className='text-xs uppercase  text-[#8C8C8C] '>
              <tr>
                {tableHeadings.map((heading) => (
                  <th
                    key={heading.key}
                    className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'
                  >
                    <div className='font-semibold text-center flex items-center justify-center gap-1'>
                      {heading.label}
                      {heading.sortable && (
                        <div className='flex items-center'>
                          <button
                            className='text-red-800 hover:text-red-600'
                            onClick={() => handleSort(heading.key)}
                          >
                            {sortState.key === heading.key && sortState.direction === 'ASC' ? (
                              <ArrowDownNarrowWide className='h-4 w-4 inline-block ml-1' />
                            ) : (
                              <ArrowDownWideNarrow className='h-4 w-4 inline-block ml-1' />
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            {users?.map((data, index) => (
              <CallsTableItem
                num={index + 1 + itemsPerPage * (currentPage - 1)}
                key={data?.id}
                id={data?.id}
                name={data?.name}
                email={data?.email}
                status={data?.call_status}
                code_used={data?.code_used}
                call_time={data?.call_time}
                payment_time={data?.payment_time}
                phone_number={data?.phone_number}
                status_update_time={data?.status_update_time}
                created_at={data?.created_at}
                buying_status={data?.buying_status}
              />
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default CallsTable;
