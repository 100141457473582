import React, { useMemo } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const RunSubmitStatsChart = React.memo(({ data }) => {
    const chartData = useMemo(() => data.map(item => ({
        date: new Date(item.date).toISOString().split('T')[0],
        RunCount: item.runCount > 0 ? Math.log10(item.runCount) : 0, // Applying log transformation only if value is greater than 0
        SubmitCount: item.submitCount > 0 ? Math.log10(item.submitCount) : 0, // Avoiding log of zero
    })), [data]);

    return (
        <div>
            <h3 className='text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200'>Run & Submit Statistics</h3>
            <ResponsiveContainer width="100%" height={450}>
                <LineChart 
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 40 }} 
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                        dataKey="date" 
                        stroke={window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333'} 
                        tick={{ fontSize: 12, fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' }} 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                    />
                    <YAxis 
                        stroke={window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333'} 
                        tick={{ fontSize: 12, fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333' }} 
                        allowDecimals={false}
                        domain={['dataMin - 1', 'dataMax + 1']}
                        label={{ 
                            value: 'Count (log scale)', 
                            angle: -90, 
                            position: 'insideLeft', 
                            fill: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#808080' : '#333',
                            dx: -15
                        }}
                        tickFormatter={(tick) => Math.round(Math.pow(10, tick))} // Convert ticks back to original scale
                        padding={{ top: 20, bottom: 10 }}
                    />
                    <Tooltip 
                        contentStyle={{ backgroundColor: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#333' : '#fff', color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000' }} 
                        formatter={(value) => Math.round(Math.pow(10, value))} // Convert tooltip values back to original scale
                    />
                    <Legend 
                        wrapperStyle={{ color: window.matchMedia('(prefers-color-scheme: dark)').matches ? '#ccc' : '#333' }} 
                    />
                    <Line type="monotone" dataKey="RunCount" stroke="#8884d8" name="Run Count" />
                    <Line type="monotone" dataKey="SubmitCount" stroke="#82ca9d" name="Submit Count" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
});

export default RunSubmitStatsChart;
