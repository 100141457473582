import React, { useState, useRef, useEffect } from 'react';
import { Upload, FileImage, Trash2, X } from 'lucide-react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import toast from 'react-hot-toast';

const Button = ({ children, onClick, variant = 'primary', className = '', ...props }) => {
  const variantStyles = {
    primary:
      'bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50',
    destructive:
      'bg-red-600 text-white hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50',
    outline:
      'border border-zinc-600 text-zinc-300 hover:bg-zinc-800 focus:ring-2 focus:ring-zinc-500 focus:ring-opacity-50',
  };

  return (
    <button
      onClick={onClick}
      className={`
        flex items-center justify-center gap-2 px-4 py-2 rounded-lg 
        transition-all duration-300 ease-in-out 
        disabled:opacity-50 disabled:cursor-not-allowed
        ${variantStyles[variant]}
        ${className}
      `}
      {...props}
    >
      {children}
    </button>
  );
};

const ManageAds = () => {
  const [uploadGif, setUploadGif] = useState(null);
  const [uploadedGifs, setUploadedGifs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchUploadedGifs();
  }, []);

  const fetchUploadedGifs = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://dev-main.takeuforward.org/admin/api/blogs/getAllAds');
      if (!response.ok) {
        throw new Error(`Failed to fetch GIFs: ${response.status}`);
      }
      const data = await response.json();
      setUploadedGifs(data.ads);
      setError(null);
    } catch (err) {
      console.error('Error fetching GIFs:', err);
      setError('Failed to load GIFs. Please try again later.');
      toast.error('Failed to load GIFs. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/gif') {
      setUploadGif({
        id: Date.now().toString(),
        file: file,
        url: URL.createObjectURL(file),
        fileName: file.name
      });
      toast.success('GIF selected successfully');
    } else if (file) {
      toast.error('Please select a GIF file');
    }
    event.target.value = null;
  };

  const handleCancelUpload = () => {
    setUploadGif(null);
    toast('Upload cancelled');
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleUploadGif = async () => {
    if (!uploadGif) return;
    
    toast.loading('Uploading GIF...', { id: 'uploadGif' });
    
    try {
      const formData = new FormData();
      formData.append('file', uploadGif.file);
      
      const response = await fetch('https://dev-main.takeuforward.org/admin/api/blogs/uploadAd', {
        method: 'POST',
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error('Upload failed');
      }
      
      const result = await response.json();
      console.log('Upload successful:', result);
      
      fetchUploadedGifs();
      setUploadGif(null);
      
      toast.success('GIF uploaded successfully', { id: 'uploadGif' });
      
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Failed to upload GIF. Please try again.', { id: 'uploadGif' });
    }
  };

  const handleDeleteGif = async (gifId) => {
    if (!gifId) return;
    
    toast.loading('Deleting GIF...', { id: 'deleteGif' });
    
    try {
      const response = await fetch(`https://dev-main.takeuforward.org/admin/api/blogs/deleteAd/${gifId}`, {
        method: 'DELETE',
      });
      
      if (!response.ok) {
        throw new Error('Delete failed');
      }
      
      const result = await response.json();
      console.log('Delete successful:', result);
      
      fetchUploadedGifs();
      
      toast.success('GIF deleted successfully', { id: 'deleteGif' });
      
    } catch (error) {
      console.error('Delete error:', error);
      toast.error('Failed to delete GIF. Please try again.', { id: 'deleteGif' });
    }
  }

  return (
    <div className='flex text-zinc-200 h-screen overflow-hidden font-sans'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden h-full'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className='p-3 sm:p-4 md:p-6 h-full'>
          <div className='mx-auto h-full'>
            <div className='bg-[#1E1E1E] rounded-xl md:rounded-2xl border border-zinc-800 overflow-hidden h-full'>
              <div className='px-4 sm:px-6 py-3 sm:py-4 flex flex-col sm:flex-row sm:items-center justify-between border-b border-zinc-700 gap-3'>
                <h2 className='text-xl sm:text-2xl font-bold text-zinc-100 flex items-center gap-2'>
                  <FileImage className='text-blue-400' size={22} />
                  Manage Ad GIFs
                </h2>

                <div className='flex items-center'>
                  <input
                    type='file'
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    accept='image/gif'
                    className='hidden'
                  />
                  <Button
                    onClick={triggerFileInput}
                    variant='outline'
                    className='w-full sm:w-auto hover:bg-zinc-700'
                  >
                    <Upload size={16} className="mr-1" /> 
                    <span>Select GIF</span>
                  </Button>
                </div>
              </div>

              {uploadGif && (
                <div className='p-4 sm:p-6 border-b border-zinc-700'>
                  <div className='flex flex-col md:flex-row gap-4 md:gap-6 md:items-center'>
                    <div className='w-full md:w-2/5 lg:w-1/3'>
                      <div className='relative rounded-lg overflow-hidden border border-zinc-700 bg-zinc-900'>
                        <div className='flex items-center justify-center' style={{ minHeight: '160px' }}>
                          <img
                            src={uploadGif.url}
                            alt='GIF Preview'
                            className='w-full h-full object-contain max-h-64'
                          />
                        </div>
                        <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 p-2 text-xs truncate'>
                          {uploadGif.fileName}
                        </div>
                      </div>
                    </div>
                    
                    <div className='flex flex-col space-y-3 w-full md:w-auto'>
                      <Button 
                        onClick={handleUploadGif} 
                        className='w-full md:w-auto px-4 py-2.5 font-medium'
                      >
                        <Upload size={18} /> Upload this GIF
                      </Button>
                      <Button 
                        onClick={handleCancelUpload} 
                        variant='outline' 
                        className='w-full md:w-auto px-4 py-2.5'
                      >
                        <X size={18} /> Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              <div className='p-4 sm:p-6'>
                <div className='flex items-center justify-between mb-4'>
                  <h3 className='text-lg font-semibold'>Uploaded GIFs</h3>
                  {!loading && !error && uploadedGifs.length > 0 && (
                    <span className='text-sm text-zinc-400'>{uploadedGifs.length} GIF{uploadedGifs.length !== 1 ? 's' : ''}</span>
                  )}
                </div>
                
                {loading ? (
                  <div className='text-center py-8 sm:py-12'>
                    <div className='animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500 mx-auto'></div>

                    <p className='mt-4 text-zinc-400'>Loading GIFs...</p>
                  </div>
                ) : error ? (
                  <div className='text-center py-8 sm:py-12 text-red-400'>
                    <p>{error}</p>
                    <Button onClick={fetchUploadedGifs} className='mt-4'>Retry</Button>
                  </div>
                ) : uploadedGifs.length === 0 ? (
                  <div className='text-center py-8 sm:py-12 rounded-lg border border-dashed border-zinc-700 text-zinc-500 bg-zinc-900 bg-opacity-50'>
                    <FileImage size={40} className='mx-auto mb-3 text-zinc-600' />
                    <p className='px-4'>No GIFs uploaded yet. Click "Select GIF" to get started.</p>
                  </div>
                ) : (
                  <div className='grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 sm:gap-4 md:gap-5'>
                    {uploadedGifs.map((gif) => (
                      <div
                        key={gif.id}
                        className='relative group rounded-lg overflow-hidden 
                        border border-zinc-800 bg-zinc-900
                        transition-all duration-300 
                        hover:border-zinc-600 hover:shadow-lg'
                      >
                        <div className='aspect-square flex items-center justify-center p-1 overflow-hidden'>
                          <img
                            src={gif.url}
                            alt={gif.fileName || 'Uploaded GIF'}
                            className='w-full h-full object-contain opacity-90 group-hover:opacity-100 transition-opacity'
                          />
                        </div>
                        {gif.fileName && (
                          <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 py-1.5 px-2 text-xs truncate'>
                            {gif.fileName}
                          </div>
                        )}
                        <div className='absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity'>
                          <button 
                            onClick={()=>handleDeleteGif(gif.id)}
                            className='bg-red-600 hover:bg-red-700 text-white p-1.5 rounded-full shadow-lg'
                            title="Delete GIF"
                          >
                            <Trash2 size={16} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ManageAds;