import { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { FileImage, ExternalLink, Check, X, Trash2, RefreshCw } from 'lucide-react';

const ManagePosts = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('pending');
  const [pendingPosts, setPendingPosts] = useState([]);
  const [approvedPosts, setApprovedPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPendingPosts = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://dev-main.takeuforward.org/admin/api/slack/get-post');
      if (response.ok) {
        const data = await response.json();
        setPendingPosts(data.posts);
      } else {
        console.error('Failed to fetch pending posts');
      }
    } catch (error) {
      console.error('Error fetching pending posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchApprovedPosts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://dev-main.takeuforward.org/admin/api/slack/get-approved-post',
      );
      if (response.ok) {
        const data = await response.json();
        setApprovedPosts(data.posts);
      } else {
        console.error('Failed to fetch approved posts');
      }
    } catch (error) {
      console.error('Error fetching approved posts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingPosts();
  }, []);

  useEffect(() => {
    if (activeTab === 'pending') {
      fetchPendingPosts();
    } else if (activeTab === 'approved') {
      fetchApprovedPosts();
    }
  }, [activeTab]);

  const handleApprove = async (postId) => {
    try {
      const response = await fetch(
        `https://dev-main.takeuforward.org/admin/api/slack/approve-post/${postId}`,
        {
          method: 'POST',
        },
      );

      if (response.ok) {
        fetchPendingPosts();
      } else {
        console.error('Failed to approve post');
      }
    } catch (error) {
      console.error('Error approving post:', error);
    }
  };

  const handleReject = async (postId) => {
    try {
      const response = await fetch(
        `https://dev-main.takeuforward.org/admin/api/slack/reject-post/${postId}`,
        {
          method: 'POST',
        },
      );

      if (response.ok) {
        fetchPendingPosts();
      } else {
        console.error('Failed to reject post');
      }
    } catch (error) {
      console.error('Error rejecting post:', error);
    }
  };

  const handleRemove = async (postId) => {
    try {
      const response = await fetch(
        `https://dev-main.takeuforward.org/admin/api/slack/remove-post/${postId}`,
        {
          method: 'POST',
        },
      );

      if (response.ok) {
        fetchApprovedPosts();
      } else {
        console.error('Failed to remove post');
      }
    } catch (error) {
      console.error('Error removing post:', error);
    }
  };

  const getPlatformName = (platform) => {
    if (platform === 'twitter') return 'Twitter';
    if (platform === 'linkedin') return 'LinkedIn';
    return platform.charAt(0).toUpperCase() + platform.slice(1);
  };

  return (
    <div className='flex text-zinc-200 h-screen overflow-hidden font-sans'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden h-full'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className='p-3 sm:p-4 md:p-6 h-full'>
          <div className='mx-auto h-full'>
            <div className='bg-[#1E1E1E] rounded-xl md:rounded-2xl border border-zinc-800 overflow-auto scrollbar-none h-full'>
              <div className='px-4 sm:px-6 py-3 sm:py-4 flex flex-col sm:flex-row sm:items-center justify-between border-b border-zinc-700 gap-3'>
                <h2 className='text-xl sm:text-2xl font-bold text-zinc-100 flex items-center gap-2'>
                  <FileImage className='text-blue-400' size={22} />
                  Manage Landing Page Posts
                </h2>

                <div className='flex items-center space-x-2'>
                  <button
                    className={`px-4 py-2 rounded-lg ${activeTab === 'pending' ? 'bg-blue-600' : 'bg-zinc-800'}`}
                    onClick={() => setActiveTab('pending')}
                  >
                    Posts To Be Approved
                  </button>
                  <button
                    className={`px-4 py-2 rounded-lg ${activeTab === 'approved' ? 'bg-blue-600' : 'bg-zinc-800'}`}
                    onClick={() => setActiveTab('approved')}
                  >
                    Approved Posts
                  </button>
                  <button
                    className='bg-zinc-800 hover:bg-zinc-700 transition-colors rounded-lg p-2'
                    onClick={() =>
                      activeTab === 'pending' ? fetchPendingPosts() : fetchApprovedPosts()
                    }
                    title='Refresh'
                  >
                    <RefreshCw size={18} />
                  </button>
                </div>
              </div>

              <div className='p-4 sm:p-6'>
                {loading ? (
                  <div className='flex justify-center items-center h-64'>
                    <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500'></div>
                  </div>
                ) : (
                  <>
                    {activeTab === 'pending' && (
                      <div>
                        <h3 className='text-lg font-semibold mb-4 text-zinc-100'>
                          Posts Waiting for Approval
                        </h3>
                        <div className='grid gap-4'>
                          {pendingPosts.length > 0 ? (
                            pendingPosts.map((post) => (
                              <div
                                key={post.id || post._id}
                                className='bg-zinc-900 border border-zinc-800 rounded-lg p-4'
                              >
                                <div className='flex justify-between items-start mb-3'>
                                  <div>
                                    <div className='font-medium flex items-center gap-2'>
                                      {getPlatformName(post.platform)} - {post.name}
                                      {post.url && (
                                        <a
                                          href={post.url}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          className='text-blue-400 hover:text-blue-300'
                                        >
                                          <ExternalLink size={16} />
                                        </a>
                                      )}
                                    </div>
                                    <div className='text-sm text-zinc-400 mt-1'>
                                      @{post.username}
                                    </div>
                                  </div>
                                  <div className='flex items-center gap-2'>
                                    <button
                                      onClick={() => handleApprove(post.messID)}
                                      className='bg-green-600 hover:bg-green-700 transition-colors rounded-full p-1'
                                      title='Approve'
                                    >
                                      <Check size={18} />
                                    </button>
                                    <button
                                      onClick={() => handleReject(post.messID)}
                                      className='bg-red-600 hover:bg-red-700 transition-colors rounded-full p-1'
                                      title='Reject'
                                    >
                                      <X size={18} />
                                    </button>
                                  </div>
                                </div>
                                {post.data && (
                                  <div className='mt-2 bg-zinc-800 p-3 rounded-lg text-sm'>
                                    <div
                                      className='line-clamp-3'
                                      dangerouslySetInnerHTML={{ __html: post.data }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className='text-center py-8 text-zinc-400'>
                              No pending posts found.
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {activeTab === 'approved' && (
                      <div>
                        <h3 className='text-lg font-semibold mb-4 text-zinc-100'>Approved Posts</h3>
                        <div className='grid gap-4'>
                          {approvedPosts.length > 0 ? (
                            approvedPosts.map((post) => (
                              <div
                                key={post.id}
                                className='bg-zinc-900 border border-zinc-800 rounded-lg p-4'
                              >
                                <div className='flex justify-between items-start mb-3'>
                                  <div>
                                    <div className='font-medium flex items-center gap-2'>
                                      {getPlatformName(post.platform)} - {post.name}
                                      {post.url && (
                                        <a
                                          href={post.url}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          className='text-blue-400 hover:text-blue-300'
                                        >
                                          <ExternalLink size={16} />
                                        </a>
                                      )}
                                    </div>
                                    <div className='text-sm text-zinc-400 mt-1'>
                                      @{post.username}
                                    </div>
                                  </div>
                                  <div className='flex items-center gap-2'>
                                    <button
                                      onClick={() => handleRemove(post.id)}
                                      className='bg-red-600 hover:bg-red-700 transition-colors rounded-full p-1'
                                      title='Remove from Landing'
                                    >
                                      <Trash2 size={18} />
                                    </button>
                                  </div>
                                </div>
                                {post.content && (
                                  <div className='mt-2 bg-zinc-800 p-3 rounded-lg text-sm'>
                                    <div
                                      className='line-clamp-3'
                                      dangerouslySetInnerHTML={{ __html: post.content }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className='text-center py-8 text-zinc-400'>
                              No approved posts found.
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ManagePosts;
