import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFetchDetails, setFetchedDataPI } from '../../slices/callsSlice';
import { fetchUsersCount } from '../../services/operations/calls';
import { format } from 'date-fns';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { wspStatus, fndStatus } from '.';

const SalesPiChart = ({ userType, startDate, endDate }) => {
  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.calls.fetchedData);

  const formattedStartDate = useMemo(() => format(startDate, 'yyyy-MM-dd HH:mm:ss'), [startDate]);
  const formattedEndDate = useMemo(() => format(endDate, 'yyyy-MM-dd HH:mm:ss'), [endDate]);

  const selectedStatusList = useMemo(
    () => (userType === 'whatsapp' ? wspStatus : fndStatus),
    [userType],
  );

  const fetchPiStatsDetails = useMemo(
    () => ({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      isEnrolled: 'all',
    }),
    [formattedStartDate, formattedEndDate],
  );

  useEffect(() => {
    if (formattedStartDate && formattedEndDate)
      dispatch(setFetchDetails({ startDate: formattedStartDate, endDate: formattedEndDate }));
  }, [dispatch, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    if (!(fetchPiStatsDetails?.startDate && fetchPiStatsDetails?.endDate)) return;
    const fetchAllUsers = async () => {
      try {
        const results = await dispatch(fetchUsersCount(userType, fetchPiStatsDetails));

        dispatch(setFetchedDataPI(results));
        console.log(results);
      } catch (error) {
        console.error('Error in fetchAllUsers:', error);
      }
    };

    fetchAllUsers();
  }, [dispatch, userType, fetchPiStatsDetails, selectedStatusList]);

  const totalCount = useMemo(
    () =>
      fetchedData?.count
        ? Object.values(fetchedData.count).reduce((sum, count) => sum + (count || 0), 0)
        : 0,
    [fetchedData],
  );

  const chartData = useMemo(() => {
    if (!fetchedData || !fetchedData.count) return [];

    // Clone the fetched data count object
    const countData = { ...fetchedData.count };

    // Determine which status list to use
    const statusList = userType === 'whatsapp' ? wspStatus : fndStatus;

    // Handle the extra count[""] case
    if (countData['']) {
      const targetCategory = userType === 'whatsapp' ? 'not interested' : 'not called';

      if (countData[targetCategory] !== undefined) {
        countData[targetCategory] += countData[''];
      } else {
        countData[targetCategory] = countData[''];
      }

      // Remove the empty string key
      delete countData[''];
    }

    if (countData['null']) {
      const targetCategory = userType === 'whatsapp' ? 'not interested' : 'not called';

      if (countData[targetCategory] !== undefined) {
        countData[targetCategory] += countData['null'];
      } else {
        countData[targetCategory] = countData['null'];
      }

      // Remove the empty string key
      delete countData[''];
    }

    // Ensure all statuses from wspStatus or fndStatus exist with at least 0 count
    const finalChartData = statusList.map((status) => ({
      category: status.value,
      count: countData[status.value] ?? 0, // Default to 0 if count is missing or null
      percentage: totalCount ? ((countData[status.value] ?? 0) / totalCount) * 100 : 0,
      color: status.color || '#ccc',
    }));

    console.log({ finalChartData });

    return finalChartData;
  }, [fetchedData, totalCount, userType]);

  return (
    <div className='flex flex-col items-center justify-center mt-8'>
      <div className='flex justify-center items-center mt-8'>
        <div className='mr-16 bg-gray-500 shadow-md rounded-md p-4 text-white w-60'>
          <div className='text-center mb-4'>
            <p className='text-lg font-semibold'>{`Total Count: ${totalCount}`}</p>
          </div>
          <div className='flex flex-col items-start'>
            {chartData.map((entry) => (
              <div key={entry.category} className='flex items-center mb-2'>
                <div className='w-4 h-4 mr-2' style={{ backgroundColor: entry.color }} />
                <span className='text-sm'>{`${entry.category}: ${entry.percentage.toFixed(2)}% (${entry.count})`}</span>
              </div>
            ))}
          </div>
        </div>
        <PieChart width={400} height={400} label>
          <Pie
            data={chartData}
            dataKey='percentage'
            nameKey='category'
            cx='50%'
            cy='50%'
            outerRadius={150}
          >
            {chartData.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            content={({ active, payload }) =>
              active && payload?.length ? (
                <div className='custom-tooltip bg-gray-700 text-white p-2 rounded'>
                  <p>{`Category: ${payload[0].name}`}</p>
                  <p>{`Count: ${fetchedData[payload[0].name]?.count}`}</p>
                  <p>{`Percentage: ${payload[0].value.toFixed(2)}%`}</p>
                </div>
              ) : null
            }
          />
        </PieChart>
      </div>
    </div>
  );
};

export default SalesPiChart;
