import React from 'react';

const JudgePerformanceStatsTableItem = ({
  num = 0,
  date = '',
  runPerformance = '{}',
  submitPerformance = '{}',
  selectedDate,
  onDateClick,
}) => {
  const timeRanges = ['1to3', '3to5', '5to8', '8+'];

  return (
    <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
      {/* Serial Number */}
      <td className='px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
        <div className='font-medium text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
          {num}.
        </div>
      </td>
      {/* Date */}
      <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
        <div
          className={`font-medium ${selectedDate && selectedDate === date ? 'text-red-500 font-bold' : 'dark:text-zinc-200 text-gray-800'} flex justify-center items-center cursor-pointer transition-colors duration-20 hover:text-red-800 dark:hover:text-red-800`}
          onClick={() => onDateClick(date)}
        >
          {date}
        </div>
      </td>
      {/* Run Performance Data */}
      {timeRanges.map((range) => (
        <td
          key={`run-${range}`}
          className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap text-center'
        >
          {runPerformance[range] ?? '-'}
        </td>
      ))}
      {/* Submit Performance Data */}
      {timeRanges.map((range) => (
        <td
          key={`submit-${range}`}
          className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap text-center'
        >
          {submitPerformance[range] ?? '-'}
        </td>
      ))}
    </tr>
  );
};

export default JudgePerformanceStatsTableItem;
