import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateFeedbackUserDetails } from "../services/operations/calls"; // Update the path as needed

const useUpdateFeedbackCallDetails = () => {
  const dispatch = useDispatch();

  const updateFeedbackCallDetails = useCallback(
    ({
      status,
      notes,
      email,
      callType,
    } = { status: "not called", notes: "", email: null, callType: null }) => {
      // Use defaults if any parameter is missing
      const payload = {
        status: status || "not called",
        notes: notes || "",
        email: email || null,
        callType: callType || null,
      };

      console.log("Updating Call Details:", payload);

      // Dispatch Redux action
      dispatch(updateFeedbackUserDetails(payload));
    },
    [dispatch]
  );

  return updateFeedbackCallDetails;
};

export default useUpdateFeedbackCallDetails;
