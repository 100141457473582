import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getInterviewExperiences } from '../../services/operations/interviewExperiences';
import InterviewFormModal from './InterviewFormModal'; // Import the form modal
import ApprovalToggle from './ApprovedToggle';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar'
import { StepBackIcon } from 'lucide-react';


function InterviewExperience() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const { InterviewExperience } = useSelector((state) => state.interviewExperience);

  useEffect(() => {
    dispatch(getInterviewExperiences(id));
  }, [id, dispatch]);

  if (!InterviewExperience) {
    return <div>Loading...</div>;
  }
  console.log(InterviewExperience.isApproved);
  return (
    <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div>
            {/* <h1>Edit Interview Experience</h1> */}
            <div className='mt-2 p-2 w-full mx-auto flex justify-between items-center'>
              <div className='flex items-center gap-1'>
                <button
                  onClick={() => navigate(-1)}
                  className='flex items-center gap-2 px-4 py-2 rounded-md shadow-md'
                >
                  <StepBackIcon />
                </button>
                <h1 className='text-2xl font-normal m-0 text-orange-400'>{InterviewExperience.companyDetails?.name ?? 'Unknown Company'} {InterviewExperience.companyDetails?.position ?? 'Unknown Position'}</h1>
              </div>
              <div>
                <ApprovalToggle page={'interview'} initialApproved={InterviewExperience?.isApproved} id={InterviewExperience.id} />
              </div>
            </div>

            <InterviewFormModal
              initialData={{
                personal: {
                  fullName: InterviewExperience.personalInfo?.fullName ?? 'Anonymous',
                  email: InterviewExperience.personalInfo?.email ?? '',
                  workExp: InterviewExperience.personalInfo?.workExp ?? 'N/A',
                  currentRole: InterviewExperience.personalInfo?.currentRole ?? 'N/A',
                  linkedin: InterviewExperience.personalInfo?.linkedin ?? '',
                },
                company: {
                  name: InterviewExperience.companyDetails?.name ?? 'Unknown',
                  position: InterviewExperience.companyDetails?.position ?? 'Unknown',
                  difficulty: InterviewExperience.companyDetails?.difficulty ?? 'N/A',
                  interviewMode: InterviewExperience.companyDetails?.interviewMode ?? 'N/A',
                  applicationMode: InterviewExperience.companyDetails?.applicationMode ?? 'N/A',
                  interviewTimeline: InterviewExperience.companyDetails?.interviewTimeline ?? 'N/A',
                },
                rounds: InterviewExperience.interviewRounds?.map((round) => ({
                  name: round?.name ?? 'N/A',
                  type: round?.type ?? 'N/A',
                  mode: round?.mode ?? 'N/A',
                  difficulty: round?.difficulty ?? 'N/A',
                  duration: round?.duration ?? 'N/A',
                  overview: round?.overview ?? 'No overview available',
                  questions: round?.questions ?? [],
                })) ?? [],
                feedback: {
                  result: InterviewExperience.overallFeedback?.result ?? 'N/A',
                  salaryRange: InterviewExperience.overallFeedback?.salaryRange ?? 'N/A',
                  preparationTips: InterviewExperience.overallFeedback?.preparationTips ?? 'No tips available',
                  generalAdvice: InterviewExperience.overallFeedback?.generalAdvice ?? 'No advice provided',
                },
                isAnonymous: InterviewExperience.isAnonymous ?? false,
                id: InterviewExperience.id
              }}
              isEditMode={true}
            />

          </div>
        </main>
      </div>
    </div>
  );

}

export default InterviewExperience;

