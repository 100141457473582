import { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import CategoryTable from '../../components/Table/CategoryTable/CategoryTable';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import { Controller, useForm } from 'react-hook-form';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import { deleteExperienceDetails } from '../../services/operations/interviewExperiences';



const AllInterviewExperiences = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { AllInterviewExperiences } = useSelector((state) => state.interviewExperience);
    const [modalOpen, setModalOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProblems, setFilteredProblems] = useState([]);
    const [fuse, setFuse] = useState(null);
    const navigate = useNavigate();


    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isSubmitted },
    } = useForm({
        defaultValues: {
            "id": "alokgupta1560_google_66lp",
            "email": "alokgupta1560@gmail.com",
            "slug": "google-sde-66lp",
            "title": "Google SDE Interview Experience – 2 Rounds | Remote | Medium | by Varad Gupta",
            "isApproved": 1,
            "created_at": "1740732513257",
            "isAnonymous": 0
        },
    });
    const dispatch = useDispatch();

    const handleAddProblem = (e) => {
        e.stopPropagation();
        setModalOpen(true);
    };

    const onSubmit = (data) => {
        // dispatch(addAptitudeProblem(data,setModalOpen,reset, isProd))

    };

    const initializeFuse = (problems) => {
        const options = {
            keys: ['title'],
            threshold: 0.3,
            includeScore: true,
        };
        return new Fuse(problems, options);
    };

    useEffect(() => {
        if (AllInterviewExperiences.length !== 0) {
            setFilteredProblems(AllInterviewExperiences);
            setFuse(initializeFuse(AllInterviewExperiences));
        }
    }, [AllInterviewExperiences]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.trim() === '') {
            setFilteredProblems(AllInterviewExperiences);
            return;
        }

        const results = fuse.search(term);
        const filtered = results.map((result) => result.item);

        setFilteredProblems(filtered);
    };

    const toggleSearch = () => {
        setSearchOpen(!searchOpen);
        if (searchOpen) {
            setSearchTerm('');
            setFilteredProblems(AllInterviewExperiences);
        }
    };

    const handleInterviewExperienceEdit = (e, prop) => {
        e.stopPropagation();
        navigate(`${prop.slug}`);
    }

    // const { isProd } = useSelector((state) => state.auth);
    const handleDeleteDSA = (id, setDeleteModalOpen) => {
        dispatch(deleteExperienceDetails(id));
        setDeleteModalOpen(false);
    }

    return (
        <div className='flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className='px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto'>
                        <div className='flex justify-between items-center px-6 py-4'>
                            <h2 className='text-2xl font-bold text-[#212121] dark:text-zinc-200'>
                                All Interview Experiences
                            </h2>
                        </div>
                        <div className='flex justify-between items-center px-6 py-4'>
                            <button
                                onClick={handleAddProblem}
                                className='bg-[#FFD3B5] dark:bg-zinc-800 text-[#212121] dark:text-zinc-200 font-semibold py-2 px-4 rounded-lg'
                            >
                                Add Interview Experiences
                            </button>
                            <button
                                onClick={toggleSearch}
                                className='bg-[#FFD3B5] dark:bg-zinc-800 text-[#212121] dark:text-zinc-200 font-semibold py-2 px-4 rounded-lg w-44'
                            >
                                {searchOpen ? 'Close Search' : 'Search'}
                            </button>
                        </div>
                        {searchOpen && (
                            <div className='flex justify-center items-center mb-4'>
                                <input
                                    autoFocus
                                    type='text'
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    placeholder='Search problems...'
                                    className='w-full max-w-2xl px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500'
                                />
                            </div>
                        )}

                        <div className='flex gap-y-2 flex-col justify-start'>
                            {filteredProblems.length > 0 ? (
                                <div className='flex flex-col p-4 '>
                                    <CategoryTable
                                        page='interview'
                                        topics={filteredProblems}
                                        setModalOpen={setModalOpen}
                                        modalOpen={modalOpen}
                                        handleDelete={handleDeleteDSA}
                                        handleEdit={handleInterviewExperienceEdit}
                                    />
                                </div>
                            ) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Interview Experiences to show
                                </div>
                            )}
                        </div>
                        <CategoryModal
                            page="Aptitude"
                            title='Add Problem'
                            setModalOpen={setModalOpen}
                            modalOpen={modalOpen}
                        >
                            <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Name
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='problem_name'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='problem_name'
                                                    type='text'
                                                    name='problem_name'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='problem_name'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        const filteredValue = e.target.value.toLowerCase().replace(/[\s/]/g, '-')
                                                        setValue("slug", filteredValue);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                            rules={{
                                                required: 'Name is required',
                                            }}
                                        />
                                        {isSubmitted && errors.name && (
                                            <p className='text-red-600 text-sm italic'>
                                                {errors.name.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Slug
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='slug'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='slug'
                                                    type='text'
                                                    name='slug'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='slug'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                validate: (value) => {
                                                    const validSlugRegex = /^[a-zA-Z0-9_-]+$/;
                                                    if (!validSlugRegex.test(value)) {
                                                        return 'Slug can only contain letters, numbers, underscores, and hyphens';
                                                    }
                                                    return true
                                                }
                                            }}
                                        />
                                        {isSubmitted && errors.slug && (
                                            <p className='text-red-600 text-sm italic'>
                                                {errors.slug.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-wrap -mx-3 mt-6'>
                                    <div className='w-full px-3'>
                                        <button type='submit' className='btn-brand-2 w-full'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AllInterviewExperiences;
