/* eslint-disable no-unused-vars */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FeedbackReport from './FeedbackReport';
import { useSelector } from 'react-redux';
import Transition from '../../utils/Transition';

const SalesFeedback = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [category, setCategory] = useState('all');
  const [categoryDisplay, setCategoryDisplay] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);

  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15, 18, 29, 59);

    return start;
  });

  const [endDate, setEndDate] = useState(() => {
    const now = new Date();

    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18, 29, 59);
    return end;
  });

  const [callTypeView, setCallTypeView] = useState('both');
  const [callType, setCallType] = useState('both');

  const [timeFrame, setTimeFrame] = useState('past 15 days');

  const modalContent = useRef(null);
  const [callTypeField, setCallTypeField] = useState(false);

  const count = useSelector((state) => state.calls.allUserCalls.count);
  const tabs = useMemo(() => ['both', 'post 24hrs', 'post 2w'], []);

  const [indicatorStyle, setIndicatorStyle] = useState({ width: '0px', left: '0px' });
  const buttonsRef = useRef([]);

  useEffect(() => {
    if (!buttonsRef.current) return;

    const activeIndex = tabs.indexOf(callTypeView);
    const activeButton = buttonsRef.current[activeIndex];

    if (activeButton) {
      setIndicatorStyle({
        width: `${activeButton.offsetWidth}px`,
        left: `${activeButton.offsetLeft}px`,
      });
    }
  }, [callTypeView, tabs]);

  const handleCategoryChange = (selectedValue) => {
    setCategoryDisplay(selectedValue);
    if (callTypeView !== 'both' || selectedValue === 'all') {
      setCategory(selectedValue);
      return;
    }
    setCallTypeField(true);
  };

  const handleCallTypeSelection = (value) => {
    setCallType(value);
    setCategory(categoryDisplay);
    setCallTypeField(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!callTypeField || !modalContent.current) return;

      if (!modalContent.current.contains(event.target)) {
        setCallTypeField(false);
        setCategoryDisplay(category);
      }
    };

    const handleKeyDown = (event) => {
      if (!callTypeField) return;

      if (event.key === 'Escape') {
        setCallTypeField(false);
        setCategoryDisplay(category);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [callTypeField, setCallTypeField, setCategoryDisplay, category]);

  useEffect(() => {
    const now = new Date();
    let start, end;

    if (timeFrame === 'past 15 days') {
      // Start: 15 days ago at 18:29:59
      start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15, 18, 29, 59);

      // End: Today at 18:29:59
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18, 29, 59);
    } else if (timeFrame === 'past month') {
      // Get the first day of last month at 18:29:59
      start = new Date(now.getFullYear(), now.getMonth() - 1, 1, 18, 29, 59);

      // Compute the date 15 days ago from today
      const fifteenDaysAgo = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 15,
        18,
        29,
        59,
      );

      // Get the last day of the previous month at 18:29:59
      const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0, 18, 29, 59);

      // Set end date: It should be 15 days ago if it's after the last day of the last month, otherwise last day of last month
      end = fifteenDaysAgo >= lastDayOfLastMonth ? lastDayOfLastMonth : fifteenDaysAgo;
    }

    setStartDate(start);
    setEndDate(end);
  }, [timeFrame]);

  return (
    <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
      <Sidebar
        type={pathname && pathname?.split('/')[1]}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className='relative flex flex-col flex-1 overflow-y-auto no-scrollbar overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto'>
          <div className='flex gap-x-4 border-b justify-between pb-4 items-center'>
            <div>
              {['past 15 days', 'past month'].map((tab) => {
                let label = tab.toUpperCase();

                if (tab === 'past month') {
                  // Get current date
                  const now = new Date();

                  // Get last month's name (e.g., "Feb")
                  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                  const monthName = lastMonth
                    .toLocaleString('en-US', { month: 'short' })
                    .toUpperCase();

                  label += ` : ${monthName}`;
                }

                return (
                  <button
                    key={tab}
                    onClick={() => {
                      setTimeFrame(tab);
                      setCurrentPage(1);
                    }}
                    className={`px-4 py-2 ${
                      timeFrame === tab
                        ? 'border-b-2 border-brand text-brand font-bold text-lg'
                        : 'text-gray-600'
                    }`}
                  >
                    {label}
                  </button>
                );
              })}
            </div>

            <div className='flex flex-col items-start justify-center py-4'>
              <div className='flex items-center space-x-4'>
                <span className='font-semibold'>Count:</span>
                <span className='block w-50 h-10 text-white-950 border border-gray-200 rounded-md py-2 px-3 cursor-pointer focus:outline-none bg-#fff dark:border-zinc-700 text-base'>
                  {count}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-start gap-x-4 justify-between w-full mx-6 px-12'>
          <div className='flex items-start gap-x-4 justify-between mt-4 w-full mx-6'>
            {/* Category filter */}
            <div className='flex items-center gap-x-4'>
              <label
                htmlFor='category'
                className='text-md font-medium text-gray-800 dark:text-zinc-200'
              >
                Category:
              </label>
              <select
                id='category'
                value={categoryDisplay || ''}
                onChange={(e) => handleCategoryChange(e.target.value)}
                className='form-select'
              >
                <option value='all'>All</option>
                <option value='not called'>Not Called</option>
                <option value='wrong num'>Wrong Number</option>
                <option value='unreachable'>Unreachable</option>
                <option value='unanswered'>Unanswered</option>
                <option value='feedback happy'>Feedback Given - Happy</option>
                <option value='feedback sad'>Feedback Given - Sad</option>
              </select>
            </div>
            <div className='flex gap-x-4 justify-start items-center'>
              <div className='font-bold italic'>Status View:</div>
              <div className='relative flex gap-x-2'>
                {/* Sliding Indicator - Dynamic Width & Position */}
                <div
                  className='absolute bottom-0 h-8 bg-gray-300 dark:bg-zinc-900 rounded-lg transition-all duration-300 ease-in-out border-2 border-brand '
                  style={indicatorStyle}
                />

                {tabs.map((tab, index) => (
                  <button
                    key={tab}
                    ref={(el) => (buttonsRef.current[index] = el)}
                    onClick={() => {
                      setCallTypeView(tab);
                    }}
                    className={`relative z-10 px-2 py-1 transition-all duration-300 ${
                      callTypeView === tab
                        ? 'rounded-lg text-brand'
                        : 'text-gray-600 hover:text-brand'
                    }`}
                  >
                    {tab.toUpperCase()}
                  </button>
                ))}
              </div>
            </div>
            {/*<DateRangePicker
              className='items-center'
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />*/}
          </div>
        </div>
        <div className='mx-6 mt-6 px-3'>
          <FeedbackReport
            category={category}
            startDate={startDate}
            endDate={endDate}
            callTypeView={callTypeView}
            callType={callType}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <>
        <Transition
          className='fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity'
          show={callTypeField}
          enter='transition ease-out duration-200'
          enterStart='opacity-0'
          enterEnd='opacity-100'
          leave='transition ease-out duration-100'
          leaveStart='opacity-100'
          leaveEnd='opacity-0'
          aria-hidden='true'
        />
        <Transition
          className='fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6'
          show={callTypeField}
          enter='transition ease-in-out duration-200'
          enterStart='opacity-0 translate-y-4'
          enterEnd='opacity-100 translate-y-0'
          leave='transition ease-in-out duration-200'
          leaveStart='opacity-100 translate-y-0'
          leaveEnd='opacity-0 translate-y-4'
        >
          <div
            ref={modalContent}
            className='bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar w-[30%] max-h-[80%] flex flex-col p-4 sm:p-6'
          >
            <div className='flex flex-col gap-4'>
              {/* Modal Title */}
              <h2 className='text-xl font-semibold text-gray-800 dark:text-gray-200 text-center'>
                Select Call Type
              </h2>

              {/* Call Type Buttons */}
              <button
                className='w-full py-3 px-6 text-lg font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition duration-300'
                onClick={() => handleCallTypeSelection('post 24hrs')}
              >
                Post 24 Hours
              </button>
              <button
                className='w-full py-3 px-6 text-lg font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition duration-300'
                onClick={() => handleCallTypeSelection('post 2w')}
              >
                Post 2 Weeks
              </button>
            </div>
          </div>
        </Transition>
      </>
    </div>
  );
};

export default SalesFeedback;
