/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import FeedbackDetailsModal from '../../SalesModals/FeedbackDetailsModal';
import moment from 'moment';
import { differenceInHours } from 'date-fns';
import StatusIndicator from './StatusIndicator';
import { statusLabels } from './StatusIndicator';
import useUpdateFeedbackCallDetails from '../../../hooks/useUpdateFeedbackCallDetails';

const FeedbackTableItem = (props) => {
  const {
    num,
    email,
    phoneNumber,
    location,
    purchaseDate,
    planName,
    activeDays,
    lastSeen,
    postPurchaseCall,
    postPurchaseCall2w,
    callType,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [callStatus, setCallStatus] = useState({
    call1: postPurchaseCall,
    call2: postPurchaseCall2w,
  });

  const updateFeedbackCallDetails = useUpdateFeedbackCallDetails();

  const getLastSeenText = (date) => {
    if (!date) return '-';
    const diff = differenceInHours(new Date(), new Date(date));
    return diff < 24 ? `${diff} hours ago` : `${Math.floor(diff / 24)} days ago`;
  };

  const handleDetailView = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };
  const callTypeKey = callType === 'post 24hrs' ? 'call1' : callType === 'post 2w' ? 'call2' : null;

  const handleStatusChange = (key, value) => {
    setCallStatus((prev) => ({ ...prev, [key]: value }));
    updateFeedbackCallDetails({
      email,
      callType: Number(key.replace(/\D/g, '')),
      notes: "<p>No Notes Available</p>",
      status: value
    })
  };

  useEffect(() =>{
    setCallStatus({
      call1: postPurchaseCall,
      call2: postPurchaseCall2w,
    })
  }, [setCallStatus, postPurchaseCall, postPurchaseCall2w])

  return (
    <>
      <tbody className='text-[13px]'>
        <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
          <td className='px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {num}.
            </div>
          </td>
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {email}
            </div>
          </td>
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {phoneNumber}
            </div>
          </td>
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {location}
            </div>
          </td>
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {purchaseDate ? moment(purchaseDate).format('MMM DD, YYYY, h:mm A') : 'N/A'}
            </div>
          </td>
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {planName}
            </div>
          </td>
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {activeDays ?? 0}
            </div>
          </td>
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {getLastSeenText(lastSeen)}
            </div>
          </td>

          {/* Call Status Column */}
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center gap-2'>
              {callType === 'both' ? (
                <>
                  <StatusIndicator status={callStatus.call1} />
                  <StatusIndicator status={callStatus.call2} />
                </>
              ) : callTypeKey ? (
                <div className='flex gap-2 items-center'>
                  <StatusIndicator status={callStatus[callTypeKey]} />
                  <select
                    id='category'
                    value={callStatus[callTypeKey] || ''}
                    className='form-select'
                    onChange={(e) => handleStatusChange(callTypeKey, e.target.value)}
                  >
                    {[
                      'not called',
                      'wrong num',
                      'unreachable',
                      'unanswered',
                      'feedback happy',
                      'feedback sad',
                    ].map((option) => (
                      <option key={option} value={option}>
                        {statusLabels[option]}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
          </td>

          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              <button
                onClick={(e) => {
                  handleDetailView(e);
                }}
                className='text-brand underline cursor-pointer flex items-center'
                type='button'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-eye'
                >
                  <path d='M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0' />
                  <circle cx='12' cy='12' r='3' />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <FeedbackDetailsModal
        email={email}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        getLastSeenText={getLastSeenText}
        callType={callType}
      />
    </>
  );
};

export default FeedbackTableItem;
