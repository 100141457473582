import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import ContentAccordion from '../../components/Accordion/ContentAccordion';
import {  Grip, GripHorizontal, Plus, Trash2Icon } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { getAllSubjectProblems, removeProblemFromOrder, setSubjectOrder } from '../../services/operations/new-syllabus';
import { setAllSubjectProblems } from '../../slices/new-syllabusSlice';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import Fuse from 'fuse.js';
import toast from 'react-hot-toast';

const SyllabusOrder = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { AllSubjectProblems, AllAptitudeProblems, AllDSAProblems, AllTheoryProblems, AllQuizProblems } = useSelector(
        (state) => state.new_syllabus,
    );
    const dispatch = useDispatch();
    const { isProd } = useSelector((state) => state.auth)

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    const [listModalOpen, setListModalOpen] = useState(false);

    const { subject } = useParams();

    useEffect(() => {
        setSelectedCategory(null);
        setSelectedSubCategory(null);
        dispatch(getAllSubjectProblems(subject));

        // eslint-disable-next-line
    }, [subject,dispatch]);

    const onDrag = (result) => {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        let updatedAllProblems = [];

        if (type === 'categories') {
            const newCategories = Array.from(AllSubjectProblems);
            const [reorderedItem] = newCategories.splice(source.index, 1);
            newCategories.splice(destination.index, 0, reorderedItem);
            updatedAllProblems = newCategories.map((category, index) => ({
                ...category,
                rank: index,
            }));
        } else if (type === 'subcategories') {
            const updatedCategories = AllSubjectProblems.map((category) => {
                if (category.category_id?.toString() !== source.droppableId) {
                    return category;
                }
                const newSubcategories = Array.from(category.subcategories);
                const [reorderedItem] = newSubcategories.splice(source.index, 1);
                newSubcategories.splice(destination.index, 0, reorderedItem);
                return {
                    ...category,
                    subcategories: newSubcategories.map((subCategory, index) => ({
                        ...subCategory,
                        rank: index,
                    })),
                };
            });
            updatedAllProblems = updatedCategories;
        } else if (type === 'problems') {
            const updatedCategories = AllSubjectProblems.map((category) => {
                if (category.subcategories) {

                const updatedSubcategories = category.subcategories.map((subCategory) => {
                    if (subCategory.id?.toString() !== source.droppableId) {
                        return subCategory;
                    }
                    const newProblems = Array.from(subCategory.problems);
                    const [reorderedItem] = newProblems.splice(source.index, 1);
                    newProblems.splice(destination.index, 0, reorderedItem);
                    return {
                        ...subCategory,
                        problems: newProblems.map((problem, index) => ({
                            ...problem,
                            rank: index,
                        })),
                    };
                });
                return {
                    ...category,
                    subcategories: updatedSubcategories,
                };
            } else {
                const newProblems = Array.from(category.problems);
                const [reorderedItem] = newProblems.splice(source.index, 1);
                newProblems.splice(destination.index, 0, reorderedItem);
                return {
                    ...category,
                    problems: newProblems.map((problem, index) => ({
                        ...problem,
                        rank: index,
                    })),
                };
            }
            });
            updatedAllProblems = updatedCategories;
        }

        dispatch(setAllSubjectProblems(updatedAllProblems));
    };

    const [filteredProblems, setFilteredProblems] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [fuse, setFuse] = useState(null);

    const initializeFuse = (problems) => {
        const options = {
            keys: ['problem_name'],
            threshold: 0.5,
            includeScore: true,
        };
        return new Fuse(problems, options);
    };

    useEffect(() => {
        if (AllDSAProblems.length !== 0) {
            const allProblems = [...AllDSAProblems, ...AllTheoryProblems, ...AllQuizProblems, ...AllAptitudeProblems];
            setFilteredProblems(allProblems);
            setFuse(initializeFuse(allProblems));
        }
        // eslint-disable-next-line
    }, [AllDSAProblems,dispatch]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.trim() === '') {
            setFilteredProblems(AllDSAProblems);
            return;
        }

        const results = fuse.search(term);
        const filtered = results.map((result) => result.item);

        setFilteredProblems(filtered);
    };

    const handleAddProblem = (problem) => {

        console.log("category",selectedCategory);
        console.log(problem);
        // Create a deep copy of the current state to avoid direct mutations
        const updated_all_problems = JSON.parse(JSON.stringify(AllSubjectProblems));

        // Find the target category
        const categoryIndex = updated_all_problems.findIndex(
            (category) => category.category_id === selectedCategory
        );

        if (categoryIndex === -1) {
            console.error("Selected category not found");
            return;
        }


        // updated_all_problems.forEach((category) => {
        //     const problemIndex = category.problems.findIndex((p) => p.problem_id === problem.id);
        //     // If problem exists and it's not in our target location
        //     if (problemIndex !== -1) {
        //         // Remove the problem
        //         category.problems.splice(problemIndex, 1);
        //         // Reorder ranks for remaining problems
        //         category.problems = category.problems.map((p, index) => ({
        //             ...p,
        //             rank: index
        //         }));

        //     }
        // });

        // Check if problem already exists in the target category
        const problemExists = updated_all_problems[categoryIndex].problems.some(
            (existingProblem) => existingProblem.problem_id === problem.id
        );

        if (problemExists) {
            toast.error("Problem already exists in this category");
            return;
        }

        // Add rank property to the new problem
        const newProblem = {
            ...problem,
            problem_id: problem.id,
            rank: updated_all_problems[categoryIndex].problems.length
        };

        // Add the problem to the target category
        updated_all_problems[categoryIndex].problems.push(newProblem);

        // Update the state
        dispatch(setAllSubjectProblems(updated_all_problems));

        // Close the modal after adding the problem
        setListModalOpen(false);
    };
    



    const handleAddProblemWithSubcat = (problem) => {
        console.log(problem);
        // Create a deep copy of the current state to avoid direct mutations
        const updated_all_problems = JSON.parse(JSON.stringify(AllSubjectProblems));
    
        // Find the target category
        const categoryIndex = updated_all_problems.findIndex(
            (category) => category.category_id === selectedCategory
        );
    
        if (categoryIndex === -1) {
            console.error("Selected category not found");
            return;
        }
    
        // Find the target subcategory
        const subCategoryIndex = updated_all_problems[categoryIndex].subcategories.findIndex(
            (subCategory) => subCategory.id === selectedSubCategory
        );
    
        if (subCategoryIndex === -1) {
            console.error("Selected subcategory not found");
            return;
        }
    
        // // First, remove the problem from all other categories and subcategories
        // updated_all_problems.forEach((category, catIdx) => {
        //     category.subcategories.forEach((subcategory, subCatIdx) => {
        //         const problemIndex = subcategory.problems.findIndex(
        //             (p) => p.problem_id === problem.id
        //         );
    
        //         // If problem exists and it's not in our target location
        //         if (problemIndex !== -1 && 
        //             !(catIdx === categoryIndex && subCatIdx === subCategoryIndex)) {
        //             // Remove the problem
        //             subcategory.problems.splice(problemIndex, 1);
                    
        //             // Reorder ranks for remaining problems
        //             subcategory.problems = subcategory.problems.map((p, index) => ({
        //                 ...p,
        //                 rank: index
        //             }));
    
        //         }
        //     });
        // });
    
        // Check if problem already exists in the target subcategory
        const problemExists = updated_all_problems[categoryIndex].subcategories[subCategoryIndex].problems.some(
            (existingProblem) => existingProblem.problem_id === problem.id
        );

        if (problemExists) {
            toast.error("Problem already exists in this subcategory");
            return;
        }
    
        // Add rank property to the new problem
        const newProblem = {
            ...problem,
            problem_id: problem.id,
            rank: updated_all_problems[categoryIndex].subcategories[subCategoryIndex].problems.length
        };
    
        // Add the problem to the target subcategory
        updated_all_problems[categoryIndex].subcategories[subCategoryIndex].problems.push(newProblem);
    
        // Update the study problem count for the target category
        updated_all_problems[categoryIndex].studyProblemCount = 
            updated_all_problems[categoryIndex].subcategories.reduce(
                (total, subCat) => total + subCat.problems.length,
                0
            );
    
        // Update the state
        dispatch(setAllSubjectProblems(updated_all_problems));
    
        // Close the modal after adding the problem
        setListModalOpen(false);
    };


    const SaveProblems = (category, subCategory) => {
        //get the category and subcategory
        const categoryIndex = AllSubjectProblems.findIndex((cat) => cat.category_id === category);

        let subCategoryIndex
        if (subCategory !== null) {       
        subCategoryIndex = AllSubjectProblems[categoryIndex].subcategories.findIndex(subCat => subCat.id === subCategory);
        }

        const problems = subCategory ? AllSubjectProblems[categoryIndex].subcategories[subCategoryIndex].problems : AllSubjectProblems[categoryIndex].problems;
        dispatch(setSubjectOrder(subject, category, subCategory, problems, "PROBLEM", isProd));
    }

    const SaveSubCategoryOrder = (category) => {

        const categoryIndex = AllSubjectProblems.findIndex((cat) => cat.category_id === category);
        const subCategories = AllSubjectProblems[categoryIndex].subcategories;
        
        dispatch(setSubjectOrder(subject, category, subCategories, null, "SUBCATEGORY", isProd));
       
    }

    const SaveCategoryOrder = () => {
        const categoryOrder = AllSubjectProblems.map((category, index) => ({
            category_id: category.category_id,
            rank: index
        }));
        
        dispatch(setSubjectOrder(subject, categoryOrder, null, null, "CATEGORY", isProd));
    }

    
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [problemToRemove, setProblemToRemove] = useState(null);
    const handleRemoveProblem = () => {
        if (!problemToRemove) {
            console.error("No problem to remove");
            return;
        }
        dispatch(removeProblemFromOrder(subject, problemToRemove, setRemoveModalOpen, isProd))
            
    };

		const countNumberofProblem = (category) => {
			let count = 0;
			if(category?.problems) {
				count += category.problems.length
			}
			else{
				category?.subcategories?.forEach((subCategory) => {
					count += subCategory.problems.length;
				});
			}
			return count;
		}

    return (
        <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className='px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto'>
                        <DragDropContext onDragEnd={onDrag}>
                            {AllSubjectProblems && AllSubjectProblems.length > 0 && (
                                <Droppable droppableId='categories' type='categories'>
                                    {(provided) => (
                                        <div
                                            className='flex gap-y-2 flex-col justify-start mt-10'
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >		<h1 className='ml-2 text-xl font-semibold'>Total topics {AllSubjectProblems.length}</h1>
                                            {AllSubjectProblems && AllSubjectProblems.length > 0 ? (
                                                AllSubjectProblems.map((category, index) => (
                                                    <Draggable
                                                        key={category.category_id?.toString()}
                                                        draggableId={category.category_id?.toString()}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                            >
                                                                <div className='flex items-center gap-5'>
                                                                    <div
                                                                        className='mt-4'
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Grip />
                                                                    </div>
                                                                    <ContentAccordion
                                                                        title={category.category_name}
                                                                        numberOfProblems={countNumberofProblem(category)}
                                                                    >
                                                                        <Droppable
                                                                            droppableId={category.category_id?.toString()}
                                                                            type={category.subcategories
                                                                                    ? 'subcategories'
                                                                                    : 'problems'
                                                                                }
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                >
                                                                                    {category.subcategories
                                                                                        ? 
                                                                                        <>
                                                                                        {category.subcategories.map(
                                                                                              (subCategory,subIndex) => (
                                                                                                  <Draggable
                                                                                                      key={subCategory.id?.toString()}
                                                                                                      draggableId={subCategory.id?.toString()}
                                                                                                      index={subIndex}
                                                                                                  >
                                                                                                      {(provided) => (
                                                                                                          <div
                                                                                                              ref={provided.innerRef}
                                                                                                              {...provided.draggableProps}
                                                                                                              className='p-2'
                                                                                                          >
                                                                                                              <div className='flex items-center gap-3'>
                                                                                                                  <div
                                                                                                                      className='mt-4'
                                                                                                                      {...provided.dragHandleProps}
                                                                                                                  >
                                                                                                                      <Grip />
                                                                                                                  </div>
                                                                                                                  <ContentAccordion
                                                                                                                      title={subCategory.name}
                                                                                                                  >
                                                                                                                      <Droppable
                                                                                                                          droppableId={subCategory.id?.toString()}
                                                                                                                          type='problems'
                                                                                                                      >
                                                                                                                          {(provided) => (
                                                                                                                              <div
                                                                                                                                  {...provided.droppableProps}
                                                                                                                                  ref={provided.innerRef}
                                                                                                                                  className='flex flex-col p-4'
                                                                                                                              >
                                                                                                                                  {subCategory.problems.map(
                                                                                                                                      (problem,problemIndex) => (
                                                                                                                                          <Draggable
                                                                                                                                              key={subCategory.id?.toString() + problem.problem_id?.toString()}
                                                                                                                                              draggableId={subCategory.id?.toString() + problem.problem_id?.toString()}
                                                                                                                                              index={problemIndex}
                                                                                                                                          >
                                                                                                                                              {(
                                                                                                                                                  provided,
                                                                                                                                              ) => (
                                                                                                                                                  <div
                                                                                                                                                      ref={provided.innerRef}
                                                                                                                                                      {...provided.draggableProps}
                                                                                                                                                      className='flex items-center gap-2'
                                                                                                                                                  >
                                                                                                                                                      <div
                                                                                                                                                          {...provided.dragHandleProps}
                                                                                                                                                      >
                                                                                                                                                          <GripHorizontal />
                                                                                                                                                      </div>
                                                                                                                                                      <div className='p-2 bg-gray-100 dark:bg-dark_50 rounded-md'>
                                                                                                                                                          {problem.problem_name}
                                                                                                                                                      </div>
                                                                                                                                                      <Trash2Icon color='red' width={15} className='ml-5' onClick={(e)=>{e.preventDefault();e.stopPropagation();setRemoveModalOpen(true);setProblemToRemove({category:category.category_id, subCategory:subCategory.id, problem:problem.problem_id})}}/>
                                                                                                                                                      </div>
                                                                                                                                              )}
                                                                                                                                          </Draggable>
                                                                                                                                      ),
                                                                                                                                  )}
                                                                                                                                  {provided.placeholder}
                                                                                                                                  <span>
                                                                                                                                      <div className='mt-6'>
                                                                                                                                          <button
                                                                                                                                              type='button'
                                                                                                                                              onClick={(e) => {
                                                                                                                                                  e.stopPropagation();
                                                                                                                                                  setSelectedCategory(category.category_id);
                                                                                                                                                  setSelectedSubCategory(subCategory.id);
                                                                                                                                                  setListModalOpen(true);
                                                                                                                                              }}
                                                                                                                                              className='btn  bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'
                                                                                                                                          >
                                                                                                                                              <Plus
                                                                                                                                                  size={18}
                                                                                                                                                  className='stroke-zinc-600'
                                                                                                                                              />
                                                                                                                                          </button>
                                                                                                                                      </div>
                                                                                                                                  </span>
                                                                                                                                  <div onClick={()=>SaveProblems(category.category_id,subCategory.id)} className='mt-4 p-2 rounded-md w-full text-center bg-zinc-700'>
                                                                                                                                      Save
                                                                                                                                      Problems.
                                                                                                                                      Order 
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          )}
                                                                                                                      </Droppable>
                                                                                                                  </ContentAccordion>
                                                                                                              </div>
                                                                                                            
                                                                                                          </div>
                                                                                                      )}
                                                                                                  </Draggable>
                                                                                              ),
                                                                                          )}
                                                                                           {provided.placeholder}
                                                                                            <div 
                                                                                            onClick={()=>SaveSubCategoryOrder(category.category_id)}
                                                                                            className='mt-4 p-2 rounded-md w-full text-center bg-zinc-700'>

                                                                                                Save SubCategories Order
                                                                                            </div>
                                                                                          </>
                                                                                        :
                                                                                        <>
                                                                                        {category.problems.map(
                                                                                              (problem,problemIndex) => (
                                                                                                
                                                                                                  <Draggable
                                                                                                      key={category.category_id?.toString() + problem.problem_id?.toString()}
                                                                                                      draggableId={category.category_id?.toString() + problem.problem_id?.toString()}
                                                                                                      index={problemIndex}>
                                                                                                      {(provided) => (
                                                                                                          <div
                                                                                                              ref={provided.innerRef}
                                                                                                              {...provided.draggableProps}
                                                                                                              {...provided.dragHandleProps}
                                                                                                              className='flex items-center gap-2'
                                                                                                          >
                                                                                                              <div>
                                                                                                                  <GripHorizontal />
                                                                                                              </div>
                                                                                                              <div className='p-2 bg-gray-100 dark:bg-dark_50 rounded-md'>
                                                                                                                  {problem.problem_name}
                                                                                                              </div>
                                                                                                              <Trash2Icon color='red' width={15} className='ml-5' onClick={(e)=>{e.preventDefault();e.stopPropagation();setRemoveModalOpen(true);setProblemToRemove({category:category.category_id, problem:problem.problem_id})}}/>
                                                                                                          </div>
                                                                                                      )}
                                                                                                      
                                                                                                  </Draggable>
                                                                                              ),
                                                                                            )}

                                                                                          <span>
                                                                                            {provided.placeholder}
                                                                                            <div onClick={()=>SaveProblems(category.category_id, null )} className='mt-4 p-2 rounded-md w-full text-center bg-zinc-700'>
                                                                                                Save Problems-Order
                                                                                            </div>
                                                                                        </span>
                                                                                        <div className='mt-6'>
                                                                                        <button
                                                                                            type='button'
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setSelectedCategory(category.category_id)
                                                                                                setListModalOpen(true);
                                                                                            }}
                                                                                            className='btn  bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'
                                                                                        >
                                                                                            <Plus
                                                                                                size={18}
                                                                                                className='stroke-zinc-600'
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                        </>
                                                                                    }

                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </ContentAccordion>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))
                                            ) : (
                                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                                    No Problems to show
                                                </div>
                                            )}
                                            {provided.placeholder}
                                            <div onClick={SaveCategoryOrder} className='mt-4 p-2 rounded-md w-full text-center bg-zinc-700'>
                                                Save Categories Order
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            )}
                        </DragDropContext>
                    </div>
                </main>
                <CategoryModal
                    title={'Add Problem'}
                    modalOpen={listModalOpen}
                    setModalOpen={setListModalOpen}
                >
                    <div className='flex flex-col gap-5'>
                      
                        <div className='w-full'>
                            <input
                                type='text'
                                className='w-full'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className='h-[60vh] overflow-scroll'>
                            {filteredProblems.length > 0 ? (
                                filteredProblems.map((problem) => (
                                    <div onClick={()=>selectedSubCategory ? handleAddProblemWithSubcat(problem) : handleAddProblem(problem)} className='my-2 p-2 rounded-md hover:bg-zinc-800 cursor-pointer'>
                                        {problem.problem_name}
                                    </div>
                                ))
                            ) : (
                                <div>No problems found</div>
                            )}
                        </div>
                    </div>
                </CategoryModal>
            </div>
            <CategoryModal title={"Remove Problem from list"} modalOpen={removeModalOpen} setModalOpen={setRemoveModalOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={() => handleRemoveProblem()}>Yes, Remove</button>
                </div>
            </CategoryModal>
        </div>
    );
};

export default SyllabusOrder;
