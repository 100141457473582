/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Transition from '../../utils/Transition';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { addWhatsappUser, addWhatsappUserDetails } from '../../services/operations/calls';
import { setUserAdded } from '../../slices/callsSlice';
import ReactQuill from 'react-quill';
import { htmlToMarkdown, markdownToHtml } from '../RichEditor/Parser';

const PulseLoader = () => {
  return (
    <div style={styles.loaderContainer}>
      <span style={{ ...styles.dot, animationDelay: '0s' }}></span>
      <span style={{ ...styles.dot, animationDelay: '0.15s' }}></span>
      <span style={{ ...styles.dot, animationDelay: '0.3s' }}></span>
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  dot: {
    width: '8px',
    height: '8px',
    backgroundColor: 'white',
    borderRadius: '50%',
    animation: 'pulse 1.2s infinite ease-in-out',
  },
};

// Add keyframes inside a <style> tag to inject CSS dynamically
const GlobalStyles = () => {
  return (
    <style>
      {`
        @keyframes pulse {
          0% { opacity: 1; transform: scale(1); }
          50% { opacity: 0.5; transform: scale(1.2); }
          100% { opacity: 1; transform: scale(1); }
        }
      `}
    </style>
  );
};

function AddWhatsappUserModal({ modalOpen, setModalOpen }) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [callStatus, setCallStatus] = useState('not interested');
  const [codeUsed, setCodeUsed] = useState('NONE');
  const [isEditingCodeUsed, setIsEditingCodeUsed] = useState(false);
  const modalContent = useRef(null);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(markdownToHtml());
  const [tempNotes, setTempNotes] = useState(notes); // Temporary state for editing
  const reactQuillRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      await dispatch(addWhatsappUser(data.email, data.name, data.phone, data.status, data.code));
      await dispatch(addWhatsappUserDetails(data.notes, data.email));

      dispatch(setUserAdded(true));
      setModalOpen(false);
      reset();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCodeUsedChange = (value) => {
    setCodeUsed(value);
    setValue('code', value);
  };

  const handleSaveNotes = () => {
    let editorContent;
    if (reactQuillRef.current) {
      editorContent = reactQuillRef.current.getEditor().root.innerHTML.trim();
      setNotes(editorContent || '');
    }
    setIsEditing(false);
  };

  const handleEdit = () => {
    setTempNotes(notes);
    setIsEditing(true);
  };

  return (
    <>
      <Transition
        className='fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity'
        show={modalOpen}
        enter='transition ease-out duration-200'
        enterStart='opacity-0'
        enterEnd='opacity-100'
        leave='transition ease-out duration-100'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        aria-hidden='true'
      />
      <Transition
        className='fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6'
        show={modalOpen}
        enter='transition ease-in-out duration-200'
        enterStart='opacity-0 translate-y-4'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-in-out duration-200'
        leaveStart='opacity-100 translate-y-0'
        leaveEnd='opacity-0 translate-y-4'
      >
        <div
          ref={modalContent}
          className='bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar w-full max-w-[90%] sm:max-w-[75%] md:max-w-[60%] max-h-[80%] flex flex-col p-3 sm:p-6'
        >
          <h2 className='text-xl text-center font-semibold mb-4'>Add User</h2>
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
            {/* Two-column layout */}
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 w-full'>
              {/* Left Column */}
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col'>
                  <label className='font-semibold mb-1 text-sm sm:text-base'>Email</label>
                  <input
                    type='email'
                    className='w-full h-10 px-3 py-2 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none bg-white dark:border-zinc-700'
                    {...register('email', { required: 'Email is required' })}
                  />
                  {errors.email && <p className='text-red-500 text-sm'>{errors.email.message}</p>}
                </div>

                <div className='flex flex-col'>
                  <label className='font-semibold mb-1 text-sm sm:text-base'>Name</label>
                  <input
                    type='text'
                    className='w-full h-10 px-3 py-2 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none bg-white dark:border-zinc-700'
                    {...register('name', { required: 'Name is required' })}
                  />
                  {errors.name && <p className='text-red-500 text-sm'>{errors.name.message}</p>}
                </div>

                <div className='flex flex-col'>
                  <label className='font-semibold mb-1 text-sm sm:text-base'>Phone</label>
                  <input
                    type='tel'
                    className='w-full h-10 px-3 py-2 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none bg-white dark:border-zinc-700'
                    {...register('phone', {
                      validate: (value) => {
                        if (!value) return true;
                        return (
                          /^\+?[1-9]\d{1,14}$/.test(value) ||
                          'Enter a valid phone number (with optional country code)'
                        );
                      },
                    })}
                    onBlur={(e) => {
                      const sanitizedValue = e.target.value.replace(/\s+/g, '');
                      setValue('phone', sanitizedValue, { shouldValidate: true });
                    }}
                  />
                  {errors.phone && <p className='text-red-500 text-sm'>{errors.phone.message}</p>}
                </div>

                <div className='flex flex-col'>
                  <label className='font-semibold mb-1 text-sm sm:text-base'>Buying Status:</label>
                  <select
                    className='w-full h-10 px-3 py-2 text-sm bg-gray-50 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 focus:outline-none focus:ring focus:ring-red-500'
                    {...register('status', {
                      onChange: (e) => {
                        setCallStatus(e.target.value);
                      },
                    })}
                    value={callStatus}
                  >
                    <option value='not interested'>Not Interested</option>
                    <option value='will buy later'>Will Buy Later</option>
                    <option value='bought'>Bought</option>
                  </select>
                </div>

                <div className='flex flex-col'>
                  <label className='font-semibold mb-1 text-sm sm:text-base'>Code Used:</label>
                  {isEditingCodeUsed ? (
                    <input
                      type='text'
                      value={codeUsed}
                      onChange={(e) => setCodeUsed(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleCodeUsedChange(codeUsed.toUpperCase());
                          setCodeUsed((prev) => prev.toUpperCase());
                          setIsEditingCodeUsed(false);
                        }
                      }}
                      onBlur={() => {
                        handleCodeUsedChange(codeUsed.toUpperCase());
                        setCodeUsed((prev) => prev.toUpperCase());
                        setIsEditingCodeUsed(false);
                      }}
                      className='w-full px-3 py-2 border rounded-md focus:ring focus:ring-red-500 focus:outline-none text-sm'
                      autoFocus
                    />
                  ) : (
                    <span
                      className='w-full px-3 py-2 border rounded-md cursor-pointer focus:ring focus:ring-red-500 focus:outline-none text-sm'
                      onClick={() => setIsEditingCodeUsed(true)}
                    >
                      {codeUsed || 'None'}
                    </span>
                  )}
                  <input type='hidden' {...register('code')} value={codeUsed} />
                </div>
              </div>

              {/* Right Column (Notes Field) */}
              <div className='flex flex-col'>
                <div className='flex items-center justify-between mb-2'>
                  <label className='font-semibold text-sm sm:text-base'>Notes:</label>
                  <button
                    type='button'
                    className={`px-3 py-1 rounded text-sm font-medium ${
                      isEditing
                        ? 'text-green-500 hover:text-green-600'
                        : 'text-red-500 hover:text-red-600'
                    }`}
                    onClick={isEditing ? handleSaveNotes : handleEdit}
                  >
                    {isEditing ? 'Save' : 'Edit'}
                  </button>
                </div>
                {isEditing ? (
                  <ReactQuill
                    ref={reactQuillRef}
                    theme='snow'
                    placeholder='Start writing...'
                    value={tempNotes || ''}
                    className='rich-editor-height rounded h-40 sm:h-56'
                    onChange={(value) => {
                      setTempNotes(value);
                      setValue('notes', value); // Update the form state
                    }}
                  />
                ) : (
                  <div
                    className='p-3 bg-gray-50 dark:bg-zinc-800 rounded shadow-md border border-gray-200 dark:border-zinc-700 text-sm'
                    dangerouslySetInnerHTML={{
                      __html: notes || 'No notes available.',
                    }}
                  />
                )}
              </div>
            </div>

            {/* Hidden input for notes (to include it in the form submission) */}
            <input type='hidden' {...register('notes')} value={notes || ''} />

            {/* Buttons outside the columns */}
            <div className='flex flex-col sm:flex-row justify-end gap-2 sm:gap-4 mt-6'>
              <button
                type='button'
                className='px-3 py-2 border text-sm border-black dark:border-white bg-zinc-50 dark:bg-zinc-800 rounded-md hover:text-red-600 hover:border-red-600 dark:hover:text-red-500 dark:hover:border-red-500 text-black dark:text-white'
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='px-3 py-2 text-sm bg-red-500 text-white rounded flex items-center justify-center min-w-[100px] disabled:opacity-50 disabled:cursor-not-allowed hover:bg-red-800'
                disabled={isSubmitting}
              >
                {isSubmitting ? <PulseLoader /> : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </Transition>
    </>
  );
}

export default AddWhatsappUserModal;
