import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    AllInterviewExperiences: [],
    InterviewExperience: null,
};

const interviewExperiencesSlice = createSlice({
    name: "interviewExperiences",
    initialState,
    reducers: {
        setAllInterviewExperiences: (state, action) => {
            state.AllInterviewExperiences = action.payload;
        },
        setInterviewExperience: (state, action) => {
            state.InterviewExperience = action.payload;
        },
    },
});

export const { setAllInterviewExperiences, setInterviewExperience } = interviewExperiencesSlice.actions;

export default interviewExperiencesSlice.reducer;