/* eslint-disable no-unused-vars */

import React from "react";

export const statusLabels = {
  'not called': 'Not Called',
  null: 'Not Called',
  'wrong num': 'Wrong Number',
  unreachable: 'Unreachable',
  unanswered: 'Unanswered',
  'feedback happy': 'Feedback Given - Happy',
  'feedback sad': 'Feedback Given - Sad',
};

const statusColors = {
  'not called': 'bg-red-500 dark:bg-red-600',
  null: 'bg-red-500 dark:bg-red-600',
  'feedback happy': '',
  'feedback sad': '',
  'wrong num': 'bg-yellow-500 dark:bg-yellow-400',
  unreachable: 'bg-yellow-500 dark:bg-yellow-400',
  unanswered: 'bg-yellow-500 dark:bg-yellow-400',
};

const getStatusLabel = (status) => statusLabels[status] || 'Unknown';
const getStatusColor = (status) => statusColors[status] || 'bg-yellow-500 dark:bg-yellow-400';

const StatusIndicator = ({ status }) => {
  const isHappy = status === 'feedback happy';
  const isSad = status === 'feedback sad';

  const statusText = getStatusLabel(status);

  return (
    <div className='relative flex items-center justify-center group'>
      {/* Hover Text */}
      <div className='absolute -top-7 px-2 py-1 text-xs bg-gray-800 dark:bg-gray-200 text-white dark:text-black rounded opacity-0 group-hover:opacity-100 transition-opacity'>
        {statusText}
      </div>

      {/* Status Circle */}
      <div
        className={`w-7 h-7  flex items-center justify-center cursor-pointer transition-transform group-hover:scale-110 ${
          isHappy || isSad ? '' : `rounded-full ${getStatusColor(status)}`
        }`}
      >
        {isHappy ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            viewBox='0 0 24 24'
            fill='none'
            stroke='green'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='lucide lucide-smile dark:stroke-green-300'
          >
            <circle cx='12' cy='12' r='10' />
            <path d='M8 14s1.5 2 4 2 4-2 4-2' />
            <line x1='9' x2='9.01' y1='9' y2='9' />
            <line x1='15' x2='15.01' y1='9' y2='9' />
          </svg>
        ) : isSad ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            viewBox='0 0 24 24'
            fill='none'
            stroke='blue'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='lucide lucide-frown dark:stroke-blue-300'
          >
            <circle cx='12' cy='12' r='10' />
            <path d='M16 16s-1.5-2-4-2-4 2-4 2' />
            <line x1='9' x2='9.01' y1='9' y2='9' />
            <line x1='15' x2='15.01' y1='9' y2='9' />
          </svg>
        ) : null}
      </div>
    </div>
  );
};

export default StatusIndicator; 