/* eslint-disable no-unused-vars */

import React from 'react';
import FeedbackTableItem from './FeedbackTableItem';
import { current } from '@reduxjs/toolkit';

const FeedbackTable = ({ feedbacks, callType, offset }) => {
  const callTypeMap = {
    both: 'Calls Status',
    'post 24hrs': 'Post 24Hrs',
    'post 2w': 'Post 2 Weeks',
  };

  return (
    <div className='font-primary  relative'>
      <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
        <div className='overflow-x-auto'>
          <table className='table-auto w-full  divide-y  divide-gray-200'>
            <thead className='text-xs uppercase  text-[#8C8C8C] '>
              <tr>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>No.</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold items-center'>Email</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Phone Number</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Location</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Purchase Date</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Plan Name</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Active Days</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Last Seen</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>{`${callTypeMap[callType]}`}</div>
                </th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                  <div className='font-semibold text-center'>Notes</div>
                </th>
              </tr>
            </thead>
            {feedbacks?.map((data, index) => {
              return (
                <FeedbackTableItem
                  num={index + 1 + offset}
                  email={data?.email}
                  phoneNumber={data?.phone_number}
                  purchaseDate={data?.created_at}
                  planName={data?.plan_name}
                  activeDays={data?.active_days_count}
                  lastSeen={data?.last_seen}
                  location={data?.location}
                  postPurchaseCall={data?.call_status_1}
                  postPurchaseCall2w={data?.call_status_2}
                  callType={callType}
                />
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default FeedbackTable;
