import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import ConfigsModal from '../../components/Shared/Modals/ConfigsModal';
import ConfigsTable from '../../components/Table/ConfigsTable/ConfigsTable';
import { getAllConfigs, addConfig, updateConfig } from '../../services/operations/config';
import { setAllConfigs, setConfig } from '../../slices/configSlice';

const environmentTabs = [
    { label: 'DEV', value: 'dev' },
    { label: 'PROD', value: 'prod' }
  ];
  
  const configTypeTabs = [
    { label: 'Backend', value: 'backend' },
    { label: 'Frontend', value: 'frontend' }
  ];

const AddConfig = () => {
    
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentTab, setCurrentTab] = useState("dev");
    const [currentConfigType, setCurrentConfigType] = useState("backend");

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    const { allConfigs, config } = useSelector((state) => state.configs)

    useEffect(() => {
        const isProd = currentTab === "prod";
        dispatch(setAllConfigs([]))
        dispatch(getAllConfigs(isProd))
    }, [dispatch, currentTab])

    useEffect(() => {
        dispatch(setConfig({}))
    }, [dispatch]);

    useEffect(() => {
        reset(config)
    }, [reset, config]);

    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            key: '',
            value: '',
            percentage: 0,
            backend: currentConfigType === "backend"
        }
        
        dispatch(setConfig(data))
        setIsEdit(false)
        setModalOpen(true);
    }

    const handleOnSubmit = async (data) => {
        const isProd = currentTab === "prod";
        if (isEdit) {
            const { key, value, percentage, backend } = data;
            dispatch(updateConfig(config?.id, key, value, percentage, backend, setModalOpen, isProd));
        }
        else {
            const { key, value, percentage,backend } = data;
            dispatch(addConfig(key, value, percentage, backend, setModalOpen, isProd));
        }
    };


    let filteredConfigs = allConfigs.filter((config) => {
        if (currentConfigType === "backend") {
            return config?.backend
        } else {
            return !config?.backend
        }
    }) 


    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>

                    <div className='flex justify-center my-6 bg-zinc-900 rounded-full text-sm w-5/12 mx-auto'>
                        <TabSwitcher
                            tabs={environmentTabs}
                            activeTab={currentTab}
                            onChange={setCurrentTab}
                        />
                    </div>

                    <p className='text-center text-8xl font-bold mt-4 text-zinc-700'>{currentTab.toUpperCase()}</p>

                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add Config</button>
                        </div>
                        <div className='flex justify-center my-6 bg-zinc-900 rounded-full text-sm w-5/12 mx-auto'>
                            <TabSwitcher
                                tabs={configTypeTabs}
                                activeTab={currentConfigType}
                                onChange={setCurrentConfigType}
                            />
                                
                    </div>
                        {filteredConfigs && filteredConfigs.length > 0 ? (
                            <>
                                <ConfigsTable 
                                    configs={filteredConfigs}
                                    setIsEdit={setIsEdit}
                                    setModalOpen={setModalOpen}
                                    modalOpen={modalOpen} 
                                    isProd={currentTab==="prod"}
                                />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Config Keys Stored in database
                            </div>
                        )}
                        <ConfigsModal title={isEdit ? 'Edit config' : 'Create config'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Key</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="key"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="key"
                                                    type="text"
                                                    name="key"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Key"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'key is required',
                                            }}
                                        />
                                        {isSubmitted && errors.key && <p className="text-red-600 text-sm italic">{errors.key.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>value</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="value"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="value"
                                                    type="text"
                                                    name="value"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Value"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'value is required',
                                            }}
                                        />
                                        {isSubmitted && errors.value && <p className="text-red-600 text-sm italic">{errors.value.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Percentage</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="percentage"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="percentage"
                                                    type="number"
                                                    name="percentage"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Percentage"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'percentage is required',
                                                validate: value => (value >= 0 && value <= 100) || 'percentage must be between 0 and 100'
                                            }}
                                        />
                                        {isSubmitted && errors.percentage && <p className="text-red-600 text-sm italic">{errors.percentage.message}</p>}
                                    </div>
                                </div>

                                <div className="flex   -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300 whitespace-nowrap'>For Backend</span>
                                    <div className="w-full px-3">
                                    <Controller
                                        name="backend"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <input
                                                id="backend"
                                                type="checkbox"
                                                className="form-checkbox h-5 w-5 text-blue-600"
                                                checked={Boolean(value)}
                                                onChange={(e) => onChange(e.target.checked)}
                                                ref={ref}
                                            />
                                        )}
                                    />
                                        {isSubmitted && errors.backend && <p className="text-red-600 text-sm italic">{errors.backend?.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Add Config Key</button>
                                    </div>  
                                </div>
                            </form>
                        </ConfigsModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddConfig



const TabSwitcher = ({ tabs, activeTab, onChange, size = "md" }) => {
  const sizeClasses = {
    sm: "text-sm py-1.5",
    md: "text-base py-2",
    lg: "text-lg py-2.5"
  };

  return (
    <div className="flex justify-center bg-zinc-900 rounded-full mx-auto w-full">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          onClick={() => onChange(tab.value)}
          className={`
            relative w-full px-4 rounded-full  transition-colors duration-200
            ${sizeClasses[size]}
            ${activeTab === tab.value ? 'text-white' : 'text-zinc-500 hover:text-zinc-400'}
          `}
        >
          {activeTab === tab.value && (
            <div className="absolute inset-0 w-full border-2 border-zinc-700 rounded-full" />
          )}
          <span className="relative z-10 w-full">{tab.label}</span>
        </button>
      ))}
    </div>
  );
};