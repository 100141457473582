import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setCategory, setProblem } from '../../../slices/syllabusSlice'
import { deleteCategory, deleteDeletedProblem, deleteProblem } from '../../../services/operations/syllabus'
import CategoryModal from '../../Shared/Modals/CategoryModal'
import ApprovalToggle from '../../../pages/ManageInterviewExperiences/ApprovedToggle'

const CategoryTableItem = (props) => {

    const { setModalOpen, setIsEdit, setParts } = props
    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)

    const { isProd } = useSelector((state) => state.auth)

    const handleCategoryEdit = (e) => {
        e.stopPropagation()
        const data = {
            id: props.id,
            name: props.name,
            rank: props.rank,
            percentage: props?.percentage,
            slug: props?.slug,

        }
        dispatch(setCategory(data))
        setIsEdit(true)
        setModalOpen(true);
    }

    const handleCat = (e) => {
        // dispatch(deleteCategory(props.id))
        e.stopPropagation();
        setModalDeleteOpen(true)
    }
    const handleProblem = (e) => {
        e.stopPropagation();
        setModalDeleteOpen(true)
    }
    const handleProblemDelete = (e) => {
        dispatch(deleteProblem(encodeURIComponent(props?.problem_id), setModalDeleteOpen, isProd))
    }

    const handleDeletedProblemDelete=(e)=>{
        dispatch(deleteDeletedProblem(encodeURIComponent(props.problem_id),setModalDeleteOpen, isProd))
    }

    const handleCatDelete = (e) => {
        dispatch(deleteCategory(encodeURIComponent(props.id), setModalDeleteOpen, isProd))

    }

    const handleProblemEdit = (e) => {
        e.stopPropagation()
        const data = {
            category: props?.cat_id,
            problem_id: props?.problem_id,
            subCategory: props?.subcatid,
            name: props?.problem_name,
            rank: props?.rank,
            hasIDE: props?.hasIDE,
            note: props?.note,
            points: props?.points,
            parts: props?.parts,
            slug: props?.slug,

        }
        setIsEdit(true)
        dispatch(setProblem(data))
        setModalOpen(true);
    }
    const handleVideoEdit = (e) => {
        e.stopPropagation()
        const data = {
            parts: props?.parts,
            problem_id: props?.problem_id ? props?.problem_id : props.id
        }
        setParts(data)
        setModalOpen(true);
    }
    const handleView = (e) => {
        window.open(`https://dev.takeuforward.org/plus/dsa/problems/${props.slug}`, '_blank');
    };

    const handleDeletedProblemAdd = (e) => {
        e.stopPropagation()
        const data = {
            category: props?.cat_id,
            problem_id: props?.problem_id,
            subCategory: props?.subcatid,
        }
        dispatch(setProblem(data))
        setModalOpen(true);
    }



    const handleDeletedProblem = (e) => {
        e.stopPropagation();
        setModalDeleteOpen(true)
    }

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.num}.
                    </div>
                </td>

                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                        {props.page === 'interview' ? (props.title) : (props?.name ? props?.name : props?.problem_name)}
                    </div>
                </td>
                {props.page === 'Category' ? (
                    <>

                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                {props?.rank}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                {props?.percentage}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                <button onClick={(e) => handleCat(e)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                                <button onClick={(e) => handleCategoryEdit(e)} className='btn bg-green-500 text-white  border-gree-500'>Edit</button>
                            </div>
                        </td>
                    </>
                ) : (
                    <>


                        {props.page === 'PB' ? (
                            <>
                                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                        {props?.rank}
                                    </div>
                                </td>
                                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">

                                        <button onClick={handleProblemEdit} className='btn bg-green-500 text-white  border-gree-500'>Edit</button>
                                        <button onClick={(e) => handleProblem(e)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                                    </div>
                                </td>
                            </>
                        ) : (
                            <>
                                {props.page === 'Deleted_PB' ? (
                                    <>
                                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                {props?.rank}
                                            </div>
                                        </td>
                                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                            <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                                <button onClick={handleDeletedProblemAdd} className='btn bg-green-600 text-white  border-gree-500'>Add</button>
                                                <button onClick={(e) => handleDeletedProblem(e)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>
                                            </div>
                                        </td>
                                    </>
                                ) : props.page === 'DSA' || props.page === "Subject" || props.page === 'Quiz' || props.page === 'Theory' || props.page === 'New_Category' || props.page === 'New_Subcategory' || props.page === 'MyCourses' || props.page === 'Aptitude' || props.page === 'interview' || props.page === 'companies' ? (
                                    <>

                                        <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
                                            <div className='flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4'>
                                                {(props.page !== "Subject" && props.page !== "New_Category" && props.page !== "New_Subcategory" && props.page !== "MyCourses") && props.page !=='interview' && props.page !== 'companies' ?
                                                    <>
                                                        <button onClick={(e) => handleDeletedProblem(e)} className='btn bg-red-500 border-red-500 text-white  transation-all duration-300 ease-in-out'>Delete</button>

                                                    <Link
                                                        to={`/manage-content/all-${props?.page.toLowerCase()}-problem/${props.slug}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='btn bg-green-600 text-white  border-gree-500'
                                                    >
                                                        EDIT
                                                    </Link>
                                                    <button onClick={handleView} className='btn bg-green-500 text-white  border-gree-500'>View</button>
                                                    </>
                                                    :
                                                    <div className="flex items-center space-x-4">
                                                   

                                                    {props.page === 'companies' ? (<button
                                                        onClick={(e) => props.handleDelete(e, props)}
                                                        className="btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out"
                                                    >
                                                        Delete
                                                    </button>) : (<button
                                                        onClick={(e) => handleDeletedProblem(e)}
                                                        className="btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out"
                                                    >
                                                        Delete
                                                    </button>)}

                                                  
                                                    <button
                                                      onClick={(e) => props.handleEdit(e, props)}
                                                      className="btn bg-green-600 text-white border-green-500"
                                                    >
                                                      EDIT
                                                    </button>
                                                  
                                                    {/* Toggle Section with Fixed Width */}
                                                    {props.page === 'interview' && (<div className="flex items-center min-w-[120px]">
                                                      <ApprovalToggle id={props.id} page={props.page} initialApproved={props.isApproved} />
                                                    </div>)}
                                                  </div>
                                                  }
                                            </div>
                                        </td>
                                    </>
                                ) : (
                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">

                                            {props.page === 'Editorials' && (
                                                <Link to={`/syllabus-content/editorials/${props.id ? encodeURIComponent(props.id) : encodeURIComponent(props.problem_id)}`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                            )}
                                            {props.page === 'PS' && (
                                                <Link to={`/syllabus-content/problem-setter/${props.id ? encodeURIComponent(props.id) : encodeURIComponent(props.problem_id)}`} className='btn bg-green-500 text-white  border-gree-500'>Edit</Link>
                                            )}
                                            {props.page === 'Video' && (
                                                <>
                                                    <button onClick={handleVideoEdit} className='btn bg-green-500 text-white  border-gree-500'>Edit</button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                )}
                            </>
                        )}
                    </>
                )}
            </tr>
            <CategoryModal title={"Delete"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>


                    <button
                        className='btn-brand-2 mt-4'
                        onClick={() => {
                            if (props.page === 'Category') {
                                handleCatDelete();
                            } else if (props.page === 'PB') {
                                handleProblemDelete();
                            } else if (props.page === 'Deleted_PB') {
                                handleDeletedProblemDelete();
                            } else {
                                props.handleDelete(props.slug, setModalDeleteOpen)
                            }

                        }}>
                        Yes, Delete
                    </button>

                </div>
            </CategoryModal>
        </tbody>
    )
}

export default CategoryTableItem
