/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import { getAllPlans } from '../../services/operations/premium';
import { changeEmail, checkIfPremium, downloadInvoice, getAllPrivilegedUsers, getAllUsers, setUser } from '../../services/operations/auth';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addUser, grantUserAccess, removePremiumAccess, updateUser } from '../../services/operations/grantAccess';
import UsersModal from '../../components/Shared/Modals/UsersModal';
import UsersTable from '../AddUser/UsersTable';
import axios from 'axios';
import toast from 'react-hot-toast';

const V2GrantPremiumAccess = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation()
    const { pathname } = location
    const [modalOpen, setModalOpen] = useState(false);
    const [grantAccessModal, setGrantAccessModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit: handleRemoveSubmit, control: removeControl, formState: { errors: removeErrors, isSubmitted: isRemoveSubmitted }, reset: resetRemove } = useForm({
        defaultValues: {
            removeEmail: '',
        }
    });

    const { handleSubmit: handleUserSubmit, control: userControl, formState: { errors: userErrors, isSubmitted: isUserSubmitted }, reset: resetUser } = useForm({
        defaultValues: {
            email: '',
            name: '',
            username: '',
            isPremium: '1',
            plan_name: '',
            validity: '',
            medium: 'privileged',
        }
    });
    const { handleSubmit: handleGrantSubmit, control: grantControl, formState: { errors: grantErrors, isSubmitted: isGrantUserAccessSubmitted }, reset: resetGrantUser } = useForm();

    const { user } = useSelector((state) => state.grantAccess)
    const { allPrivilegedUsers, isProd } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(getAllPrivilegedUsers())
    }, [dispatch])


    useEffect(() => {
        resetUser(user)
    }, [resetUser, user]);

    const isEmailValid = (value) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(value) || 'Invalid email address';
    };

    const handleModalOpen = (e) => {
        e.stopPropagation();
        resetUser()
        const data = {
            email: '',
            name: '',
            username: '',
            isPremium: '1',
            plan_name: '',
            validity: '',
            medium: 'privileged',
        }

        dispatch(setUser(data))
        setIsEdit(false)
        setModalOpen(true);
    }
    const handleGrantAccessModalOpen = (e) => {
        e.stopPropagation();
        resetGrantUser()
        setGrantAccessModal(true);
    }

    const handleOnSubmit = async (data) => {
        const { email, name, username, isPremium, plan_name, medium } = data;
        const validity = plan_name === 'Pinnacle' ? 'Lifetime' : '12 months'
        dispatch(addUser(email, name, username, isPremium, plan_name, validity, medium, setModalOpen, isProd));
    };
    const handleOnSubmit2 = async (data) => {
        const { email, name, username, plan_name, amountPaid, location, phoneNumber, currency } = data;
        const validity = plan_name === 'Pinnacle' ? 'Lifetime' : '12 months'
        dispatch(grantUserAccess(email, name, username, 1, plan_name, validity, 'paid', parseFloat(amountPaid), location, phoneNumber, currency, setGrantAccessModal));
    };

    const handleRemovePremiumAccessModal = (e) => {
        e.stopPropagation()
        resetRemove()
        setModalDeleteOpen(true);
    }
    const handleRemovePremiumAccess = async (data) => {
        const { removeEmail } = data;
        dispatch(removePremiumAccess(removeEmail, setModalDeleteOpen));
    }

    const states = [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar",
        "Chhattisgarh", "Gujarat", "Haryana", "Himachal Pradesh",
        "Jammu and Kashmir", "Goa", "Jharkhand", "Karnataka",
        "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
        "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
        "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
        "Uttarakhand", "Uttar Pradesh", "West Bengal", "Andaman and Nicobar Islands",
        "Chandigarh", "Dadra and Nagar Haveli", "Daman and Diu",
        "Delhi", "Lakshadweep", "Puducherry"
    ];

    const [userEmail, setUserEmail] = useState('');
    const [userEmail2, setUserEmail2] = useState('');
    const [userName, setUserName] = useState('');
    const [oldUsername, setOldUserName] = useState('');
    const [oldUserEmail, setOldUserEmail] = useState('');
    const [newUsername, setNewUserName] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [status, setStatus] = useState(null);
    const [link, setLink] = useState(null);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        dispatch(checkIfPremium(userEmail, setStatus))
    };
    const handleDownloadInvoiceSubmit = async (e) => {
        e.preventDefault();
        if(userEmail2 && userName) {
            dispatch(downloadInvoice(userEmail2, userName, setLink))
            setUserEmail2('')
            setUserName('')
        } else {
            toast.error("Email or userName is empty")
        }
    };
    const handleEmailChange = async (e) => {
        e.preventDefault();
        if(oldUsername && oldUserEmail && newUsername && newUserEmail) {
            dispatch(changeEmail(oldUsername, oldUserEmail, newUsername, newUserEmail))
            setOldUserEmail('')
            setOldUserName('')
            setNewUserName('')
            setNewUserEmail('')
        } else {
            toast.error("Email or userName is empty")
        }
    };
    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar type={pathname && pathname?.split("/")[1]} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='mt-4 mb-10'>
                            <form className='flex items-center gap-x-4' onSubmit={handleEmailSubmit}>
                                <input
                                    type="email"
                                    className='form-input'
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    required
                                />
                                <button type="submit" className='btn'>Submit</button>
                            </form>
                            <p className='mt-4'>Status: {status === true ? 'True' : status === false && 'False'}</p>
                        </div>
                        <div className='mt-4 mb-10'>
                            <form className='flex items-center gap-x-4' onSubmit={handleDownloadInvoiceSubmit}>
                                <input
                                    type="email"
                                    className='form-input'
                                    placeholder='Email'
                                    value={userEmail2}
                                    onChange={(e) => setUserEmail2(e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    className='form-input'
                                    placeholder='username'
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    required
                                />
                                <button type="submit" className='btn'>Download Invoice</button>
                            </form>
                            {link && (
                            <div className='mt-8 mx-auto flex justify-center items-center'>
                                <div className='flex justify-between w-1/2 p-2 rounded-lg border border-zinc-400 dark:border-zinc-800 dark:bg-dark_50 bg-[#f5f5f5]'>
                                    <span className='w-full max-w-11/12'>{link}</span>
                                    <button onClick={() => { navigator.clipboard.writeText(link); toast.success("File Link Copied Successfully"); }}>
                                        Copy Link
                                    </button>
                                </div>
                            </div>
                        )}
                        </div>
                        <div className='mt-4 mb-10'>
                        <form className="flex items-center gap-x-4" onSubmit={handleEmailChange}>
    <input
        type="email"
        className="form-input"
        placeholder="Old Email"
        value={oldUserEmail}
        onChange={(e) => setOldUserEmail(e.target.value)}
        required
    />
    <input
        type="email"
        className="form-input"
        placeholder="New Email"
        value={newUserEmail}
        onChange={(e) => setNewUserEmail(e.target.value)}
        required
    />
    <input
        type="text"
        className="form-input"
        placeholder="Old Username"
        value={oldUsername}
        onChange={(e) => setOldUserName(e.target.value)}
        required
    />
    <input
        type="text"
        className="form-input"
        placeholder="New Username"
        value={newUsername}
        onChange={(e) => setNewUserName(e.target.value)}
        required
    />
    <button type="submit" className="btn">Submit</button>
</form>
                        </div>
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add Privileged User</button>
                            <button onClick={(e) => handleGrantAccessModalOpen(e)} className='btn-brand-2'>Grant Access to user</button>
                            <button onClick={(e) => handleRemovePremiumAccessModal(e)} className='btn-brand-2'>Remove Premium Access</button>
                        </div>
                        {allPrivilegedUsers && allPrivilegedUsers.length > 0 ? (
                            <>
                                <div className="  font-primary  relative w-full">
                                    <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                                        <div className="overflow-x-auto">
                                            <table className="table-auto w-full  divide-y  divide-gray-200">
                                                <thead className="text-xs uppercase  text-[#8C8C8C] ">
                                                    <tr>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                                            <div className="font-semibold flex items-start">Name</div>
                                                        </th>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                            <div className="font-semibold text-center">Email</div>
                                                        </th>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                            <div className="font-semibold text-center">Plan Name</div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {
                                                    allPrivilegedUsers?.map((data, index) => {
                                                        return (
                                                            <tbody className="text-[13px]">
                                                                <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                            {data?.name}
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                            {data?.email}
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                            {data?.plan_name}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody >
                                                        )
                                                    })
                                                }
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No privileged users
                            </div>
                        )}
                        <UsersModal title={"Delete User"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                            <form onSubmit={handleRemoveSubmit(handleRemovePremiumAccess)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Email</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="removeEmail"
                                            control={removeControl}
                                            render={({ field }) => (
                                                <input
                                                    id="removeEmail"
                                                    type="email"
                                                    name="removeEmail"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Email of user to remove premium access"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'email is required',
                                                validate: {
                                                    isEmailValid,
                                                },
                                            }}
                                        />
                                        {isRemoveSubmitted && removeErrors.removeEmail && <p className="text-red-600 text-sm italic">{removeErrors.removeEmail.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 mt-4">Delete User</button>
                                    </div>
                                </div>
                            </form>
                        </UsersModal>
                        <UsersModal title={'Add Privileged User'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleUserSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="name"
                                            control={userControl}
                                            render={({ field }) => (
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="User's Name"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'name is required',
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.name && <p className="text-red-600 text-sm italic">{userErrors.name.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Username</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="username"
                                            control={userControl}
                                            render={({ field }) => (
                                                <input
                                                    id="username"
                                                    type="text"
                                                    name="username"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Username on TUF"
                                                    {...field}
                                                    disabled={isEdit}
                                                />
                                            )}
                                            rules={{
                                                required: !isEdit ? 'username is required' : false,
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.username && <p className="text-red-600 text-sm italic">{userErrors.username.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Email</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="email"
                                            control={userControl}
                                            render={({ field }) => (
                                                <input
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Email"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'email is required',
                                                validate: {
                                                    isEmailValid,
                                                },
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.email && <p className="text-red-600 text-sm italic">{userErrors.email.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Status</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="isPremium"
                                            control={userControl}
                                            defaultValue="1"
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-select w-full text-gray-300"
                                                >
                                                    <option value="1">Premium</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'Status is required',
                                            }}
                                        />
                                        {userErrors.isPremium && <p className="text-red-600 text-sm italic">{userErrors.isPremium.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Medium</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="medium"
                                            control={userControl}
                                            defaultValue="privileged"
                                            render={({ field }) => (
                                                <select
                                                    id="medium"
                                                    name="medium"
                                                    className="form-select w-full text-gray-300 "
                                                    {...field}
                                                >
                                                    <option value="privileged">Privileged</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'medium is required',
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.medium && <p className="text-red-600 text-sm italic">{userErrors.medium.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Plan Name</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="plan_name"
                                            control={userControl}
                                            render={({ field }) => (
                                                <select
                                                    id="plan_name"
                                                    name="plan_name"
                                                    className="form-select w-full text-gray-300 "
                                                    {...field}
                                                >
                                                    <option value="">Select Plan</option>
                                                    <option key={'Pinnacle'} value={'Pinnacle'}>Pinnacle</option>
                                                    <option key={'Sprint'} value={'Sprint'}>Sprint</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'plan name is required',
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.plan_name && <p className="text-red-600 text-sm italic">{userErrors.plan_name.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">{isEdit ? 'Update User' : 'Add User'}</button>
                                    </div>
                                </div>
                            </form>
                        </UsersModal>
                        <UsersModal title={'Grant Access to user'} setModalOpen={setGrantAccessModal} modalOpen={grantAccessModal}>
                            <form onSubmit={handleGrantSubmit(handleOnSubmit2)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Amount Paid</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="amountPaid"
                                            control={grantControl}
                                            render={({ field }) => (
                                                <input
                                                    id="amountPaid"
                                                    type="number"
                                                    step={0.01}
                                                    name="amountPaid"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Amount Paid by user"
                                                    {...field}
                                                    disabled={isEdit}
                                                />
                                            )}
                                            rules={{
                                                required: !isEdit ? 'amountPaid is required' : false,
                                            }}
                                        />
                                        {isGrantUserAccessSubmitted && grantErrors.amountPaid && <p className="text-red-600 text-sm italic">{grantErrors.amountPaid.message}</p>}
                                    </div>
                                </div>
                                <div className='flex w-full flex-row  gap-x-4'>
                                    <div className="flex w-full flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="name"
                                                control={grantControl}
                                                render={({ field }) => (
                                                    <input
                                                        id="name"
                                                        type="text"
                                                        name="name"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder="User's Name"
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'name is required',
                                                }}
                                            />
                                            {isGrantUserAccessSubmitted && grantErrors.name && <p className="text-red-600 text-sm italic">{grantErrors.name.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Username</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="username"
                                                control={grantControl}
                                                render={({ field }) => (
                                                    <input
                                                        id="username"
                                                        type="text"
                                                        name="username"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder="Username on TUF"
                                                        {...field}
                                                        disabled={isEdit}
                                                    />
                                                )}
                                                rules={{
                                                    required: !isEdit ? 'username is required' : false,
                                                }}
                                            />
                                            {isGrantUserAccessSubmitted && grantErrors.username && <p className="text-red-600 text-sm italic">{grantErrors.username.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full flex-row  gap-x-4'>
                                    <div className="flex w-full flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Location</span>
                                        {/* {states && states?.length > 0 && states.map(state => (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    ))} */}
                                        <div className="w-full px-3">
                                            <Controller
                                                name="location"
                                                control={grantControl}
                                                render={({ field }) => (
                                                    <select
                                                        id="location"
                                                        name="location"
                                                        className="form-select w-full text-gray-300 "
                                                        {...field}
                                                    >
                                                        <option value="">Select Location</option>
                                                        {states && states?.length > 0 && states.map(state => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                                rules={{
                                                    required: 'Location is required',
                                                }}
                                            />
                                            {isGrantUserAccessSubmitted && grantErrors.location && <p className="text-red-600 text-sm italic">{grantErrors.location.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Phone number</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="phoneNumber"
                                                control={grantControl}
                                                render={({ field }) => (
                                                    <input
                                                        id="phoneNumber"
                                                        type="text"
                                                        name="phoneNumber"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder="Input with +91"
                                                        {...field}
                                                        disabled={isEdit}
                                                    />
                                                )}
                                                rules={{
                                                    required: !isEdit ? 'phone Number is required' : false,
                                                }}
                                            />
                                            {isGrantUserAccessSubmitted && grantErrors.phoneNumber && <p className="text-red-600 text-sm italic">{grantErrors.phoneNumber.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full flex-row  gap-x-4'>
                                    <div className="flex w-full flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Email</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="email"
                                                control={grantControl}
                                                render={({ field }) => (
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder="Email"
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'email is required',
                                                    validate: {
                                                        isEmailValid,
                                                    },
                                                }}
                                            />
                                            {isGrantUserAccessSubmitted && grantErrors.email && <p className="text-red-600 text-sm italic">{grantErrors.email.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Plan Name</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="plan_name"
                                                control={grantControl}
                                                render={({ field }) => (
                                                    <select
                                                        id="plan_name"
                                                        name="plan_name"
                                                        className="form-select w-full text-gray-300 "
                                                        {...field}
                                                    >
                                                        <option value="">Select Plan</option>
                                                        <option key={'Pinnacle'} value={'Pinnacle'}>Pinnacle</option>
                                                        <option key={'Sprint'} value={'Sprint'}>Sprint</option>
                                                    </select>
                                                )}
                                                rules={{
                                                    required: 'plan name is required',
                                                }}
                                            />
                                            {isGrantUserAccessSubmitted && grantErrors.plan_name && <p className="text-red-600 text-sm italic">{grantErrors.plan_name.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Currency</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="currency"
                                            control={grantControl}
                                            defaultValue="INR"
                                            render={({ field }) => (
                                                <select
                                                    id="currency"
                                                    name="currency"
                                                    className="form-select w-full text-gray-300 "
                                                    {...field}
                                                >
                                                    <option value="INR">INR</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'currency is required',
                                            }}
                                        />
                                        {isGrantUserAccessSubmitted && grantErrors.currency && <p className="text-red-600 text-sm italic">{grantErrors.currency.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Grant user access</button>
                                    </div>
                                </div>
                            </form>
                        </UsersModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default V2GrantPremiumAccess