/* eslint-disable no-unused-vars */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '../../components/Table/TablePagination';
import { setFeedBackFetch } from '../../slices/callsSlice';
import FeedbackTable from '../../components/Table/CallsTable/FeedbackTable';
import { fetchFeedbackUsers } from '../../services/operations/calls';

const FeedbackReport = ({ category, startDate, endDate, callTypeView, callType, currentPage, setCurrentPage }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [content, setContent] = useState('');

  const dispatch = useDispatch();
  const feedbacks = useSelector((state) => state.calls.allUserCalls);

  const itemsPerPage = 25;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Format dates only when necessary
  const formattedStartDate = useMemo(
    () => format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
    [startDate],
  );
  const formattedEndDate = useMemo(
    () => format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
    [endDate],
  );

  // Memoized API fetch parameters
  const feedbackFetch = useMemo(
    () => ({
      callType,
      category,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      page: currentPage,
    }),
    [category, formattedStartDate, formattedEndDate, currentPage, callType],
  );

  useEffect(() => {
    dispatch(setFeedBackFetch(feedbackFetch));
    dispatch(fetchFeedbackUsers(feedbackFetch));
  }, [dispatch, feedbackFetch]);

  const paginate = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
    },
    [setCurrentPage],
  );

  if (!feedbacks?.users || feedbacks.users.length === 0) {
    return (
      <div className='flex justify-center items-center text-3xl pt-4 dark:text-zinc-300'>
        No Feedbacks Found!
      </div>
    );
  }

  return (
    <main className='dark:bg-dark font-primary h-screen overflow-y-auto no-scrollbar bg-[#fafafa]'>
      <div className='mb-8'>
        <FeedbackTable
          setModalOpen={setModalOpen}
          setContent={setContent}
          feedbacks={feedbacks?.users}
          callType={callTypeView}
          offset={(currentPage - 1) * itemsPerPage}
        />
        <div className='border dark:border-dark_40 bg-[#F4F6F8] dark:bg-[#141414] border-gray-200 rounded-b-2xl mt-3'>
          <TablePagination
            itemsPerPage={itemsPerPage}
            totalItems={feedbacks?.count}
            currentPage={currentPage}
            indexOfFirstItem={indexOfFirstItem}
            indexOfLastItem={indexOfLastItem}
            paginate={paginate}
          />
        </div>
      </div>
    </main>
  );
};

export default FeedbackReport;
