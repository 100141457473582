const wspStatus = [
  { value: 'bought', label: 'Bought', color: '#FF2D55' }, // Bright Crimson Red
  { value: 'will buy later', label: 'Will Buy Later', color: '#FF5733' }, // Vivid Orange-Red
  { value: 'not interested', label: 'Not Interested', color: '#FF7E00' } // Bright Sunset Orange
];

const fndStatus = [
  { value: 'not called', label: 'Not Called', color: '#FFA500' }, // Bright Amber Orange
  { value: 'wrong num', label: 'Wrong Number', color: '#FF3B30' }, // Bright Scarlet Red
  { value: 'unreachable', label: 'Unreachable', color: '#E6399B' }, // Bright Magenta Pink
  { value: 'unanswered', label: 'Unanswered', color: '#A133FF' }, // Vivid Electric Purple
  { value: 'interested', label: 'Interested', color: '#5865F2' }, // Bright Royal Blue
  { value: 'not interested', label: 'Not Interested', color: '#4287F5' }, // Vibrant Sky Blue
  { value: 'already enrolled', label: 'Already Enrolled', color: '#00B4D8' }, // Electric Cyan
  { value: 'call later', label: 'Call Later', color: '#00C8FF' }, // Vibrant Aqua Blue
  { value: 'bought on call', label: 'Bought on Call', color: '#00E5FF' } // Bright Neon Blue
];

export { wspStatus, fndStatus };

