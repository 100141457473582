import { useSelector } from 'react-redux';

export const useSidebarFolders = () => {
    const { AllSubjects } = useSelector((state) => state.new_syllabus);

    const sidebarFolders = [
        {
            category: {
                name: 'Manage Courses',
                link: 'manage-courses',
            },
            subcategories: [
                {
                    name: 'My Courses',
                    link: 'manage-courses/my-courses',
                },
                {
                    name: 'Partner Courses',
                    link: 'manage-courses/partner-courses',
                },
            ],
        },
        {
            category: {
                name: 'Manage Content',
                link: 'manage-content',
            },
            subcategories: [
                {
                    name: 'All DSA Problems',
                    link: 'manage-content/all-dsa-problem',
                },
                {
                    name: 'All Theory Content',
                    link: 'manage-content/all-theory-problem',
                },
                {
                    name: 'All Quiz Content',
                    link: 'manage-content/all-quiz-problem',
                },
                {
                    name: 'All Aptitude Problem',
                    link: 'manage-content/all-aptitude-problem',
                },
            ],
        },
        {
            category: {
                name: 'Topics',
                link: 'topics',
            },
            subcategories: [
                {
                    name: 'All Subject',
                    link: 'topics/all-subjects',
                },
                {
                    name: 'All Category',
                    link: 'topics/all-categories',
                },
                {
                    name: 'All Subcategory',
                    link: 'topics/all-sub-categories',
                },
            ],
        },
        {
            category: {
                name: 'Syllabus',
                link: 'syllabus',
            },
            subcategories: AllSubjects.map((subject) => ({
                name: subject.name,
                link: `syllabus/${subject.slug}`,
            })),
        },
        {
            category : {
                name: 'Manage Interview Exp',
                link: 'interview',
            },
            subcategories: [
                {
                    name: 'Interview Experiences',
                    link: 'interview/all-interview-experiences',
                },
                {
                    name: 'Manage Companies',
                    link: 'interview/companies',
                },
            ],
        },
        {
            category: {
                name: 'Manage Sessions',
                link: 'admin#0',
            },
            subcategories: [
                {
                    name: 'Sessions',
                    link: 'manage-sessions',
                },
            ],
        },
        {
            category: {
                name: 'Manage Notifications',
                link: 'admin#0',
            },
            subcategories: [
                {
                    name: 'Notifications',
                    link: 'manage-notifications',
                },
            ],
        },
    ];

    return sidebarFolders;
};
