import { setAllCompanies, setCompany } from '../../slices/companies';
import { companiesEndpoints } from '../api';
import { apiConnector } from '../apiConnector';
const { ADD_COMPANY, DELETE_COMPANY, GET_ALL_COMPANIES, GET_COMPANY_BY_ID, UPDATE_COMPANY } =
  companiesEndpoints;

export function addCompany(companyData, setModalOpen,reset, isProd) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('POST', ADD_COMPANY, companyData);
      if (response.data.success) {
        dispatch(getAllCompanies());
        setModalOpen(false);
        reset({
            name: '',
            logo: '',
            type: '',
        })
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function deleteCompany(id) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('DELETE', DELETE_COMPANY + id);
      if (response.data.success) {
        dispatch(getAllCompanies());
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function getAllCompanies() {
  return async (dispatch) => {
    try {
      const response = await apiConnector('GET', GET_ALL_COMPANIES);
      if (response.data.success) {
        console.log(response.data.data);
        dispatch(setAllCompanies(response.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCompanyById(id) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('GET', GET_COMPANY_BY_ID + `${id}`);
      if (response.data.success) {
        dispatch(setCompany(response.data.company));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateCompany(companyData, setModalOpen, isProd) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('PUT', UPDATE_COMPANY + `${companyData.id}`, companyData);
      if (response.data.success) {
        dispatch(getAllCompanies());
        setModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
}
