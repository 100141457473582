import React from 'react'
import CategoryTableItem from './CategoryTableItem'

const CategoryTable = ({ topics, setModalOpen, page, setIsEdit, setParts, handleEdit, handleDelete }) => {
    return (
        <div className="  font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold flex items-start">Name</div>
                                </th>
                                {page === 'Category' && (
                                    <>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Rank</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Percentage</div>
                                        </th>
                                    </>

                                )}
                                {(page === 'PB' || page === "Deleted_PB") && (
                                    <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div className="font-semibold text-center">Rank</div>
                                    </th>
                                )}
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {topics && topics?.length > 0 ? (
                            topics?.map((data, index) => {
                                return (
                                    <CategoryTableItem
                                        id={data?.id}
                                        num={index + 1}
                                        setModalOpen={setModalOpen}
                                        setParts={setParts}
                                        setIsEdit={setIsEdit}
                                        name={data?.name}
                                        hasIDE={data?.hasIDE}
                                        note={data?.note}
                                        subcatid={data?.subcatid}
                                        problem_name={data?.problem_name}
                                        problem_id={data?.problem_id}
                                        cat_id={data?.cat_id || data?.category_id}
                                        rank={data?.rank}
                                        points={data?.points}
                                        parts={data?.parts}
                                        page={page}
                                        percentage={data?.percentage}
                                        slug={data?.slug}
                                        type={data?.type}
                                        handleEdit={handleEdit}
                                        handleDelete={handleDelete}
                                        difficulty={data?.difficulty}
                                        subject={data?.subject}
                                        metaData={data?.metaData}
                                        title={data?.title}
                                        isApproved={data?.isApproved}
                                        company_name={data?.name}
                                        company_logo={data?.logo}
                                        company_type={data?.type}

                                    />
                                )
                            })
                        ) : (
                            <></>
                        )
                        }
                    </table>

                </div>
            </div>
        </div >
    )
}

export default CategoryTable
