/* eslint-disable no-unused-vars */
import { salesDashboardEndpoints } from "../api";
import { apiConnector } from "../apiConnector";
import toast from "react-hot-toast";
import { setLoading, setError, setUserDetails, setUserCalls, setCallDetailsRedux, setUserViaEmail, updateBuyingStatusRedux, setIsUserViaEmail, setFetchedDataBar } from "../../slices/callsSlice";
const { GET_FAILED_USERS, GET_FAILED_USER_DETAILS, GET_DROPPED_USERS, GET_DROPPED_USER_DETAILS, UPDATE_CALL_STATUS, ADD_COUPON_CODE, ADD_CALL_DETAILS, FIND_USER_BY_EMAIL, GET_ALL_WHATSAPP_USERS, GET_WHATSAPP_USER_DETAILS, UPDATE_BUYING_STATUS, ADD_WHATSAPP_USER_DETAILS, ADD_WHATSAPP_USER, GET_COUNT, GET_COUNT_DAILY, GET_FEEDBACK_USERS, GET_FEEDBACK_USER_DETAILS, UPDATE_FEEDBACK_CALL_DETAILS, UPDATE_WHATSAPP_USER_DETAILS } = salesDashboardEndpoints;

//fetchDetails: { category: 'all', startDate: '', endDate: '', page: 1, isEnrolled: 'all', orderBy: 'ASC' },
export const fetchUsers = (type, fetchDetails) => {
    const { category, startDate, endDate, page, isEnrolled, orderBy } = fetchDetails

    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            let API_QUERY = '';

            switch (type) {
                case 'failed':
                    API_QUERY = `${GET_FAILED_USERS}`;
                    break;
                case 'dropped':
                    API_QUERY = `${GET_DROPPED_USERS}`;
                    break;
                case 'whatsapp':
                    API_QUERY = `${GET_ALL_WHATSAPP_USERS}`;
                    break;
                default:
                    throw new Error("Invalid user type");
            }

            API_QUERY += `?page=${page}&startDate=${startDate}&endDate=${endDate}`;

            if (category !== 'all') {
                API_QUERY += `&category=${category}`;
            }

            if (isEnrolled === 'enrolled') {
                API_QUERY += `&isEnrolled=1`;
            } else if (isEnrolled === 'not enrolled') {
                API_QUERY += `&isEnrolled=0`;
            }

            API_QUERY += `&orderBy=${orderBy}`;

            const response = await apiConnector("GET", API_QUERY);
            if (response.data.success)
                dispatch(setUserCalls(response.data));

        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching calls"));
            toast.error("Error fetching calls");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchFailedUserDetails = (email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", GET_FAILED_USER_DETAILS, { email });
            if (response.data.success) {
                dispatch(setUserDetails(response.data.userDetails))
                dispatch(setCallDetailsRedux(response.data.userDetails.call_details))
            }
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error("Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchDroppedUserDetails = (email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", GET_DROPPED_USER_DETAILS, { email });
            if (response.data.success) {
                dispatch(setUserDetails(response.data.userDetails));
                dispatch(setCallDetailsRedux(response.data.userDetails.call_details));
            }
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error(error.response?.data?.error || "Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateCallStatus = (email, userType, status) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", UPDATE_CALL_STATUS, { email, userType, status })
            if (response.data.success)
                toast.success("call status updated");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating call status"));
            toast.error("Error updating call status");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addCouponCode = (code, email, userType) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_COUPON_CODE, { code, email, userType });
            if (response.data.success)
                toast.success("Coupn Code Added");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding coupon code"));
            toast.error("Error adding coupon code");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addCallDetails = (transcript, duration, notes, email, userType) => {
    return async (dispatch) => {

        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_CALL_DETAILS, { transcript, duration, notes, email, userType });

            if (response.data.success) {
                dispatch(setCallDetailsRedux([{ transcript: transcript, duration: duration, notes: notes }]))
                toast.success("Call Details Updated");
            }
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding call details"));
            toast.error("Error adding call details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const getUserByEmail = (email, userType) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", FIND_USER_BY_EMAIL, { email, userType });

            if (response.data.success) {
                dispatch(setUserViaEmail(response.data))
                dispatch(setIsUserViaEmail(true))
                toast.success("User found");
            }
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error Finding the user"));
            toast.error("Error finding the user");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchWhatsappUserDetails = (email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", GET_WHATSAPP_USER_DETAILS, { email });
            if (response.data.success) {
                dispatch(setUserDetails(response.data.userDetails));
                dispatch(setCallDetailsRedux(response.data.userDetails?.call_details));
            }
        } catch (error) {
            console.error("Error fetching user details:", error);

            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error(error.response?.data?.error || "Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateBuyingStatus = (email, status) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", UPDATE_BUYING_STATUS, { email, status })
            if (response.data.success) {
                dispatch(updateBuyingStatusRedux(status))
                toast.success("call status updated");
            } else {
                toast.error(response.data.message || "Failed to update status");
            }

        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating call status"));
            toast.error("Error updating call status");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addWhatsappUserDetails = (notes, email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", ADD_WHATSAPP_USER_DETAILS, { notes, email })
            if (response.data.success)
                toast.success("user details updated");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating user details"));
            toast.error("Error updating user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateWhatsappUserDetails = (details) => {
    const { id, email, name, phone, status, code } = details;
    
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", UPDATE_WHATSAPP_USER_DETAILS, { id, email, name, phone, status, code })
            if (response.data.success)
                toast.success("user details updated");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating user details"));
            toast.error("Error updating user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addWhatsappUser = (email, name, phone, status, code) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_WHATSAPP_USER, { email, name, phone, status, code })
            if (response.data.success)
                toast.success("Whatsapp User Added");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding whatsapp user"));
            toast.error("Error adding whatsapp user");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchUsersCount = (type, fetchDetails) => {
    return async (dispatch) => {
        const { startDate, endDate, isEnrolled } = fetchDetails;
        dispatch(setLoading(true));

        try {
            const queryParams = new URLSearchParams({
                userType: type,
                startDate,
                endDate,
            });

            if (isEnrolled !== 'all') queryParams.append('isEnrolled', isEnrolled === 'enrolled' ? '1' : '0');

            const API_QUERY = `${GET_COUNT}?${queryParams.toString()}`;

            const response = await apiConnector("GET", API_QUERY);
            if (!response?.data) throw new Error("Invalid API response");
            return (response.data.success) ? (response.data) : null;
        } catch (error) {
            dispatch(setError(error?.response?.data?.error || "Error fetching calls"));
            toast.error("Error fetching calls");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchUsersCountDaily = (type, fetchDetails) => {
    return async (dispatch) => {
        const { startDate, endDate, isEnrolled } = fetchDetails;
        dispatch(setLoading(true));

        try {
            const queryParams = new URLSearchParams({
                userType: type,
                startDate,
                endDate,
            });

            if (isEnrolled !== 'all') queryParams.append('isEnrolled', isEnrolled === 'enrolled' ? '1' : '0');

            const API_QUERY = `${GET_COUNT_DAILY}?${queryParams.toString()}`;

            const response = await apiConnector("GET", API_QUERY);
            if (!response?.data) throw new Error("Invalid API response");
            
            if (response.data.success)
                dispatch(setFetchedDataBar(response.data))
        } catch (error) {
            dispatch(setError(error?.response?.data?.error || "Error fetching calls"));
            toast.error("Error fetching calls");
        } finally {
            dispatch(setLoading(false));
        }
    };
};


// { category, callType, startDate, endDate, page = 1 } 
export const fetchFeedbackUsers = (fetchDetails = {}) => {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const { category, callType, startDate, endDate, page = 1 } = fetchDetails;

            // 'both', 'post 24hrs', 'post 2w'
            const callTypeMap = { 'both': null, 'post 24hrs': 1, 'post 2w': 2 }
            const params = new URLSearchParams({ page });

            params.append("startDate", startDate);
            params.append("endDate", endDate);
            if (category !== 'all') {
                params.append("category", category)
                params.append("callType", callTypeMap[callType])
            };

            const API_QUERY = `${GET_FEEDBACK_USERS}?${params.toString()}`;
            const response = await apiConnector("GET", API_QUERY);
            if (response.data.success)
                dispatch(setUserCalls(response.data))

        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching calls"));
            toast.error("Error fetching calls");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchFeedbackUserDetails = (email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", GET_FEEDBACK_USER_DETAILS, { email });
            if (response.data.success)
                dispatch(setUserDetails(response.data.userDetails))
        } catch (error) {
            console.error("Error fetching user details:", error);

            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error(error.response?.data?.error || "Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateFeedbackUserDetails = (feedbackCallData = {}) => {
    const { status, notes, email, callType } = feedbackCallData
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", UPDATE_FEEDBACK_CALL_DETAILS, { status, notes, email, callType });
            if (response.data.success)
                toast.success('Update Successful')
        } catch (error) {
            console.error("Error fetching user details:", error);

            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error(error.response?.data?.error || "Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};


