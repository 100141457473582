import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import {
  addWhitelistEmail,
  deleteWhitelistEmail,
  getWhitelistEmails,
} from '../../services/operations/premium';

const Whitelist = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { whitelist } = useSelector((state) => state.premium);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWhitelistEmails());
  }, [dispatch]);

  const handleModalOpen = (e) => {
    e.stopPropagation();

    setModalOpen(true);
  };

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const handleOnSubmit = (data) => {
    const { email } = data;
    dispatch(addWhitelistEmail(email, setModalOpen));
    setValue('email', '');
  };

  const handleDeleteEmail = (email) => {
    dispatch(deleteWhitelistEmail(email));
  };

  return (
    <div className='flex dark:bg-dark  h-screen overflow-hidden'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
            <div className='flex mt-4 mb-8 flex-row justify-between'>
              <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>
                Add
              </button>
            </div>
            {whitelist && whitelist.length > 0 ? (
              <>
                <div className='  font-primary  relative w-full'>
                  <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                    <div className='overflow-x-auto'>
                      <table className='table-auto w-full  divide-y  divide-gray-200'>
                        <thead className='text-xs uppercase  text-[#8C8C8C] '>
                          <tr>
                            <th className='px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap'>
                              <div className='font-semibold flex items-start'>Email</div>
                            </th>

                            <th className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'>
                              <div className='font-semibold text-center'>Action</div>
                            </th>
                          </tr>
                        </thead>

                        <tbody className='text-[13px]'>
                          {whitelist?.map((data, index) => (
                            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                              <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
                                <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start'>
                                  {data}
                                </div>
                              </td>
                              <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
                                <div className='flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4'>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteEmail(data);
                                    }}
                                    className='btn bg-red-500 text-white  border-gree-500'
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                No user to show
              </div>
            )}
            <CategoryModal title='Add Email' setModalOpen={setModalOpen} modalOpen={modalOpen}>
              <form onSubmit={handleSubmit(handleOnSubmit)} className=''>
                <div className='flex flex-wrap -mx-3 mb-4'>
                  <div className='w-full px-3'>
                    <Controller
                      name='email'
                      control={control}
                      render={({ field }) => (
                        <input
                          type='text'
                          name='userName'
                          className='form-input w-full text-gray-300'
                          placeholder='email'
                          {...field}
                        />
                      )}
                      rules={{
                        required: 'email is required',
                      }}
                    />
                    {errors.email && (
                      <p className='text-red-600 text-sm italic'>{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div className='flex flex-wrap -mx-3 mt-6'>
                  <div className='w-full px-3'>
                    <button type='submit' className='btn-brand'>
                      Add Email
                    </button>
                  </div>
                </div>
              </form>
            </CategoryModal>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Whitelist;
