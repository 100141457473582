import React, { useMemo, useState } from 'react';
import JudgePerformanceStatsTableItem from './JudgePerformanceStatsTableItem';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = ['#77DD77', '#FFB347', '#FF6961', '#FF4040']; // Bright pastel shades

function JudgePerformanceStatsTable({ topics = [] }) {
  const [selectedDate, setSelectedDate] = useState(null);

  const sumPerformanceData = (performanceArray) => {
    const summedData = performanceArray.reduce((acc, curr) => {
      Object.entries(curr || {}).forEach(([key, value]) => {
        acc[key] = (acc[key] || 0) + value;
      });
      return acc;
    }, {});

    // Calculate total for percentage calculation
    const total = Object.values(summedData).reduce((sum, val) => sum + val, 0);

    // Add percentage key
    Object.keys(summedData).forEach((key) => {
      summedData[key] = {
        value: summedData[key],
        percentage: total ? ((summedData[key] / total) * 100).toFixed(2) : '0.00',
      };
    });

    return summedData;
  };

  // Memoized performance data calculation
  const { runPerformance, submitPerformance } = useMemo(() => {
    if (!topics.length) return { runPerformance: {}, submitPerformance: {} };

    const filteredTopics = selectedDate ? topics.filter((t) => t.date === selectedDate) : topics;
    return {
      runPerformance: sumPerformanceData(filteredTopics.map((t) => t.runPerformance || {})),
      submitPerformance: sumPerformanceData(filteredTopics.map((t) => t.submitPerformance || {})),
    };
  }, [selectedDate, topics]);

  const formatChartData = (performanceData) => {
    if (!performanceData || Object.keys(performanceData).length === 0) return [];
    return Object.entries(performanceData).map(([key, { value, percentage }], index) => ({
      name: key,
      value,
      percentage,
      color: COLORS[index % COLORS.length],
    }));
  };

  const runChartData = formatChartData(runPerformance);
  const submitChartData = formatChartData(submitPerformance);

  return (
    <div className='font-primary relative'>
      {/* Charts Section */}
      <div className='relative flex justify-center my-4 w-full'>
        <div className='flex'>
          {/* Run Performance Chart */}
          <div className='mr-16 text-center'>
            <h3 className='text-lg font-semibold mb-2'>
              Run Performance {selectedDate ? `(${selectedDate})` : '(Total)'}
            </h3>
            {runChartData.length ? (
              <PieChart width={300} height={300}>
                <Pie data={runChartData} dataKey='value' nameKey='name' cx='50%' cy='50%' outerRadius={100}>
                  {runChartData.map((entry, index) => (
                    <Cell key={index} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  content={({ active, payload }) => {
                    if (!active || !payload?.length) return null;
                    return (
                      <div className='custom-tooltip bg-gray-700 text-white p-2 rounded shadow-lg'>
                        {payload.map((entry, index) => (
                          <div key={index}>
                            <p className='font-semibold'>{`Category: ${entry.name}`}</p>
                            <p>{`Value: ${entry.value}`}</p>
                            <p>{`Percentage: ${entry.payload.percentage}%`}</p>
                          </div>
                        ))}
                      </div>
                    );
                  }}
                />
                <Legend />
              </PieChart>
            ) : (
              <p className='text-gray-500'>No data available</p>
            )}
          </div>

          {/* Submit Performance Chart */}
          <div className='mr-16 text-center'>
            <h3 className='text-lg font-semibold mb-2'>
              Submit Performance {selectedDate ? `(${selectedDate})` : '(Total)'}
            </h3>
            {submitChartData.length ? (
              <PieChart width={300} height={300}>
                <Pie data={submitChartData} dataKey='value' nameKey='name' cx='50%' cy='50%' outerRadius={100}>
                  {submitChartData.map((entry, index) => (
                    <Cell key={index} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  content={({ active, payload }) => {
                    if (!active || !payload?.length) return null;
                    return (
                      <div className='custom-tooltip bg-gray-700 text-white p-2 rounded shadow-lg'>
                        {payload.map((entry, index) => (
                          <div key={index}>
                            <p className='font-semibold'>{`Category: ${entry.name}`}</p>
                            <p>{`Value: ${entry.value}`}</p>
                            <p>{`Percentage: ${entry.payload.percentage}%`}</p>
                          </div>
                        ))}
                      </div>
                    );
                  }}
                />
                <Legend />
              </PieChart>
            ) : (
              <p className='text-gray-500'>No data available</p>
            )}
          </div>
        </div>

        {/* Reset Button */}
        <button
          className={`absolute left-[10%] top-1/2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 flex items-center transition ${
            selectedDate ? 'block' : 'hidden'
          }`}
          onClick={() => setSelectedDate(null)}
        >
          Show Total
        </button>
      </div>

      {/* Table Section */}
      <div className='bg-white dark:bg-dark_50 rounded-t-xl'>
        <div className='overflow-x-auto'>
          <table className='table-auto w-full divide-y divide-gray-200'>
            <thead className='text-xs uppercase text-[#8C8C8C]'>
              <tr>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 py-3'>No.</th>
                <th className='px-2 border-2 border-lead100 dark:border-lead500 py-3'>Date</th>
                <th colSpan='4' className='px-2 border-2 border-lead100 dark:border-lead500 py-3'>
                  Run Performance
                </th>
                <th colSpan='4' className='px-2 border-2 border-lead100 dark:border-lead500 py-3'>
                  Submit Performance
                </th>
              </tr>
            </thead>
            <tbody>
              {topics.map((data, index) => (
                <JudgePerformanceStatsTableItem
                  key={index}
                  num={index + 1}
                  date={data.date}
                  runPerformance={data.runPerformance}
                  submitPerformance={data.submitPerformance}
                  selectedDate={selectedDate}
                  onDateClick={() => setSelectedDate(data.date)}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default JudgePerformanceStatsTable;
