import { createSlice } from '@reduxjs/toolkit';

const judgeStatsSlice = createSlice({
  name: 'judgeStats',
  initialState: {
    runSubmitStats: [],
    runSubmitPerformanceStats: [],
  },
  reducers: {
    setRunSubmitStats: (state, action) => {
      state.runSubmitStats = action.payload;
    },
    setRunSubmitPerformanceStats: (state, action) => {
      state.runSubmitPerformanceStats = action.payload;
    },
  },
});

export const { setRunSubmitStats, setRunSubmitPerformanceStats } = judgeStatsSlice.actions;
export default judgeStatsSlice.reducer;