import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFetchDetails } from '../../slices/callsSlice';
import { fetchUsersCountDaily } from '../../services/operations/calls';
import { format } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { wspStatus, fndStatus } from '.';

const SalesBarChart = ({ userType, startDate, endDate }) => {
  const dispatch = useDispatch();
  const fetchedDataBar = useSelector((state) => state.calls.fetchedDataBar);
  const fetchDetails = useSelector((state) => state.calls.fetchDetails);

  const formattedStartDate = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd HH:mm:ss');

  const selectedStatusList = useMemo(
    () => (userType === 'whatsapp' ? wspStatus : fndStatus),
    [userType],
  );

  // State to track which statuses are visible
  const [visibleStatuses, setVisibleStatuses] = useState(
    selectedStatusList.reduce((acc, status) => ({ ...acc, [status.value]: true }), {}),
  );

  // Reset visibility when userType changes
  useEffect(() => {
    setVisibleStatuses(
      selectedStatusList.reduce((acc, status) => ({ ...acc, [status.value]: true }), {}),
    );
  }, [userType, selectedStatusList]);

  useEffect(() => {
    if (formattedStartDate && formattedEndDate)
      dispatch(setFetchDetails({ startDate: formattedStartDate, endDate: formattedEndDate }));
  }, [dispatch, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    if (!(fetchDetails?.startDate && fetchDetails?.endDate)) return;
    dispatch(fetchUsersCountDaily(userType, fetchDetails));
  }, [dispatch, userType, fetchDetails]);

  const chartData = useMemo(() => {
    if (!fetchedDataBar || !Array.isArray(fetchedDataBar.data) || fetchedDataBar.data.length === 0)
      return [];

    return fetchedDataBar.data.map((entry) => {
      const updatedCategories = { ...entry.categories };

      // Extract the values of "null" or "" and reassign them accordingly
      const nullValue = updatedCategories['null'] || 0;
      const emptyValue = updatedCategories[''] || 0;
      const totalToMove = nullValue + emptyValue;

      if (totalToMove > 0) {
        const targetKey = userType === 'whatsapp' ? 'not interested' : 'not called';
        updatedCategories[targetKey] = (updatedCategories[targetKey] || 0) + totalToMove;
        delete updatedCategories['null'];
        delete updatedCategories[''];
      }

      return {
        date: entry.date,
        total: entry.total,
        ...updatedCategories,
      };
    });
  }, [fetchedDataBar, userType]);

  // console.log('Transformed Chart Data:', chartData);

  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  // Toggle visibility of status lines
  const toggleStatus = (status) => {
    setVisibleStatuses((prev) => ({
      ...prev,
      [status]: !prev[status],
    }));
  };

  // Custom Legend Component with Checkboxes in a Single Row
  const renderCustomLegend = () => (
    <div className='flex items-center overflow-x-auto whitespace-nowrap space-x-4 p-4 max-w-full'>
      {selectedStatusList.map((status) => (
        <label key={status.value} className='flex items-center space-x-2 cursor-pointer'>
          <input
            type='checkbox'
            checked={visibleStatuses[status.value]}
            onChange={() => toggleStatus(status.value)}
            className={`cursor-pointer w-5 h-5 rounded border-2 dark:border-white
              text-black dark:text-white focus:ring-gray-400 dark:focus:ring-gray-500 
              hover:border-black dark:hover:border-white transition-all duration-200 dark:bg-black`}
          />
          <span style={{ color: status.color, fontWeight: 'bold', whiteSpace: 'nowrap' }}>
            {status.label}
          </span>
        </label>
      ))}
    </div>
  );

  return (
    <div className='flex flex-col items-center justify-center mt-8'>
      <h2 className='text-lg font-semibold mb-4'>Daily Sales Trends</h2>

      {/* Combined Legend and Checkboxes in a Single Row */}
      <ResponsiveContainer width='100%' height={450}>
        <LineChart data={chartData} margin={{ top: 20, right: 80, left: 20, bottom: 40 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='date'
            stroke={isDarkMode ? '#808080' : '#333'}
            tick={{ fontSize: 12, fill: isDarkMode ? '#808080' : '#333' }}
            angle={-45}
            textAnchor='end'
            height={60}
          />
          <YAxis
            stroke={isDarkMode ? '#808080' : '#333'}
            tick={{ fontSize: 12, fill: isDarkMode ? '#808080' : '#333' }}
            allowDecimals={false}
            domain={['dataMin', 'dataMax']}
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              fill: isDarkMode ? '#808080' : '#333',
            }}
            padding={{ top: 20, bottom: 10 }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: isDarkMode ? '#333' : '#fff',
              color: isDarkMode ? '#fff' : '#000',
            }}
          />
          <Legend content={renderCustomLegend} />

          {/* Render only selected lines */}
          {selectedStatusList.map(
            (status) =>
              visibleStatuses[status.value] && (
                <Line
                  key={status.value}
                  type='monotone'
                  dataKey={status.value}
                  stroke={status.color}
                />
              ),
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesBarChart;
