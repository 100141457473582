/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Transition from '../../utils/Transition';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { htmlToMarkdown, markdownToHtml } from '../RichEditor/Parser';
import { useSelector, useDispatch } from 'react-redux';
import {
  setUserDetails,
  updateCallStatusRedux,
  setEmail,
  setCodeUsedRedux,
  updateBuyingStatusRedux,
} from '../../slices/callsSlice';
import {
  fetchWhatsappUserDetails,
  addCouponCode,
  addWhatsappUserDetails,
  updateBuyingStatus,
} from '../../services/operations/calls';
import moment from 'moment';

function WhatsappEntryDetailsModal({ email, modalOpen, setModalOpen }) {
  const modalContent = useRef(null);
  const userEntryDetails = useSelector((state) => state.calls.userDetails);
  const userType = useSelector((state) => state.calls.userType);
  const buyingStatusRedux = useSelector((state) => state.calls.status);
  const [transcriptModalOpen, setTranscriptModalOpen] = useState(false);
  const [buyingStatus, setBuyingStatus] = useState(buyingStatusRedux);

  const dispatch = useDispatch();

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || transcriptModalOpen) return;
      if (modalContent.current.contains(target)) return;

      // Close outer modal logic
      setModalOpen(false);
      dispatch(updateCallStatusRedux(''));
      dispatch(setEmail(''));
      dispatch(setCodeUsedRedux(''));
      dispatch(setUserDetails({}));
    };

    document.addEventListener('mousedown', clickHandler);
    return () => document.removeEventListener('mousedown', clickHandler);
  }, [dispatch, modalOpen, setModalOpen, transcriptModalOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      if (transcriptModalOpen) return; // Don't close outer modal if TranscriptModal is open

      // Close outer modal logic
      setModalOpen(false);
      dispatch(updateCallStatusRedux(''));
      dispatch(setEmail(''));
      dispatch(setCodeUsedRedux(''));
      dispatch(setUserDetails({}));
    };

    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dispatch, modalOpen, setModalOpen, transcriptModalOpen]);

  const [isEditingCodeUsed, setIsEditingCodeUsed] = useState(false);
  const code_used = useSelector((state) => state.calls.code_used || 'NONE');
  const [codeUsed, setCodeUsed] = useState(code_used);

  const call_status = useSelector((state) => state.calls.status || 'not called');

  useEffect(() => {
    setCodeUsed(code_used);
  }, [code_used]);

  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(markdownToHtml());
  const [tempNotes, setTempNotes] = useState(notes); // Temporary state for editing
  const reactQuillRef = useRef(null);

  useEffect(() => {
    if (modalOpen && email) {
      dispatch(setEmail(email));
      if (userType === 'whatsapp') {
        dispatch(fetchWhatsappUserDetails(email));
      }
    }
  }, [modalOpen, email, userType, dispatch]);

  useEffect(() => {
    setNotes(userEntryDetails?.notes);
  }, [userEntryDetails]);

  useEffect(() => {
    dispatch(updateCallStatusRedux(userEntryDetails?.buying_status));
    dispatch(setCodeUsedRedux(userEntryDetails?.code_used));
  }, [dispatch, userEntryDetails]);

  const handleBuyingStatusUpdate = (selectedValue) => {
    setBuyingStatus(selectedValue);
    dispatch(updateBuyingStatus(email, selectedValue));
    dispatch(updateBuyingStatusRedux(selectedValue));
  };

  const handleCodeUsedChange = (newCode) => {
    const trimmedCode = newCode.trim();
    if (trimmedCode !== code_used) {
      dispatch(addCouponCode(trimmedCode.toUpperCase(), email, userType));
      dispatch(setCodeUsedRedux(trimmedCode.toUpperCase()));
    }
    setCodeUsed(trimmedCode.toUpperCase());
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text || '')
      .then(() => toast.success('Copied to clipboard!'))
      .catch(() => toast.error('Failed to copy.'));
  };

  const handleSaveNotes = () => {
    let editorContent;
    if (reactQuillRef.current) {
      editorContent = reactQuillRef.current.getEditor().root.innerHTML.trim();
      setNotes(editorContent || '');
    }

    dispatch(addWhatsappUserDetails(editorContent || '', email));
    setIsEditing(false);
  };

  const handleEdit = () => {
    setTempNotes(notes);
    setIsEditing(true);
  };

  // Close modal and clear user details
  const handleClose = (e) => {
    e.stopPropagation();
    dispatch(updateCallStatusRedux(''));
    dispatch(setEmail(''));
    dispatch(setCodeUsedRedux(''));
    dispatch(setUserDetails({}));
    setIsEditing(false);
    setIsEditingCodeUsed(false);
    setModalOpen(false);
  };

  return (
    <>
      <Transition
        className='fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity'
        show={modalOpen}
        enter='transition ease-out duration-200'
        enterStart='opacity-0'
        enterEnd='opacity-100'
        leave='transition ease-out duration-100'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        aria-hidden='true'
      />
      <Transition
        className='fixed inset-0 z-50 flex items-center justify-center px-2 sm:px-6'
        show={modalOpen}
        enter='transition ease-in-out duration-200'
        enterStart='opacity-0 translate-y-4'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-in-out duration-200'
        leaveStart='opacity-100 translate-y-0'
        leaveEnd='opacity-0 translate-y-4'
      >
        <div
          ref={modalContent}
          className='bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar w-full max-w-[90%] sm:max-w-[75%] md:max-w-[60%] max-h-[80%] flex flex-col p-3 sm:p-6'
        >
          {/* Modal Header */}
          <div className='flex justify-between items-center border-b dark:border-zinc-700 pb-2'>
            <div></div>
            <h2 className='font-bold text-lg sm:text-xl text-align text-zinc-800 dark:text-zinc-100'>
              User Details
            </h2>
            <button
              className='text-sm font-medium text-red-500 hover:text-red-700'
              onClick={handleClose}
            >
              Close
            </button>
          </div>

          {/* Main Content */}
          <div className='space-y-4 py-4'>
            <div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4'>
                {/* Left Column */}
                <div className='space-y-4 text-sm sm:text-base'>
                  {/* Name */}
                  <div className='flex items-start justify-start gap-4'>
                    <label className='font-semibold'>Name:</label>
                    <span>{userEntryDetails?.name || 'N/A'}</span>
                  </div>
                  {/* Email */}
                  <div className='flex items-start justify-start gap-4'>
                    <label className='font-semibold'>Email:</label>
                    <div className='flex flex-wrap items-center'>
                      <span>{userEntryDetails?.email || 'N/A'}</span>
                      {userEntryDetails?.email && (
                        <button
                          className='ml-2 text-red-500 font-medium hover:text-red-700'
                          onClick={() => copyToClipboard(userEntryDetails.email)}
                        >
                          Copy
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Contact */}
                  <div className='flex items-start justify-start gap-4'>
                    <label className='font-semibold'>Contact:</label>
                    <div className='flex flex-wrap items-center'>
                      <span>{userEntryDetails?.phone_number || 'N/A'}</span>
                      {userEntryDetails?.phone_number && (
                        <button
                          className='ml-2 text-red-500 font-medium hover:text-red-700'
                          onClick={() => copyToClipboard(userEntryDetails?.phone_number)}
                        >
                          Copy
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div className='space-y-4 text-sm sm:text-base'>
                  {/* Enrollment Status */}
                  <div className='flex items-start justify-start gap-4'>
                    <label className='font-semibold'>Enrollment Status:</label>
                    <span
                      className={
                        userEntryDetails?.isEnrolled
                          ? 'text-green-500 font-medium'
                          : 'text-red-500 font-medium'
                      }
                    >
                      {userEntryDetails?.isEnrolled ? 'Enrolled' : 'Not Enrolled'}
                    </span>
                  </div>
                  {/* Buying Status */}
                  <div className='flex items-start justify-start gap-4'>
                    <label className='font-semibold'>Buying Status:</label>
                    <select
                      className='text-sm px-2 py-1 bg-gray-50 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 focus:outline-none focus:ring focus:ring-red-500 appearance-none'
                      value={call_status}
                      onChange={(e) => handleBuyingStatusUpdate(e.target.value)}
                    >
                      <option value='not interested'>Not Interested</option>
                      <option value='will buy later'>Will Buy Later</option>
                      <option value='bought'>Bought</option>
                    </select>
                  </div>

                  {/* Code Used Field */}
                  <div className='flex items-start justify-start gap-4'>
                    <label className='font-semibold'>Code Used:</label>
                    {isEditingCodeUsed ? (
                      <input
                        type='text'
                        value={codeUsed}
                        onChange={(e) => setCodeUsed(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleCodeUsedChange(codeUsed);
                            setIsEditingCodeUsed(false);
                          }
                        }}
                        className='px-2 py-1 border rounded-md focus:ring focus:ring-red-500 focus:outline-none'
                      />
                    ) : (
                      <span>{code_used || 'None'}</span>
                    )}
                    <button
                      className='ml-2 text-red-500 font-medium hover:text-red-700'
                      onClick={() => {
                        if (isEditingCodeUsed) handleCodeUsedChange(codeUsed);
                        setIsEditingCodeUsed(!isEditingCodeUsed);
                      }}
                    >
                      {isEditingCodeUsed ? 'Save' : 'Edit'}
                    </button>
                  </div>
                  {/* Created At */}
                  <div className='flex items-start justify-start gap-4'>
                    <label className='font-semibold'>Created:</label>
                    <span>
                      {moment(userEntryDetails?.created_at).format('MMM DD, YYYY, h:mm A') || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Notes Field */}
            <div className='flex flex-col'>
              <div className='flex items-center justify-between mb-2'>
                <span className='font-semibold text-sm sm:text-base'>Notes:</span>
                <button
                  className={`px-3 py-1 rounded text-sm sm:text-sm font-medium ${
                    isEditing
                      ? 'bg-green-500 text-white hover:bg-green-600'
                      : 'bg-red-500 text-white hover:bg-red-600'
                  }`}
                  onClick={isEditing ? handleSaveNotes : handleEdit}
                >
                  {isEditing ? 'Save' : 'Edit'}
                </button>
              </div>
              {isEditing ? (
                <ReactQuill
                  ref={reactQuillRef}
                  theme='snow'
                  placeholder='Start writing...'
                  value={tempNotes || ''}
                  className='rich-editor-height rounded h-40 sm:h-56'
                  onChange={setTempNotes} // Update temporary notes while editing
                />
              ) : (
                <div
                  className='p-3 bg-gray-50 dark:bg-zinc-800 rounded shadow-md border border-gray-200 dark:border-zinc-700 text-sm sm:text-sm'
                  dangerouslySetInnerHTML={{
                    __html: notes || 'No notes available.',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}

export default WhatsappEntryDetailsModal;