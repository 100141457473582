import toast from "react-hot-toast"
import { setAllCouponCodes, setAllPlans, setWhitelistEmails } from "../../slices/premiumSlice"
import { premiumEndpoints } from "../api"
import { apiConnector } from "../apiConnector"


const { ADD_PLAN, EDIT_PLAN, GET_ALL_PLANS, DELETE_PLAN, GET_ALL_COUPON_CODES, ADD_COUPON_CODE, EDIT_COUPON_CODE, DELETE_COUPON_CODE, GET_WHITELIST_EMAILS, ADD_WHITELIST_EMAIL, DELETE_WHITELIST_EMAIL } = premiumEndpoints


export function getAllPlans() {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("GET", GET_ALL_PLANS)
            dispatch(setAllPlans(response.data.allPlans))
        } catch (error) {
            console.log(error)
        }
    }
}

export function addPlan(name, price, setModalOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("POST", ADD_PLAN, { name, price })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalOpen(false)
                dispatch(getAllPlans())
            }
        } catch (error) {
            console.log(error)
        }
    }
}
export function updatePlan(id, name, price, setModalOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("PUT", EDIT_PLAN, { id, name, price })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalOpen(false)
                dispatch(getAllPlans())
            }
        } catch (error) {
            console.log(error)
        }
    }
}
export function deletePlan(id, setModalDeleteOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("DELETE", DELETE_PLAN, { id })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalDeleteOpen(false)
                dispatch(getAllPlans())
            }
        } catch (error) {
            console.log(error)
        }
    }
}


export function getAllCoupons() {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("GET", GET_ALL_COUPON_CODES)
            const nonAffiliateCoupons = response.data.allCoupons.filter(coupon => coupon.isAffiliate === 0);
            dispatch(setAllCouponCodes(nonAffiliateCoupons));
        } catch (error) {
            console.log(error)
        }
    }
}

export function addCoupon(discount, isActive,isPublic, maxCount, name, setModalOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("POST", ADD_COUPON_CODE, { discount, isActive,isPublic, maxCount, name })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalOpen(false)
                dispatch(getAllCoupons())
            }
        } catch (error) {
            console.log(error)
        }
    }
}
export function updateCoupon(id, discount, isActive,isPublic, maxCount, name, setModalOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("PUT", EDIT_COUPON_CODE, { id, discount, isActive,isPublic, maxCount, name })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalOpen(false)
                dispatch(getAllCoupons())
            }
        } catch (error) {
            console.log(error)
        }
    }
}
export function deleteCoupon(id, setModalDeleteOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("DELETE", DELETE_COUPON_CODE, { id })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalDeleteOpen(false)
                dispatch(getAllCoupons())
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function getWhitelistEmails() {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("GET", GET_WHITELIST_EMAILS)
            dispatch(setWhitelistEmails(response.data.whitelistEmails))
        } catch (error) {
            console.log(error)
        }
    }
}

export function addWhitelistEmail(email, setModalOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("POST", ADD_WHITELIST_EMAIL, { email })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalOpen(false)
                dispatch(getWhitelistEmails())
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function deleteWhitelistEmail(email, setModalDeleteOpen) {
    return async (dispatch, getState) => {
        try {
            const response = await apiConnector("DELETE", DELETE_WHITELIST_EMAIL, { email })
            if (response.data.success) {
                toast.success(response.data.message)
                setModalDeleteOpen(false)
                dispatch(getWhitelistEmails())
            }
        } catch (error) {
            console.log(error)
        }
    }
}
