import React, { useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { ChevronsRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Admin = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='grid md:grid-cols-3 grid-cols-2 gap-10'>
                            <div class="rounded-xl shadow-md p-6 bg-brand_50">
                                <div class="font-semibold mb-1 md:text-lg text-md text-white" >Manage</div>
                                <div className='flex justify-between items-center'>
                                    <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Syllabus</div>
                                    <ChevronsRight size={40} className='stroke-white' />
                                </div>
                            </div>
                            <div to='/write/draft' class="rounded-xl shadow-md p-6 bg-brand_50">
                                <div class="font-semibold mb-1 md:text-lg text-md text-white" >Course</div>
                                <div className='flex justify-between items-center'>
                                    <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Content</div>
                                    <ChevronsRight size={40} className='stroke-white' />
                                </div>
                            </div>
                            <Link to='/premium/grant-premium-access' class="rounded-xl shadow-md p-6 bg-brand_50">
                                <div class="font-semibold mb-1 md:text-lg text-md text-white" >Grant</div>
                                <div className='flex justify-between items-center'>
                                    <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Premium Access</div>
                                    <ChevronsRight size={40} className='stroke-white' />
                                </div>
                            </Link>
                            <Link to='/manage-sales/failed' class="rounded-xl shadow-md p-6 bg-brand_50">
                                <div class="font-semibold mb-1 md:text-lg text-md text-white" >Manage</div>
                                <div className='flex justify-between items-center'>
                                    <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Sales</div>
                                    <ChevronsRight size={40} className='stroke-white' />
                                </div>
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Admin