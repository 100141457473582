import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  AllCompanies: [],
  Company: null,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setAllCompanies: (state, action) => {
      state.AllCompanies = action.payload;
    },
    setCompany: (state, action) => {
      state.Company = action.payload;
    },
  },
});

export const { setAllCompanies, setCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
