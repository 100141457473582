/* eslint-disable no-unused-vars */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import CallsTable from '../../components/Table/CallsTable/CallsTable';
import TablePagination from '../../components/Table/TablePagination';
import { fetchUsers } from '../../services/operations/calls';
import { setFetchDetails, setUserAdded } from '../../slices/callsSlice';

const CallsReport = ({ userType, isEnrolled, category, startDate, endDate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [content, setContent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const fetchDetails = useSelector((state) => state.calls.fetchDetails);

  const dispatch = useDispatch();
  const calls = useSelector((state) => state.calls.allUserCalls);

  const itemsPerPage = 25;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const userAdded = useSelector((state) => state.calls.userAdded);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    dispatch(
      setFetchDetails({
        category,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        page: currentPage,
        isEnrolled,
      }),
    );
  }, [dispatch, category, formattedStartDate, formattedEndDate, currentPage, isEnrolled]);

  useEffect(() => {
    dispatch(fetchUsers(userType, fetchDetails));
    if (userAdded) {
      dispatch(setUserAdded(false));
    }
  }, [dispatch, userType, fetchDetails, userAdded]);

  const renderCategoryCalls = (callDetails) => {
    if (!callDetails?.users || callDetails.users.length === 0) {
      return (
        <div className='flex justify-center items-center text-3xl pt-4 dark:text-zinc-300'>
          No Calls Found!
        </div>
      );
    }

    return (
      <div className='mb-8'>
        <CallsTable
          setModalOpen={setModalOpen}
          setContent={setContent}
          calls={callDetails.users}
          userType={userType}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
        <div className='border dark:border-dark_40 bg-[#F4F6F8] dark:bg-[#141414] border-gray-200 rounded-b-2xl mt-3'>
          <TablePagination
            itemsPerPage={itemsPerPage}
            indexOfFirstItem={indexOfFirstItem}
            indexOfLastItem={indexOfLastItem}
            totalItems={callDetails?.count}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      </div>
    );
  };

  return (
    <main className='dark:bg-dark font-primary h-screen overflow-y-auto no-scrollbar bg-[#fafafa]'>
      {renderCategoryCalls(calls)}
    </main>
  );
};

export default CallsReport;
