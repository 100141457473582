import React, { useState } from 'react';
import { toggleIsApproved } from '../../services/operations/interviewExperiences';
import { useDispatch } from 'react-redux';

const ApprovalToggle = ({ id, page, initialApproved }) => {
  const [isApproved, setIsApproved] = useState(initialApproved);
  const dispatch = useDispatch();

  const toggleApproval = () => {
    dispatch(toggleIsApproved(id, setIsApproved))
  };


  if (page !== 'interview') return null;

  return (
    <div className="flex items-center">
      <label htmlFor={`toggle-${id}`} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            id={`toggle-${id}`}
            type="checkbox"
            className="sr-only"
            checked={isApproved}
            onChange={toggleApproval}
          />
          {/* Toggle background changes to orange when approved */}
          <div className={`block w-14 h-8 rounded-full transition ${
            isApproved ? 'bg-orange-500' : 'bg-gray-300'
          }`}></div>

          {/* Toggle dot (moves right when approved) */}
          <div
            className={`absolute left-1 top-1 w-6 h-6 rounded-full transition bg-white ${
              isApproved ? 'transform translate-x-6' : ''
            }`}
          ></div>
        </div>
        {/* Min-width ensures "Approved" and "Not Approved" take the same space */}
        <div className="ml-3 text-gray-100 font-medium min-w-[90px] text-center">
          {isApproved ? 'Approved' : 'Not Approved'}
        </div>
      </label>
    </div>
  );
};

export default ApprovalToggle;
