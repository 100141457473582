/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateInterviewExperience } from '../../services/operations/interviewExperiences';

const InterviewFormModal = ({ initialData, isEditMode, setIsFormSubmitted }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(initialData?.isAnonymous || false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {
      isAnonymous: false,
      personal: {
        workExp: 'Select',
      },
      rounds: [
        {
          name: '',
          type: 'Technical',
          mode: 'Remote',
          difficulty: 'Medium',
          duration: '60',
          overview: '',
          questions: [{ text: 'Linear Search', link: '' }],
        },
      ],
    },
  });


  const { fields: roundFields, append: appendRound, remove: removeRound } = useFieldArray({
    control,
    name: 'rounds',
  });

  useEffect(() => {
    if (initialData) {
      reset(initialData)
    }

  }, [reset, initialData])


  const steps = ['Personal', 'Company', 'Rounds', 'Feedback'];

  const onSubmit = (data) => {
    try {
      console.log(initialData.id)
      const finalData = {
        personalInfo: data?.personal || {},
        companyDetails: data.company || {},
        interviewRounds: data?.rounds || {},
        overallFeedback: data?.feedback || {},
      };
      setLoading(true);
      dispatch(updateInterviewExperience(initialData.id, finalData, isAnonymous, setLoading));
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  return (
    <div className='flex items-center justify-center p-4 min-h-[85vh]'>
      <div className='bg-white dark:bg-dark rounded-2xl w-full p-8  min-h-[85vh] font-dmSans relative'>
        {/* Progress Bar */}
        <div className='w-full h-1 bg-gray-200 dark:bg-gray-700 relative mb-6'>
          <div
            className='h-1 bg-orange-500 absolute top-0 transition-all duration-300'
            style={{ width: `${(currentStep + 1) * 25}%` }}
          ></div>
        </div>

        {/* Navigation Tabs */}
        <div className='flex justify-between text-gray-500 dark:text-gray-400 text-sm mb-6'>
          {steps.map((step, index) => (
            <span
              key={step}
              className={`${currentStep === index ? 'text-orange-500 font-semibold' : ''}`}
            >
              {step} {index === 0 ? 'Information' : 'Details'}
            </span>
          ))}
        </div>

        {/* Form Content */}
        <form onSubmit={handleSubmit(onSubmit)}>
        

          {currentStep === 0 && (
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
              {!isAnonymous && (
                <>
                  <div>
                    <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Full Name</label>
                    <input
                      {...register('personal.fullName', { required: !isAnonymous })}
                      className='w-full p-3 border-2 rounded-lg border-new_border dark:border-gray-700 placeholder:text-new_quaternary dark:placeholder:text-[#808080] dark:bg-[#18191B] dark:text-[#FAFAFA]'
                      placeholder='Enter your name'
                    />
                    {errors.personal?.fullName && (
                      <span className='text-red-500 text-sm'>This field is required</span>
                    )}
                  </div>
                  <div>
                    <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Email Address</label>
                    <input
                      type='email'
                      {...register('personal.email', { required: !isAnonymous })}
                      className='w-full p-3 border-2 rounded-lg border-new_border dark:border-gray-700 placeholder:text-new_quaternary dark:placeholder:text-[#808080] dark:bg-[#18191B] dark:text-[#FAFAFA]'
                      placeholder='Enter email address'
                    />
                    {errors.personal?.email && (
                      <span className='text-red-500 text-sm'>Valid email is required</span>
                    )}
                  </div>
                </>
              )}
              <div>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Work Experience</label>
                <Controller
                  name='personal.workExp'
                  control={control}
                  render={({ field }) => (
                    <select {...field} className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg text-new_quaternary dark:text-[#808080] dark:bg-[#18191B]'>
                      <option value='Select' disabled>
                        Select
                      </option>
                      <option value='0-1 years'>0-1 years</option>
                      <option value='1-3 years'>1-3 years</option>
                      <option value='3+ years'>3+ years</option>
                    </select>
                  )}
                />
              </div>
              <div>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Current Role</label>
                <input
                  {...register('personal.currentRole', { required: true })}
                  className='w-full p-3 border-2 rounded-lg border-new_border dark:border-gray-700 placeholder:text-new_quaternary dark:placeholder:text-[#808080] dark:bg-[#18191B] dark:text-[#FAFAFA]'
                  placeholder='Your current role'
                />
                {errors.personal?.currentRole && (
                  <span className='text-red-500 text-sm'>This field is required</span>
                )}
              </div>
              {!isAnonymous && (
                <div className='sm:col-span-2'>
                  <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>LinkedIn Profile</label>
                  <input
                    {...register('personal.linkedin')}
                    className='w-full p-3 border-2 rounded-lg border-new_border dark:border-gray-700 placeholder:text-new_quaternary dark:placeholder:text-[#808080] dark:bg-[#18191B] dark:text-[#FAFAFA]'
                    placeholder='Paste your LinkedIn profile link here'
                  />
                </div>
              )}
            </div>
          )}

          {currentStep === 1 && (
            <div className='mb-12'>
              <h1 className='font-semibold mb-6 dark:text-[#FAFAFA]'>Company Details</h1>
              <div className='mb-6'>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Company Name</label>
                <input
                  {...register('company.name', { required: true })}
                  className='w-full p-3 border-2 rounded-lg border-new_border dark:border-gray-700 placeholder:text-new_quaternary dark:placeholder:text-[#808080] dark:bg-[#18191B] dark:text-[#FAFAFA]'
                  placeholder='Enter Company name'
                />
                {errors.company?.name && (
                  <span className='text-red-500 text-sm'>Company name is required</span>
                )}
              </div>
              <div className='mb-6'>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Job Position Applied</label>
                <input
                  {...register('company.position', { required: true })}
                  className='w-full p-3 border-2 rounded-lg border-new_border dark:border-gray-700 placeholder:text-new_quaternary dark:placeholder:text-[#808080] dark:bg-[#18191B] dark:text-[#FAFAFA]'
                  placeholder='Enter position applied'
                />
                {errors.company?.position && (
                  <span className='text-red-500 text-sm'>Position is required</span>
                )}
              </div>
              <div>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
                  <div>
                    <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Difficulty Level</label>
                    <Controller
                      name='company.difficulty'
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg text-new_quaternary dark:text-[#808080] dark:bg-[#18191B]'
                        >
                          <option value=''>Select</option>
                          <option value='Easy'>Easy</option>
                          <option value='Medium'>Medium</option>
                          <option value='Hard'>Hard</option>
                        </select>
                      )}
                    />
                  </div>
                  <div>
                    <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Interview Timeline</label>
                    <Controller
                      name='company.interviewTimeline'
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg text-new_quaternary dark:text-[#808080] dark:bg-[#18191B]'
                        >
                          <option value=''>Select</option>
                          <option value='Online'>Online</option>
                          <option value='Referral'>Referral</option>
                          <option value='Career Fair'>Career Fair</option>
                        </select>
                      )}
                    />
                  </div>
                  <div>
                    <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Application Mode</label>
                    <Controller
                      name='company.applicationMode'
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg text-new_quaternary dark:text-[#808080] dark:bg-[#18191B]'
                        >
                          <option value=''>Select</option>
                          <option value='Remote'>Remote</option>
                          <option value='On-site'>On-site</option>
                          <option value='Hybrid'>Hybrid</option>
                        </select>
                      )}
                    />
                  </div>
                  <div>
                    <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Interview Mode</label>
                    <Controller
                      name='company.interviewMode'
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg text-new_quaternary dark:text-[#808080] dark:bg-[#18191B]'
                        >
                          <option value=''>Select</option>
                          <option value='Remote'>Remote</option>
                          <option value='On-site'>On-site</option>
                          <option value='Hybrid'>Hybrid</option>
                        </select>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {currentStep === 2 && (
            <div className='space-y-6 mb-20'>
              <div className='flex justify-between items-center'>
                <h3 className='text-lg font-semibold dark:text-[#FAFAFA]'>Interview Rounds</h3>
                <button
                  type='button'
                  onClick={() =>
                    appendRound({
                      name: '',
                      type: 'Technical',
                      mode: 'Remote',
                      difficulty: 'Medium',
                      duration: '60',
                      overview: '',
                      questions: [{ text: '' }],
                    })
                  }
                  className='bg-orange-500 text-white px-4 py-2 rounded-md'
                >
                  Add Round
                </button>
              </div>

              {roundFields.map((round, roundIndex) => (
                <RoundSection
                  key={round.id}
                  control={control}
                  register={register}
                  errors={errors}
                  roundIndex={roundIndex}
                  removeRound={removeRound}
                />
              ))}
            </div>
          )}

          {currentStep === 3 && (
            <div className='space-y-6 mb-10'>
              <div>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Interview Result</label>
                <Controller
                  name='feedback.result'
                  control={control}
                  render={({ field }) => (
                    <select {...field} className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA]'>
                      <option value=''>Select</option>
                      <option value='Selected'>Selected</option>
                      <option value='Rejected'>Rejected</option>
                      <option value='Ghosted'>Ghosted</option>
                    </select>
                  )}
                />
              </div>

              <div>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Salary Range (LPA)</label>
                <input
                  {...register('feedback.salaryRange')}
                  className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA] dark:placeholder:text-[#808080]'
                  placeholder='Eg 8-10 LPA'
                />
              </div>

              <div>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Preparation Tips</label>
                <textarea
                  {...register('feedback.preparationTips')}
                  className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg h-32 dark:bg-[#18191B] dark:text-[#FAFAFA] dark:placeholder:text-[#808080]'
                  placeholder='Share preparation advice...'
                />
              </div>

              <div>
                <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>General Advice</label>
                <textarea
                  {...register('feedback.generalAdvice')}
                  className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg h-32 dark:bg-[#18191B] dark:text-[#FAFAFA] dark:placeholder:text-[#808080]'
                  placeholder='Share overall interview advice...'
                />
              </div>
            </div>
          )}

          <div className='absolute flex gap-4 justify-end mt-6 right-10 bottom-4'>
            {currentStep > 0 && (
              <button
                type='button'
                onClick={() => setCurrentStep((prev) => prev - 1)}
                className='px-6 sm:px-10 py-3 bg-[#F47B54] text-white rounded-md hover:bg-[#e06642]'
              >
                Previous
              </button>
            )}

            {currentStep <= 2 && (
              <button
                type='button'
                onClick={() => setCurrentStep((prev) => prev + 1)}
                className='px-6 sm:px-10 py-3 bg-[#F47B54] text-white rounded-md hover:bg-[#e06642]'
              >
                Next
              </button>
            )}
            {currentStep === 3 && (
              <button
                type='submit'
                className='px-6 sm:px-10 py-3 bg-[#F47B54] text-white rounded-md hover:bg-[#e06642]'
              >
                {loading ? " Submiting..." : "submit"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const RoundSection = ({ control, register, errors, roundIndex, removeRound }) => {
  const { fields: questionFields, append: appendQuestion, remove: removeQuestion } = useFieldArray({
    control,
    name: `rounds.${roundIndex}.questions`,
  });

  return (
    <div className='border p-4 rounded-lg space-y-4 dark:border-gray-700 dark:bg-[#18191B]'>
      <div className='flex justify-between items-center'>
        <h4 className='font-medium dark:text-[#FAFAFA]'>Round {roundIndex + 1}</h4>
        <button
          type='button'
          onClick={() => removeRound(roundIndex)}
          className='text-red-500 text-sm'
        >
          <Trash2 className='text-new_Brand' size={24} />
        </button>
      </div>

      <div>
        <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Round Name</label>
        <input
          {...register(`rounds.${roundIndex}.name`, { required: true })}
          className='w-full p-2 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA] dark:placeholder:text-[#808080]'
          placeholder='Eg. Technical Round 1'
        />
        {errors.rounds?.[roundIndex]?.name && (
          <span className='text-red-500 text-sm'>Round name is required</span>
        )}
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        <div>
          <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Round Type</label>
          <Controller
            name={`rounds.${roundIndex}.type`}
            control={control}
            render={({ field }) => (
              <select {...field} className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA]'>
                <option value='Technical'>Technical</option>
                <option value='HR'>HR</option>
                <option value='Managerial'>Managerial</option>
              </select>
            )}
          />
        </div>
        <div>
          <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Mode</label>
          <Controller
            name={`rounds.${roundIndex}.mode`}
            control={control}
            render={({ field }) => (
              <select {...field} className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA]'>
                <option value='Remote'>Remote</option>
                <option value='On-site'>On-site</option>
              </select>
            )}
          />
        </div>
        <div>
          <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Difficulty Level</label>
          <Controller
            name={`rounds.${roundIndex}.difficulty`}
            control={control}
            render={({ field }) => (
              <select {...field} className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA]'>
                <option value='Easy'>Easy</option>
                <option value='Medium'>Medium</option>
                <option value='Hard'>Hard</option>
              </select>
            )}
          />
        </div>
        <div>
          <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Duration (mins)</label>
          <input
            type='number'
            {...register(`rounds.${roundIndex}.duration`, { required: true })}
            className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA]'
          />
          {errors.rounds?.[roundIndex]?.duration && (
            <span className='text-red-500 text-sm'>Duration is required</span>
          )}
        </div>
      </div>

      <div className='overview'>
        <label className='block text-new_primary dark:text-[#FAFAFA] mb-1'>Round Overview</label>
        <textarea
          {...register(`rounds.${roundIndex}.overview`, { required: true })}
          className='w-full p-3 border-2 border-new_border dark:border-gray-700 rounded-lg h-32 dark:bg-[#18191B] dark:text-[#FAFAFA] dark:placeholder:text-[#808080]'
          placeholder='Round overview...'
        />
        {errors.rounds?.[roundIndex]?.overview && (
          <span className='text-red-500 text-sm'>Overview is required</span>
        )}
      </div>

      <div>
        <h5 className='text-new_primary dark:text-[#FAFAFA] mb-2'>Questions</h5>
        {questionFields.map((question, questionIndex) => (
          <div key={question.id} className='flex items-center space-x-2 mb-2'>
            <input
              {...register(`rounds.${roundIndex}.questions.${questionIndex}.text`, { required: true })}
              className='w-full p-2 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA] dark:placeholder:text-[#808080]'
              placeholder='Enter question'
            />
            <input
              {...register(`rounds.${roundIndex}.questions.${questionIndex}.link`, { required: true })}
              className='w-full p-2 border-2 border-new_border dark:border-gray-700 rounded-lg dark:bg-[#18191B] dark:text-[#FAFAFA] dark:placeholder:text-[#808080]'
              placeholder='Enter Question Link'
            />
            <button
              type='button'
              onClick={() => removeQuestion(questionIndex)}
              className='text-red-500'
            >
              <Trash2 size={20} />
            </button>
            <button
              type='button'
              onClick={() => appendQuestion({ text: '', link: '' })}
              className='bg-orange-500 text-white px-4 py-2 text-sm rounded-md whitespace-nowrap'
            >
              Add
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InterviewFormModal;