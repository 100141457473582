/* eslint-disable no-unused-vars */
import { PencilIcon, SaveIcon } from 'lucide-react';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FailedEntryDetailsModal from '../../SalesModals/FailedEntryDetailsModal';
import DroppedEntryDetailsModal from '../../SalesModals/DroppedEntryDetailsModal';
import WhatsappEntryDetailsModal from '../../SalesModals/WhatasppEntryDetailsModal';
import moment from 'moment';
import {
  updateCallStatus,
  addCouponCode,
  updateBuyingStatus,
} from '../../../services/operations/calls';

const CallsTableItem = (props) => {
  const {
    id,
    num,
    name,
    email,
    status,
    code_used,
    call_time,
    created_at,
    status_update_time,
    payment_time,
    phone_number,
    buying_status,
  } = props;

  const [callCategory, setCallCategory] = useState(status || '');
  const [buyingStatus, setBuyingStatus] = useState(buying_status || '');
  const [salesModalOpen, setSalesModalOpen] = useState(false);

  const dispatch = useDispatch();
  const type = useSelector((state) => state.calls.userType || '');
  const [isEditingCodeUsed, setIsEditingCodeUsed] = useState(false);
  const [codeUsed, setCodeUsed] = useState(code_used || 'NONE');
  const [originalCode, setOriginalCode] = useState(codeUsed);

  const handleStatusUpdate = (selectedValue) => {
    setCallCategory(selectedValue);
    dispatch(updateCallStatus(email, type, selectedValue));
  };

  const handleBuyingStatusUpdate = (selectedValue) => {
    setBuyingStatus(selectedValue);
    dispatch(updateBuyingStatus(email, selectedValue));
  };

  // Handles code changes and dispatches the API call
  const handleCodeUsedChange = (newCode) => {
    const trimmedCode = newCode.trim();
    setCodeUsed(trimmedCode.toUpperCase());
    if (trimmedCode !== code_used) {
      dispatch(addCouponCode(trimmedCode.toUpperCase(), email, type));
    }
  };

  const handleDetailView = (e) => {
    e.stopPropagation();
    setSalesModalOpen(true);
  };

  useEffect(() => {
    setCodeUsed(code_used);
    setCallCategory(status);
    setBuyingStatus(buying_status);
  }, [status, code_used, call_time, buying_status]);

  return (
    <tbody className='text-[13px] overflow-y-auto no-scrollbar'>
      <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
        {/* Serial Number */}
        <td className='px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
          <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
            {num}.
          </div>
        </td>

        {/* whatsapp:  Name */}
        {type === 'whatsapp' && (
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
              {name}
            </div>
          </td>
        )}

        {/* Email */}
        <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
          <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
            {email}
          </div>
        </td>

        {/* 
          failed: Payment Time
          dropped: Created At
          whatsapp: Phone Number 
        */}
        <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
          <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
            {type === 'failed' &&
              (payment_time ? moment(payment_time).format('MMM DD, YYYY, h:mm A') : 'N/A')}
            {type === 'whatsapp' && (phone_number ? phone_number : 'N/A')}
            {type === 'dropped' &&
              (created_at ? moment(created_at).format('MMM DD, YYYY, h:mm A') : 'N/A')}
          </div>
        </td>

        {/* 
          failed : Call Status
          dropped : Call Status
          whatsapp : Buying Status
        */}
        {type === 'whatsapp' ? (
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4'>
              <select
                className='text-xs sm:text-sm px-2 py-1 bg-gray-50 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 focus:outline-none focus:ring w-[70%] focus:ring-red-500 appearance-none'
                value={buyingStatus}
                onChange={(e) => handleBuyingStatusUpdate(e.target.value)}
              >
                <option value='not interested'>Not Interested</option>
                <option value='will buy later'>Will Buy Later</option>
                <option value='bought'>Bought</option>
              </select>
            </div>
          </td>
        ) : (
          <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
            <div className='flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4'>
              <select
                className='text-xs sm:text-sm px-2 py-1 bg-gray-50 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 focus:outline-none focus:ring w-[70%] focus:ring-red-500 appearance-none'
                value={callCategory}
                onChange={(e) => handleStatusUpdate(e.target.value)}
              >
                <option value='not called'>Not Called</option>
                <option value='wrong num'>Wrong Number</option>
                <option value='unreachable'>Unreachable</option>
                <option value='unanswered'>Unanswered</option>
                <option value='interested'>Interested</option>
                <option value='not interested'>Not Interested</option>
                <option value='already enrolled'>Already Enrolled</option>
                <option value='call later'>Call Later</option>
                <option value='bought on call'>Bought on Call</option>
              </select>
            </div>
          </td>
        )}

        {/* 
          failed : Call Time 
          dropped : Call Time 
          whatsapp : Status Update Time
        */}
        <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
          <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
            {(type === 'failed' || type === 'dropped') &&
              (call_time ? moment(call_time).format('MMM DD, YYYY, h:mm A') : '--')}
            {type === 'whatsapp' &&
              (status_update_time
                ? moment(status_update_time).format('MMM DD, YYYY, h:mm A')
                : 'N/A')}
          </div>
        </td>

        {/* Details */}
        <td className='px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap'>
          <div className='font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center'>
            <button
              onClick={(e) => {
                handleDetailView(e);
              }}
              className='text-brand underline cursor-pointer flex items-center'
              type='button'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='lucide lucide-eye'
              >
                <path d='M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0' />
                <circle cx='12' cy='12' r='3' />
              </svg>
            </button>
          </div>
        </td>

        {/* Code Used */}
        <td className='px-4 py-4 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 whitespace-nowrap'>
          <div className='flex flex-col items-center space-y-2'>
            {isEditingCodeUsed ? (
              <div className='flex items-center space-x-2'>
                <input
                  type='text'
                  value={codeUsed}
                  onChange={(e) => setCodeUsed(e.target.value)}
                  className='w-32 px-2 py-1 border border-red-500 rounded-md text-sm sm:text-base focus:ring focus:ring-red-300 focus:outline-none dark:bg-gray-800 dark:border-red-600 dark:text-white'
                  placeholder='Enter code'
                  autoFocus
                />
                <button
                  className='text-brand  underline cursor-pointer flex items-center'
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering Edit button logic
                    handleCodeUsedChange(codeUsed);
                    setOriginalCode(codeUsed); // Update original code to match the saved value
                    setIsEditingCodeUsed(false); // Exit editing mode
                  }}
                >
                  <SaveIcon className='w-4 h-4' />
                </button>
              </div>
            ) : (
              <div className='flex items-center space-x-2'>
                <span className='w-32 px-2 py-1 border  border-lead100 dark:border-lead500  rounded-md text-sm sm:text-base text-gray-900 dark:text-gray-100'>
                  {codeUsed || 'None'}
                </span>
                <button
                  className='text-brand underline cursor-pointer flex items-center'
                  onClick={(e) => {
                    e.stopPropagation(); // Ensure Edit only activates when explicitly clicked
                    setIsEditingCodeUsed(true); // Enter editing mode
                  }}
                >
                  <PencilIcon className='w-4 h-4' />
                </button>
              </div>
            )}
          </div>
        </td>
      </tr>

      {(() => {
        switch (type) {
          case 'failed':
            return (
              <FailedEntryDetailsModal
                email={email}
                modalOpen={salesModalOpen} // Control visibility
                setModalOpen={setSalesModalOpen} // Function to toggle visibility
              />
            );
          case 'dropped':
            return (
              <DroppedEntryDetailsModal
                email={email}
                modalOpen={salesModalOpen} // Control visibility
                setModalOpen={setSalesModalOpen} // Function to toggle visibility
              />
            );
          case 'whatsapp':
            return (
              <WhatsappEntryDetailsModal
                email={email}
                modalOpen={salesModalOpen} // Control visibility
                setModalOpen={setSalesModalOpen} // Function to toggle visibility
              />
            );
          default:
            return null; // Return null if no matching type
        }
      })()}
    </tbody>
  );
};

export default CallsTableItem;
