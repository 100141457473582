import { setAllConfigs } from "../../slices/configSlice"
import { configEndpoints, DevConfigEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { ADD_CONFIG, EDIT_CONFIG, DELETE_CONFIG, GET_ALL_CONFIG } = configEndpoints;
const { ADD_CONFIG_DEV, EDIT_CONFIG_DEV, DELETE_CONFIG_DEV, GET_ALL_CONFIG_DEV } = DevConfigEndpoints;


export function getAllConfigs(isProd) {
    return async(dispatch) => {
        try {
            let response
            if (isProd) {
                response = await apiConnector("GET", GET_ALL_CONFIG);
            } else {
                response = await apiConnector("GET", GET_ALL_CONFIG_DEV);
            }

            if (response.data.success){
                dispatch(setAllConfigs(response.data.config_details));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function addConfig(key, value, percentage, backend, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            let response
            if (isProd) {
                response = await apiConnector("POST", ADD_CONFIG, { key, value, percentage, backend });
            }
            else {
                response = await apiConnector("POST", ADD_CONFIG_DEV, { key, value, percentage, backend });
            }
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllConfigs(isProd));
                if(setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to add config");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function updateConfig(id, key, value, percentage, backend, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            let response
            if (isProd) {
                response = await apiConnector("PUT", EDIT_CONFIG + `${id}`, { key, value, percentage, backend });
            } else {
                response = await apiConnector("PUT", EDIT_CONFIG_DEV + `${id}`, { key, value, percentage, backend });
            }

            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllConfigs(isProd));
                if(setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to update config");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deleteConfig(id, setModalDeleteOpen, isProd) {
    return async (dispatch) => {
        try {
            let response
            if (isProd) {
                response = await apiConnector("DELETE", DELETE_CONFIG + `${id}`);
            } else {
                response = await apiConnector("DELETE", DELETE_CONFIG_DEV + `${id}`);
            }
            if(response.data.success) {
                dispatch(getAllConfigs(isProd));
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete config");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}
