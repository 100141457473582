import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Controller, useForm } from 'react-hook-form';
import NotificationsModal from '../../components/Shared/Modals/NotificationModal';
import { useDispatch, useSelector } from 'react-redux';
import { addPlusUpdate, deletePlusUpdate, editPlusUpdate, getAllPlusUpdates } from '../../services/operations/notifications';

const PlusUpdates = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [plusUpdateId, setPlusUpdateId] = useState(null);
    const {isProd} = useSelector((state) => state.auth)

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, setValue } = useForm();

    const { allPlusUpdates } = useSelector((state) => state.notifications)

    useEffect(() => {
        dispatch(getAllPlusUpdates())
    }, [dispatch])
    const handleAdd = (e) => {
        e.stopPropagation()
        setModalOpen(true)
        setIsEdit(false)
        setValue("title", '')
        setValue("description", '')
        setValue("type", '')
        setValue("redirect_link", '')
    }

    const handleOnSubmit = (data) => {
        if (isEdit) {
            const { title, description, type, redirect_link } = data

            dispatch(editPlusUpdate(plusUpdateId, title, description, type, redirect_link, setModalOpen,isProd))
        } else {
            const { title, description, type, redirect_link } = data
            dispatch(addPlusUpdate(title, description, type, redirect_link, setModalOpen,isProd))
        }
        // console.log(data)
    }

    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const handlePlusUpdateEdit = (e, id, title, description, type, redirect_link) => {
        e.stopPropagation()
        setValue('title', title)
        setValue('description', description)
        setValue('type', type)
        setValue('redirect_link', redirect_link)
        setPlusUpdateId(id)
        setIsEdit(true)
        setModalOpen(true)
    }
    const handlePlusUpdateDeleteModal = (e, id) => {
        e.stopPropagation()
        setPlusUpdateId(id)
        setModalDeleteOpen(true);
    }

    const handleDeletePlusUpdate = (e) => {
        dispatch(deletePlusUpdate(plusUpdateId, setModalDeleteOpen))
    }
    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleAdd(e)} className='btn-brand-2'>Add Plus Update</button>
                        </div>

                        <NotificationsModal title={isEdit ? 'Edit Plus Update' : 'Add Plus Update'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="title"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="title"
                                                    type="text"
                                                    name="title"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Title"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Title is required',
                                            }}
                                        />
                                        {isSubmitted && errors.title && <p className="text-red-600 text-sm italic">{errors.title.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Description</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="description"
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    id="description"
                                                    type="text"
                                                    name="description"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Description"
                                                    rows={3}
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Description is required',
                                            }}
                                        />
                                        {isSubmitted && errors.description && <p className="text-red-600 text-sm italic">{errors.description.message}</p>}
                                    </div>
                                </div>

                                {/* Add Types select */}
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Type</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="type"
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-select w-full text-gray-300"
                                                >
                                                    <option value="">Select Public Status...</option>
                                                    <option value={'AI'}>AI</option>
                                                    <option value={'Core'}>Core</option>
                                                    <option value={'System Design'}>System Design</option>
                                                    <option value={'DSA'}>DSA</option>
                                                    <option value={'Code-Editor'}>Code Editor</option>
                                                    <option value={'Small-Feature'}>Small Feature</option>
                                                    <option value={'Profile'}>Profile</option>
                                                    <option value={'Roadmap'}>Roadmap</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'Type is required',
                                            }}
                                        />
                                        {errors.type && <p className="text-red-600 text-sm italic">{errors.type.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Redirect Link (Optional)</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="redirect_link"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="redirect_link"
                                                    type="text"
                                                    name="redirect_link"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Redirect Link"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">{isEdit ? 'Edit Plus Update' : 'Add Plus Update'}</button>
                                    </div>
                                </div>
                            </form>
                        </NotificationsModal>
                        {allPlusUpdates && allPlusUpdates?.length > 0 && (
                            <>
                                <div className="  font-primary  relative w-full">
                                    <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                                        <div className="overflow-x-auto">
                                            <table className="table-auto w-full  divide-y  divide-gray-200">
                                                <thead className="text-xs uppercase  text-[#8C8C8C] ">
                                                    <tr>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/3 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                                            <div className="font-semibold flex items-start">Title</div>
                                                        </th>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                            <div className="font-semibold text-center">Desc</div>
                                                        </th>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                            <div className="font-semibold text-center">Type</div>
                                                        </th>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                            <div className="font-semibold text-center">Redirect Link</div>
                                                        </th>
                                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                            <div className="font-semibold text-center">Action</div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {
                                                    allPlusUpdates?.map((data) => {
                                                        return (
                                                            <tbody className="text-[13px]">
                                                                <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                                                            {data?.title}
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                            {data?.description}
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                            {data?.type}
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                                                                            {data?.redirect_link}
                                                                        </div>
                                                                    </td>

                                                                    <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                                                        <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                                                                            <button onClick={(e) => handlePlusUpdateEdit(e, data.id, data.title, data.description, data.type, data?.redirect_link)} className='btn bg-green-500 text-white  border-green-500 transition-all duration-300 ease-in-out '>Edit</button>
                                                                            <button onClick={(e) => handlePlusUpdateDeleteModal(e, data.id)} className='btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out'>Delete</button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <NotificationsModal title={"Delete Plus Update"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                                                                    <div className='flex flex-col gap-y-4 items-center justify-center'>
                                                                        <span className='text-4xl'>Are You Sure?</span>
                                                                        <button className='btn-brand-2 mt-4' onClick={() => handleDeletePlusUpdate()}>Yes, Delete</button>
                                                                    </div>
                                                                </NotificationsModal>
                                                            </tbody >
                                                        )
                                                    })
                                                }

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default PlusUpdates
