/* eslint-disable no-unused-vars */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SalesPiChart from '../../components/SalesCharts/SalesPiChart';
import { useSelector, useDispatch } from 'react-redux';
import { setUserType } from '../../slices/callsSlice';
import DateRangePicker from '../../components/DateRangePicker';
import SalesBarChart from '../../components/SalesCharts/SalesBarChart';

const SalesUserStats = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.calls.userType || 'whatsapp');

  const [startDate, setStartDate] = useState(
    () => new Date(new Date().setDate(new Date().getDate() - 30)),
  );
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
      <Sidebar
        type={pathname && pathname?.split('/')[1]}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className='relative flex flex-col flex-1 overflow-y-auto no-scrollbar overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
          <div className='flex justify-between items-center w-full px-6'>
            <div className='flex gap-x-4 border-b'>
              {['whatsapp', 'failed', 'dropped'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => dispatch(setUserType(tab))}
                  className={`px-4 py-2 ${userType === tab ? 'border-b-2 border-brand text-brand' : 'text-gray-600'}`}
                >
                  {tab.toUpperCase()}
                </button>
              ))}
            </div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          <SalesPiChart userType={userType} startDate={startDate} endDate={endDate} />
          <SalesBarChart userType={userType} startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </div>
  );
};

export default SalesUserStats;
