import {
  setInterviewExperience,
  setAllInterviewExperiences,
} from '../../slices/interviewExperiencesSlice';
import { interviewExperienceEndpoints } from '../api';
import toast from 'react-hot-toast';
import { apiConnector } from '../apiConnector';

const {
  DELETE_INTERVIEW_EXPERIENCE,
  GET_ALL_INTERVIEW_EXPERIENCES,
  GET_INTERVIEW_EXPERIENCE_BY_ID,
  UPDATE_INTERVIEW_EXPERIENCE,
  TOGGLE_ISAPPROVED_INTERVIEW_EXPERIENCE,
} = interviewExperienceEndpoints;

export function getAllInterviewExperiences() {
  return async (dispatch) => {
    try {
      const response = await apiConnector('GET', GET_ALL_INTERVIEW_EXPERIENCES);

      if (response?.data.success) {
        dispatch(setAllInterviewExperiences(response.data.data));
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error fetching interview experiences:', error);
      toast.error('Failed to fetch interview experiences');
    }
  };
}

export function getInterviewExperiences(id) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('GET', GET_INTERVIEW_EXPERIENCE_BY_ID + `${id}`);
      if (response?.data.success) {
        dispatch(setInterviewExperience(response.data.data));
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error fetching interview experiences:', error);
      toast.error('Failed to fetch interview experiences');
    }
  };
}

export function toggleIsApproved(id, setIsApproved) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('POST', TOGGLE_ISAPPROVED_INTERVIEW_EXPERIENCE + `${id}`);

      if (response?.data.success) {
        toast.success('Successfully Toggle IsApproved');
        setIsApproved((prev) => !prev);
        dispatch(getAllInterviewExperiences());
      } else {
        toast.error('Failed to Toggle IsApproved');
      }
    } catch (error) {
      console.error('Error fetching interview experiences:', error);
      toast.error('Failed to fetch interview experiences');
    }
  };
}

export function deleteExperienceDetails(id) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('DELETE', DELETE_INTERVIEW_EXPERIENCE + `${id}`);
      if (response?.data.success) {
        toast.success('Successfully Deleted Interview Experience');
        dispatch(getAllInterviewExperiences());
      } else {
        toast.error('Failed to Delete Interview Experience');
      }
    } catch (error) {
      console.error('Error fetching interview experiences:', error);
      toast.error('Failed to fetch interview experiences');
    }
  };
}


export function updateInterviewExperience (id, data, isAnonymous, setLoading) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('PUT', UPDATE_INTERVIEW_EXPERIENCE + `${id}`, {
        data,
        isAnonymous,
      });
      if (response?.data.success) {
        toast.success('Successfully Updated Interview Experience');
        setLoading(false);
        dispatch(getAllInterviewExperiences());
      } else {
        toast.error('Failed to Update Interview Experience');
      }
    } catch (error) {
      console.error('Error fetching interview experiences:', error);
      toast.error('Failed to fetch interview experiences');
    }
  };
}